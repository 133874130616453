import React from 'react';
import {
  List, Avatar
} from 'antd';
import icon from '../images/icon.jpg';

class ReviewItem extends React.Component {
  config = {
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  getImageUrl(url, name){
    if(url === null  || url === ""){
      return this.getImage(name)
    } else{
        return url
    }
  }
  getImage = (name) => {
    if(name!=null){
      name = name.toLowerCase()
      name = name.split(" ")[0]
    }
    console.log("names",name)
    const available_veggies = [
        "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
        "corn", "arugula", "beansprouts", "cucumber", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
      ]
    if(available_veggies.includes(name)){
      const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
      return url
    }
    else{
      return icon
    }
  }
  render() {
    console.log(this.props.data)
    return (
      <div className="demo-infinite-container">

        <List
          dataSource={this.props.data}
          renderItem={item => (
            <List.Item 
                key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={this.getImageUrl(item.image_url, item.item_name)} size={60} />}
                title={item.item_name}
                description={"Ordered from: "+item.seller_name}
              />
              <div>
                <h1 style={{ marginLeft: "-30vw", position: "absolute" }}>Amount: {item.quantity}</h1>
              </div>
              <div>
                <h1 style={{ marginLeft: "-20vw", position: "absolute" }}>Price: ${item.price.toFixed(2)}</h1>
              </div>
              <div>
                <h1 style={{  marginLeft: "-10vw", position: "absolute"  }}> ${(item.quantity*item.price).toFixed(2)} </h1>
              </div>
              </List.Item>
          )}
        >
        </List>
      </div>
    );
  }
}

export default ReviewItem;
