import React from 'react';
import axios from 'axios';
import QueryString from 'query-string'
import Transactions from '../components/Transactions'
class TransactionContainer extends React.Component {
    state = {
        refresh: false,
        transactions: [],
        page: 1,
        maxPage: 1
    }
    componentWillReceiveProps() {
        const params = QueryString.parse(this.props.location.search)
        var page = 1
        if ("page" in params) {
            page = params.page
            this.setState({ page: page })
        }
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        const url = "https://api.farmersofamerica.net/api/transactions?page=" + page
        axios.get(url, config)
            .then(res => {
                this.setState({
                    transactions: res.data
                })
            })
    }
    goPrevious() {
        var page = parseInt(this.state.page)
        page = page -= 1
        this.props.history.push({
            pathname: "/orders",
            search: "?page=" + page
        })
        window.location.reload(false);
    }
    goNext() {
        var page = parseInt(this.state.page)
        page = page += 1
        this.props.history.push({
            pathname: "/orders",
            search: "?page=" + page
        })
        window.location.reload(false);
    }
    generate3Buttons() {
        var to_return = []
        if (this.state.maxLength <= 3) {
            for (let i = 0; i < this.state.maxLength; i++) {
                to_return.push(
                    <button class="button" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "80%", marginLeft: '1vw', height: '4vh' }} onClick={() => { this.goToPage(i + 1) }}>
                        {i + 1}
                    </button>
                )
            }
        } else {
            for (let i = 0; i < 3; i++) {
                if (parseInt(this.state.page) + i <= this.state.maxLength) {
                    to_return.push(
                        <button class="button" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "80%", marginLeft: '1vw', height: '4vh' }} onClick={() => { this.goToPage(i + parseInt(this.state.page)) }}>
                            {parseInt(this.state.page) + i}
                        </button>
                    )
                } else {
                    break
                }
            }
        }
        return to_return
    }
    getButtons() {
        return (
            <footer style={{ "textAlign": "center" }}>
                {
                    this.state.page == 1 ?
                        <button class="button" disabled style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "80%", height: '4vh', width: "6vw" }}>
                            &laquo; Previous
                </button>
                        :
                        <button onClick={() => this.goPrevious()} class="button" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "80%", height: '4vh', width: "6vw" }}>
                            &laquo; Previous
                </button>
                }
                {this.generate3Buttons()}
                {parseInt(this.state.page) == this.state.maxLength ?
                    <button disabled class="button" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "80%", marginLeft: '1vw', height: '4vh', width: "6vw" }}>
                        Next &raquo;
                        </button>
                    :
                    <button onClick={() => this.goNext()} class="button" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", fontSize: "80%", marginLeft: '1vw', height: '4vh', width: "6vw" }}>
                        Next &raquo;
            </button>}
            </footer>
        )
    }
    goToPage(i) {
        this.props.history.push({
            pathname: "/orders",
            search: "?page=" + i
        })
        window.location.reload(false);
    }
    componentDidMount() {
        const params = QueryString.parse(this.props.location.search)
        var page = 1
        if ("page" in params) {
            page = params.page
            this.setState({ page: page })
        }
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        const url = "https://api.farmersofamerica.net/api/transactions?page=" + page
        axios.get(url, config)
            .then(res => {
                if (res.data.length == 0) {
                    var maxLength = 0
                } else {
                    var maxLength = res.data[0]["max_length"]
                }
                this.setState({
                    transactions: res.data,
                    maxLength: maxLength
                })

            })

    }
    render() {
        const token = localStorage.getItem('token');
        if (token === null) {
            this.props.history.push("/")
        }
        return (
            <div>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li class="is-active">
                            <a href="#">
                                <span class="icon">
                                    <i  style = {{fontSize: 25}}class="fas fa-search-dollar" aria-hidden="true"></i>
                                </span>
                                <span style = {{fontSize: 25}}>Orders</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <h2>
                    <Transactions data={this.state.transactions} />
                </h2>
                {this.getButtons()}
            </div>
        );
    }
}
export default TransactionContainer;