import React from 'react'
import axios from 'axios';
import { Form, Button, Icon, Input, message } from 'antd';
import { NavLink } from 'react-router-dom';
import '../index.css';
const FormItem = Form.Item
class ChangePasswordView extends React.Component {
    state = {
        old_password: "",
        new_password: "",
        confirm_password: "",
    }
    isEnabled = true
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    handleChange = input => event => {
        this.setState({ [input]: event.target.value })
    }
    callApi = () => {
        const url = "https://api.farmersofamerica.net/api/change_password/"
        console.log(this.config)
        axios.post(url,this.state, this.config)
            .then(res =>{
                console.log(res)
                message.success("Password successfully changed.")
                this.props.history.push("/profile")
                }
        ).catch(error => {
            const reason = error.response.data
            if(reason === "simple"){
                message.error("New password is too simple!")
            } else if (reason === "wrong") {
                message.error("Previous password is incorrect!")
            } else if (reason === "match") {
                message.error("Passwords do not match.")
            } else{
                message.error("Not sure how you got this error. Try again.")
            }
            console.log(error.response.data, error.response.status)
            this.isEnabled = true
        })
    }
    changePassword = (rule, value, callback) => {
        console.log("we callling this?")
        if(this.isEnabled){
            console.log("test")
            this.isEnabled = false
            const form = this.props.form;
            form.validateFields().then(
                this.callApi
            )
            .catch(
                    err => {
                        console.log("Password error", err)
                        this.isEnabled = true
                    }
                )
        }
    }
    validateFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value === form.getFieldValue("old_password")){
            callback("New password cannot be the same as the previous password!")
        } else if (value.length < 8) {
            callback('Password is too short! Must be at least 8 characters.')
        } else if (value.toUpperCase() === value || value.toLowerCase() === value) {
            callback('Password must contain uppercase and lowercase characters!')
        } else if (!/\d/.test(value)) {
            callback('Password must contain at least one number!')
        } else {
            callback();
        }

    }
    validateNewPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value === form.getFieldValue("old_password")) {
            callback("New password cannot be the same as the previous password!")
        } else if (value && value !== form.getFieldValue('new_password')) {
            callback('Passwords must match!');
        } else if (value.length < 8) {
            callback('Password is too short! Must be at least 8 characters.')
        } else if (value.toUpperCase() === value || value.toLowerCase() === value) {
            callback('Password must contain uppercase and lowercase characters!')
        } else if (!/\d/.test(value)) {
            callback('Password must contain at least one number!')
        } else {
            callback();
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="changePassword">
                <h2>
                    Change your password
                </h2>
                <div>
                    <h1>1. Please enter your old password</h1>
                    <FormItem style={{ marginLeft: "0vw" }}>
                        {getFieldDecorator('old_password', {
                            rules: [{ required: true, message: 'Please input your previous password!' }],
                            initialValue: null
                        })(
                            <Input
                                style={{ width: 400 }}
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Previous Password"
                                onChange={this.handleChange("old_password")}
                            />
                        )}
                    </FormItem>
                </div>
                <div>
                    <h1>2. Please enter your new password</h1>
                    <FormItem style={{ marginLeft: "0vw" }}>
                        {getFieldDecorator('new_password', {
                            rules: [{ required: true, message: 'Please input your new password!' },
                            {validator: this.validateFirstPassword}
                        ],
                            initialValue: null
                        })(
                            <Input
                                style={{ width: 400 }}
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="New Password"
                                onChange={this.handleChange("new_password")}
                            />
                        )}
                    </FormItem>
                </div>
                <div>
                    <h1>3. Please re-enter your new password</h1>
                    <FormItem style={{ marginLeft: "0vw" }}>
                        {getFieldDecorator('confirm_password', {
                            rules: [{ required: true, message: 'Please confirm your password!' },
                            { validator: this.validateNewPassword }],
                            initialValue: null
                        })(
                            <Input
                                style={{ width: 400}}
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder="Confirm Password"
                                onChange={this.handleChange("confirm_password")}
                            />
                        )}
                    </FormItem>
                </div>
                <NavLink to="/profile">
                    <Button style={{ width: 75 }}>
                        Back
                </Button>
                </NavLink>
                <Button style={{ marginLeft: 75 }} onClick={this.changePassword}>
                    Change Password
                </Button>
                <div style = {{marginTop: 10}} className = "changePasswordDiv">
                <h1 className="changepasswordTip0">
                    Passwords cannot be the same as the previous one.
                </h1>
                <h1 className="changepasswordTip1">
                    Passwords must be at least 8 characters long.
                </h1>
                <h1 className="changepasswordTip2">
                    Contain numbers (0-9)
                </h1>
                <h1 className="changepasswordTip3">
                    Contain upper case characters (A-Z)
                </h1>
                <h1 className="changepasswordTip4">
                    Contain lower case characters (a-z)
                </h1>
                <h1 className="changepasswordTip4">
                    Avoid simple passwords like Password1234, 1234Qwer,
                </h1>
                </div>
            </div>
        )
    }
}
const WrappedPasswordChangeForm = Form.create()(ChangePasswordView);

export default WrappedPasswordChangeForm;