import React from 'react'
import topLogo from '../images/bon-harvest-title-green.png'
import officeMap from '../images/office-map-png.png'
import veggies from '../images/veggies.jpg'
import rethinkLogo from '../images/rethink.png'
import ourLogo from '../images/logotext.png'
class AboutRethink extends React.Component {

    render() {
        return (
            <body style={{ fontFamily: 'GothamRound' }}>
                <nav class="navbar" role="navigation" aria-label="main navigation">
                    <div class="navbar-brand">
                        <a class="navbar-item" href="/">
                            <img src={topLogo} />
                        </a>
                        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div class="navbar-menu is-size-5 has-text-weight-medium">
                        <div class="navbar-end">
                            <a href="/data-problem" class="navbar-item is-active">
                                The Data Problem
                             </a>
                            <div class="navbar-item has-dropdown is-hoverable">
                                <a href="/about" class="navbar-link is-active">
                                    About
                                </a>
                                <div class="navbar-dropdown">
                                    <a href="#story" class="navbar-item">
                                        Story
                                    </a>
                                    <a href="#ethics" class="navbar-item">
                                        Ethics
                                    </a>
                                    <a href="#history" class="navbar-item">
                                        History
                                    </a>
                                    <hr class="navbar-divider" />
                                    <a href="/team" class="navbar-item">
                                        Team
                                    </a>
                                </div>
                            </div>
                            <a href="https://blog.bonharvest.com" class="navbar-item">
                                Blog
                            </a>
                            <a href="/rethink" class="navbar-item">
                                Rethink
                        </a>
                            <div class="navbar-item">
                                <div class="buttons">
                                    <a href="/farmer_signup" class="button is-medium is-rounded is-danger has-text-weight-semibold">
                                        Get early access
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section class="hero is-large parallax" style={{ "backgroundImage": `url(${veggies})` }}>
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title is-size-1 has-text-white has-text-weight-bold">
                                Bon Harvest and Rethink Food
                             </h1>
                        </div>
                    </div>
                </section>
                <section class="section">
                    <div class="container">
                        <div class="columns is-centered">
                            
                            <div class="column is-two-thirds">
                                <img style = {{marginLeft: "12vw", marginTop: -70, width: 200, }} src = {ourLogo}/>
                            <img style = {{marginTop: -70, width: 200, }} src={rethinkLogo }/>
                                <div class="content is-medium">
                                    {/* <p>
                                        The journey of real food from sprout to plate is awe-inspiring. That piece of lettuce in your salad (ok, burger) had an incredible life before it met your mouth. That lowly iceberg leaf is actually the physical manifestation of an entire ecosystem of natural and human elements that came together at just the right time in just the right way to create just the right accompaniment to all the more flashy bits of yum arranged on your plate.
                                    </p>
                                    <p>
                                        What if you truly felt connected to that leaf? Connected to your farmers, your community, and this planet? These are relationships that could be so much more than transactional. Let’s breathe them back to life.                                    </p>
                                    <p>
                                        Bon Harvest is on a mission to rekindle awe and wonderment. To enliven curiosity.                                    </p>
                                    <p>
                                        Let’s nourish bodies and hearts and revitalize our appreciation for the inescapably rich connection that allows living to happen. Let’s re-commit to health - of body, of community, and of the Earth - and in doing so empower trust and peace of mind.                                    </p>
                                    <p>
                                        This country is currently throwing away enough in perfectly nutritious real food to feed a small country - 40 billion pounds of it a year. At the same time, 1 in 8 Americans will go to bed tonight hungry. Roughly 20 billion pounds of nourishing produce never even leave the fields, left to rot for an unsightly spot or bump, a lack of labor, or a lack of financial incentive for farmers                                    </p>
                                    <p>
                                        It is our belief that transparency can help to reestablish trust, but transparency in combination with shared stories lead to empowerment, community, and thriving. Our software highlights farmers and their stories, what food means to them, how they choose to care for and cultivate the land, what resource efficiency means to them. We are passionate about life that is vibrant and nourishing for all.
                                    </p>
                                    <p>
                                        Though our streamlined software, we are setting out to revitalizing a sense of connection between food growers, preparers, and eaters. We are not just a technology company; we are storytellers who use boldly transparent technology to rekindle wonderment and connection, reminding us of the everyday magic that conspires to sustain us all: food.
                                    </p>
                                    <p>
                                        Food is a global language of love, nourishment, connection, and thriving. It is the intimate experience that connects us to an immense web of life and serves as a symbol of our innate belonging to this world, in contrast to the increasing isolation of our modern lives.
                                    </p>
                                    <p>
                                        Bon Harvest is proud to become a Technology partner for Rethink Food, a nonprofit
                                        whose mission is to create a more sustainable and equitable food system. The
                                        partnership underscores the commitment of Rethink Food and Bon Harvest to
                                        reduce food insecurity and nourish healthy and thriving communities: Bon Harvest’s
                                        platform will enable Rethink Food to not only distribute donated goods to partners
                                        but also to purchase farm products to prepare nutritious meals for communities
                                        impacted by food insecurity. Farms can also donate goods to Rethink Food.

                                    </p> */}
                                    <h2 id="whatis">About Rethink Food</h2>
                                    <p>
                                    Rethink Food is a nonprofit whose mission is to create a more sustainable and equitable food system. Founded in 2017, Rethink Food developed a process to transform excess food from restaurants, corporate kitchens, and grocery stores into nutritious meals and distribute them via community-based organizations (CBOs).

                                    </p>
                                    <p>
                                    With food insecurity escalating and restaurants facing widespread closures during the pandemic, Rethink Food leveraged its experience as a food rescue and distribution model to launch Rethink Certified, a partnership program which provides grants and resources to local restaurants, so they can prepare community meals as a part of their daily operation. The purpose is twofold: feed local communities and keep restaurants in business, not just in times of crisis. Since April 2020, Rethink Food has invested over $12 million into 55 restaurants and has provided over 2.4 million meals to communities impacted by food insecurity through the Rethink Certified program.
Rethink Food also utilizes two other practical models to reduce food insecurity across communities: The Rethink Café, a donation-based cafe that invites everyone to enjoy a nutritious meal for a suggested donation of $5, and the Rethink Commissary, which converts donated food items into nutritious meals to deliver to CBOs at no cost. If you can please consider donating to Rethink Food <a href="https://give.rethinkfood.org/give/301866/#!/donation/checkout" style = {{color: "DodgerBlue", textDecoration: "underline"}}>here</a>
. For more information, visit <a href="https://www.rethinkfood.org/">rethinkfood.org</a> and follow Rethink Food on <a href="https://www.instagram.com/rethinkfood/">Instagram</a>, <a href="https://www.facebook.com/RethinkFoodHQ/">Facebook</a> &
<a href = "https://twitter.com/RethinkFood"> Twitter</a>.

</p>
                                    <p>
                                    Bon Harvest is proud to become a Technology partner for Rethink Food, a nonprofit whose mission is to create a more sustainable and equitable food system. The partnership underscores the commitment of Rethink Food and Bon Harvest to reduce food insecurity and nourish healthy and thriving communities: Bon Harvest’s platform will enable Rethink Food to not only distribute donated goods to partners but also to purchase farm products to prepare nutritious meals for communities impacted by food insecurity. Farms can also donate goods to Rethink Food.


                                    </p>
                                <p>
                                It is our belief that transparency can help to reestablish trust, but transparency in combination with shared stories lead to empowerment, community, and thriving. Our software highlights farmers and their stories, what food means to them, how they choose to care for and cultivate the land, what resource efficiency means to them. We are passionate about life that is vibrant and nourishing for all.
Though our streamlined software, we are setting out to revitalizing a sense of connection between food growers, preparers, and eaters. We are not just a technology company; we are storytellers who use boldly transparent technology to rekindle wonderment and connection, reminding us of the everyday magic that conspires to sustain us all: food.
Food is a global language of love, nourishment, connection, and thriving. It is the intimate experience that connects us to an immense web of life and serves as a symbol of our innate belonging to this world, in contrast to the increasing isolation of our modern lives.
                                </p>
                                </div>
                                {/* <h2 style = {{
                                    fontSize: 29
                                }}>
                                        <b>TO DONATE TO RETHINK PLEASE FIND THE FORM BELOW</b>
                                    </h2>
                                    <a 
                                    download
                                    href = 'donation form.pdf'
                                    style={{
                                        fontSize: 20,
                                        position: "absolute",
                                        left: "41%",
                                        marginTop: 20
                                    }}
                                        class="button is-success is-rounded">
                                        <i style={{ marginRight: 6 }} class="fas fa-arrow-circle-down"></i>
                                              Download Form
                                    </a> */}
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="footer">
                    <div class="container">
                        <div class="columns">
                            <div class="column is-3">
                                <div class="content">
                                    <a href="#"><img src={topLogo} /></a>
                                    <h4><strong>Produce</strong>: Helping farmers sell it, helping chefs buy it.</h4>
                                    <p>
                                        <button onClick={() => this.props.history.push("/farmer_signup")} class="button is-rounded is-warning">
                                            <span class="icon">
                                                <i class="fas fa-tractor"></i>

                                            </span>
                                            <span><strong>Get early access</strong></span>
                                        </button>
                                    </p>
                                    <h4><a href="/about">About</a></h4>
                                    <h4><a href="/team">Team</a></h4>
                                    <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                                    <h4><a href="/data-problem">The Data Problem</a></h4>

                                    <h4><a href="/rethink">Rethink Food</a></h4>
                                </div>
                            </div>
                            <div class="column">
                                <div class="content">
                                    <div class="box">
                                        <article class="media">
                                            <div class="columns is-variable is-0">
                                                <div class="column">
                                                    <div class="media-left">
                                                        <a href="#"><img src={officeMap} alt="Bon Harvest office map" /></a>
                                                    </div>
                                                </div>
                                                <div class="column is-one-third">
                                                    <div class="media-content">
                                                        <div class="content has-text-white">
                                                            <p class="has-text-weight-bold">New Lab</p>
                                                            <p>
                                                                Building 128<br></br>
                                                19 Morris Ave<br></br>
                                                Brooklyn, NY 11205<br></br>
                                                USA
                                            </p>
                                                            <p>
                                                                <span class="icon has-text-primary">
                                                                    <i class="fas fa-phone"></i>
                                                                </span>
                                                917-724-4474
                                                <br></br>
                                                                <span class="icon has-text-primary">
                                                                    <i class="fas fa-envelope"></i>
                                                                </span>
                                                hello@bonharvest.com
                                            </p>
                                                        </div>
                                                        <nav class="level">
                                                            <div class="level-left">
                                                                <a class="level-item">
                                                                    <span class="icon is-medium has-text-warning">
                                                                        <i class="fab fa-2x fa-facebook-f"></i>
                                                                    </span>
                                                                </a>
                                                                <a class="level-item">
                                                                    <span class="icon is-medium has-text-warning">
                                                                        <i class="fab fa-2x fa-twitter"></i>
                                                                    </span>
                                                                </a>
                                                                <a href="https://www.instagram.com/bonharvest/" class="level-item">
                                                                    <span class="icon is-medium has-text-warning">
                                                                        <i class="fab fa-2x fa-instagram"></i>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </body>
        )
    }
}
export default AboutRethink;