import React from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import axios from 'axios'
import titleWhite from '../images/bon-harvest-title-white.png'
import green from '../images/bon-harvest-title-green.png'
//const FormItem = Form.Item;

class BuyerSignUp extends React.Component {
  state = {
    username: "",
    email: "",
    password: "",
    password2: "",
    is_farmer: false,
    phone_number: "",
    first_name: "",
    last_name: "",
    address_line_1: "a",
    address_line_2: "b",
    city: "z",
    state: "z",
    zipcode: "",
    farm_size: "N/A",
    farm_type: "N/A",
    produce_type: ["Money"],
    button1Class: "button is-warning",
    button2Class: "button is-warning is-light",
    button3Class: "button is-warning is-light",
    validZipCode: true,
    validPassword: true,
    validPhone: true,
    validName: true,
    validFirstName: true,
    validLastName: true,
    validEmail: true,
    passwordErrorMessage: "",
    emailErrorMessage: "",
  };



  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
  async validateEmail(email) {
    if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)){
        this.setState({emailErrorMessage: "Please enter a valid email"})
        return false
    }
    const res = await axios.post('https://api.farmersofamerica.net/api/checkfields/', {'email':email})
    if(!res.data["valid"]){
        this.setState({emailErrorMessage: "Email is in use already"})
    }
    return res.data["valid"]
       
  }
  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords must match!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

    handleChange(event, param){
        const validMappings = {
            "zipcode":"validZipCode",
            "password":"validPassword",
            "password2":"validPassword",
            "phone_number":"validPhone",
            "first_name":"validFirstName",
            "last_name":"validLastName",
            "username":"validName",
            "email":"validEmail"
            
        }
        this.setState({
           [param]:event.target.value,
           [validMappings[param]]:true}
         );
    }
    
    updateFarmType = (value) => {
      this.setState({
        farm_type: value
      })
    }
    updateProduceType = (value) => {
      this.setState({
        produce_type: value
      })
   }

    async submit(){
        console.log("props 111", this.props.error)
        var canSubmit = true
        if(this.state.first_name.length == 0){
            
            this.setState({validFirstName: false})
            canSubmit = false
        }
        if(this.state.last_name.length == 0){
            this.setState({validLastName: false})
            canSubmit = false
        }
        if(this.state.username.length == 0){
            this.setState({validName: false})
            canSubmit = false
        }
        if(this.state.first_name.length == 0){

            this.setState({validFirstName: false})
            canSubmit = false
        }
        if(!this.validatePassword(this.state.password, this.state.password2)){
            canSubmit = false
        }
        if(!/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(this.state.phone_number)){

            canSubmit = false
            this.setState({validPhone:false})
        }
        if(!/^\d{5}$/.test(this.state.zipcode)){

            canSubmit = false
            this.setState({validZipCode:false})
        }
        const validEmail = await this.validateEmail(this.state.email)
        if(!validEmail){
            canSubmit = false
            this.setState({validEmail: false})
        }
        if(canSubmit){
            try{
            const x = await this.props.onAuth(
                this.state.username,
                this.state.email,
                this.state.password,
                this.state.password2,
                this.state.is_farmer,
                this.state.first_name,
                this.state.last_name,
                this.state.phone_number,
                this.state.address_line_1,
                this.state.address_line_2,
                this.state.city,
                this.state.state,
                this.state.zipcode,
                this.state.farm_size,
                this.state.farm_type,
                this.state.produce_type
            )
            console.log("x",this.props.error, this.props)
            console.log("props 170", this.props.error)
        } catch(err){
            console.log("err")
            console.log(err)
        }

        }
        
    }
    validatePassword(p1, p2){
        console.log("validation",p1,p2)
        if(p1.length === 0 || p2.length == 0){
            this.setState({validPassword:false, passwordErrorMessage:'Please enter and confirm your password'})
            return false
        }
        if (p1!==p2) {
          this.setState({validPassword:false, passwordErrorMessage:'Passwords must match'})
          return false
        } else if(p1.length <8 ) {
            this.setState({validPassword:false, passwordErrorMessage:'Password is too short!'})
            return false
        } else if(p1.toUpperCase() === p1 || p1.toLowerCase() === p1) {
            this.setState({validPassword:false, passwordErrorMessage:'Password must contain uppercase and lowercase characters!'})
            return false
        } else if (!/\d/.test(p1)){
          this.setState({validPassword:false, passwordErrorMessage:'Password must contain at least one number!'}) 
        } else {
          return true
       }
    }
  render() {
    const token = localStorage.getItem('token');
    if(token!==null){
        this.props.history.push("/dashboard")
    }
      const {username, email,password,password2, is_farmer, first_name,
            last_name, address_line_1, address_line_2, city,state,
            zipcode,farm_size,farm_type, produce_type, phone_number
            } = this.state
      const values = {username, email,password,password2, is_farmer, first_name,
            last_name, address_line_1, address_line_2, city,state,
            zipcode,farm_size,farm_type, produce_type, phone_number
            }
            return (
                <body style = {{fontFamily: 'GothamRound'}}> 
                <section class="section has-background-primary">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-one-quarter">
                                <div class="content" style = {{textAlign: "center"}}>
                                    <a href="index.html"><img src={titleWhite}alt="Bon Harvest title"/></a>
                                    <h4 style = {{marginTop: 10}} class="has-text-white">Sign up as a Buyer</h4>
                                </div>
                            </div>
                        </div>
                        <div class="columns is-centered">
                            <div style = {{width: 700}} class="column is-half has-background-white">
                                <div class="content">
        
                                    <div class="field">
                                        <label class="label is-large">Business</label>
                                        <div class="control has-icons-left">
                                            <input className="input is-large" type="text" placeholder="Business name" onChange={(e)=>this.handleChange(e, "username")}/>
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-building"></i>
                                            </span>
                                            {
                                                        !this.state.validName ?
                                                    <p class="help is-danger">
                                                        This field cannot be blank
                                                    </p> :
                                                        null
                                                     }
                                        </div>
                                    </div>
        
                                    <label class="label is-large">Contact</label>
                                    
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                                <p class="control is-expanded">
                                                    <input className="input" type="text" placeholder="First name" onChange={(e)=>this.handleChange(e, "first_name")}/>
                                                </p>
                                                    {
                                                        !this.state.validFirstName ?
                                                    <p class="help is-danger">
                                                        This field cannot be blank
                                                    </p> :
                                                        null
                                                     }
                                            </div>
                                            <div class="field">
                                                <p class="control is-expanded">
                                                    <input className="input" type="text " placeholder="Last name" onChange={(e)=>this.handleChange(e, "last_name")}/>
                                                </p>
                                                {
                                                        !this.state.validLastName ?
                                                    <p class="help is-danger">
                                                        This field cannot be blank
                                                    </p> :
                                                        null
                                                     }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <input class="input" type="tel" placeholder="Phone"onChange={(e)=>this.handleChange(e, "phone_number")}/>
                                                    <span class="icon is-small is-left">
                                                        <i class="fas fa-phone"></i>
                                                    </span>
                                                </p>
                                                {
                                                        !this.state.validPhone ?
                                                    <p class="help is-danger">
                                                        Please enter a valid phone number (only numbers)
                                                    </p> :
                                                        null
                                                     }
                                            </div>
                                            <div class="field">
                                                <p class="control has-icons-left has-icons-right">
                                                    <input class="input" maxLength="5" type="text" placeholder="Zip code" onChange={(e)=>this.handleChange(e, "zipcode")}/>
                                                    <span class="icon is-small is-left">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                    </span>
                                                </p>
                                                    {
                                                        !this.state.validZipCode ?
                                                    <p class="help is-danger">
                                                        Please enter a valid zipcode
                                                    </p> :
                                                        null
                                                     }
                                            </div>
                                        </div>
                                    </div>
                                    
                            
        
                                    <div class="field">
                                        <label class="label is-large">Email</label>
                                        <div class="control has-icons-left has-icons-right">
                                            <input className="input is-large" type="email" placeholder="example@yoursite.com" onChange={(e)=>this.handleChange(e, "email")}/>
                                            <span class="icon is-small is-left">
                                                <i class="fas fa-envelope"></i>
                                            </span>
                                            {
                                                        !this.state.validEmail ?
                                                    <p class="help is-danger">
                                                        {this.state.emailErrorMessage}
                                                    </p> :
                                                        null
                                                     }
                                        </div>
                                    </div>
                                    
                                    <div class="field is-horizontal">
                                        <div class="field-body">
                                            <div class="field">
                                                <div class="control has-icons-left">
                                                    <input class="input" type="password" placeholder="Password"onChange={(e)=>this.handleChange(e, "password")} />
                                                    <span class="icon is-small is-left">
                                                        <i class="fas fa-lock"></i>
                                                    </span>
                                                    {
                                                        !this.state.validPassword ?
                                                    <p class="help is-danger">
                                                        {this.state.passwordErrorMessage}
                                                    </p> :
                                                        null
                                                     }
                                                </div>
                                            </div>
                                            <div class="field">
                                                <div class="control">
                                                    <input class="input" type="password" placeholder="Confirm password" onChange={(e)=>this.handleChange(e, "password2")}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="field is-grouped">
                                        <div class="control">
                                            <button onClick = {()=>this.submit()} class="button is-link">Submit</button>
                                        </div>
                                        <div class="control">
                                            <a href="/" class="button is-link is-light">Cancel</a>
                                        </div>
                                        <div class="control">
                                            <a style={{marginLeft: 330}} href="/farmer_signup" class="button is-light">Farmer signup</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="footer">
                    <div class="container">
                        <div class="columns">
                            <div class="column is-one-quarter">
                                <div class="content">
                                    <a href="index.html"><img src={green}/></a>
                                    <h4>Quick tagline</h4>
                                    <p>
                                    <button onClick = {()=>this.props.history.push("/farmer_signup")}className="button is-rounded is-danger"> 
                                        <span class="icon">
                                            <i class="fas fa-tractor"></i>
                                        </span>
                                        <span><strong>Get early access</strong></span>
                                    </button>
                                </p>
                                <h4><a href="/about#ethics">Bon Harvest Ethics</a></h4>
                                <h4><a href="/about#story">Our Story</a></h4>
                                <h4><a href="/about#history">Our History</a></h4>
                                <h4><a href="/team">Team</a></h4>
                                <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                                <h4><a href="/data-problem">The Data Problem</a></h4>
                                </div>
                            </div>
                            <div class="column is-2">
                                <div class="content">
                                    <p class="has-text-weight-bold">New Lab</p>
                                    <p>
                                        Building 128<br></br>
                                        19 Morris Ave<br></br>
                                        Brooklyn, NY 11205<br></br>
                                        USA
                                    </p>
                                </div>
                            </div>
                            <div class="column is-2">
                                <div class="content">
                                    <p>
                                        <span class="icon has-text-primary">
                                            <i class="fas fa-phone"></i>
                                        </span>
                                        917-724-4474
                                        <br></br>
                                        <span class="icon has-text-primary">
                                            <i class="fas fa-envelope"></i>
                                        </span>
                                        hello@bonharvest.com
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </body>
            )
     
    }
}
const WrappedRegistrationForm = Form.create()(BuyerSignUp);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, email, password1, password2, is_farmer,
           first_name, last_name, phone_number, address_line_1, address_line_2, city, state, zipcode, farm_type, farm_size, produce_type) => 
           dispatch(actions.authSignup(username, email, password1, password2, is_farmer, first_name, last_name, phone_number, address_line_1, address_line_2, city, state, zipcode, farm_type, farm_size, produce_type)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);