import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { compose, createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducer from './store/reducers/auth';
import WebFont from 'webfontloader';
import './index.css';

WebFont.load({
    google: {
      families: ['Quicksand:300,400,700', 'sans-serif']
    }
  });
  
const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose

const store = createStore(reducer, composeEnhances(
    applyMiddleware(thunk)

));

const app = (
    <Provider store={store}>
        <App />
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.register();