import React from 'react';
import {
    List, Avatar, Button, message
} from 'antd';
import axios from 'axios';
class FriendRequest extends React.Component {
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    accept(id) {
        console.log("Accepting request",id)
        const url = `https://api.farmersofamerica.net/api/requests/${id}/`
        const data = {action: "accept"}
        axios.put(url,data,this.config)
        .then(res=>{
            message.success("Client request accepted!",0.75)
            this.props.update_requests(id)
        })
        .catch(res=>{
            console.log(
                res.response
            )
            message.error("Unable to accept request. This shouldn't happen")
        })
    }
    decline = (id) =>{
        console.log("Accepting request",id)
        const url = `https://api.farmersofamerica.net/api/requests/${id}/`
        const data = {action: "decline"}
        axios.put(url,data,this.config)
        .then(res=>{
            message.success("Client request declined!",0.75)
            this.props.update_requests(id)
        })
        .catch(res=>{
            console.log(
                res.response
            )
            message.error("Unable to decline request. This shouldn't happen")
        })
    }

    render() {
        return (
            <div className="demo-infinite-container">

                <List
                    locale = {{emptyText: "No requests"}}
                    itemLayout="horizontal"
                    dataSource={this.props.data}
                    renderItem={user => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar shape = "circle" size = {50}>{user.sender[0]}</Avatar>}
                                title={user.sender}
                                description={user.email}
                            />
                            <Button type = "primary" style = {{marginRight: "5vw",background: "#00cb70", border: "2px solid #00cb70"}}onClick ={() => {this.accept(user.id)}}>
                                Accept
                            </Button>
                            <Button onClick ={() => {this.decline(user.id)}}>
                                Decline
                                </Button>
                        </List.Item>
                        
                    )}
                />
            </div>
        );
    }
}

export default FriendRequest;
