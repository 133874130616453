import React from 'react';
import {
  List, Avatar
} from 'antd';
import icon from '../images/icon.jpg';

class ReviewDonateItem extends React.Component {
  config = {
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  getImageUrl(url, name){
    console.log(url, name)
    if(url == null  || url === ""){
      return this.getImage(name)
    } else{
        return url
    }
  }
  getImage = (name) => {
    console.log("get image", name)
    if(name!=null){
      name = name.toLowerCase()
      name = name.split(" ")[0]
    }
    console.log("names",name)
    const available_veggies = [
        "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
        "corn", "arugula", "beansprouts", "cucumbers", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
      ]
    if(available_veggies.includes(name)){
      const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
      return url
    }
    else{
      return icon
    }
  }
  render() {
    const keys = Object.keys(this.props.data)
    const obj = this.props.data
    console.log(keys, obj)
    const review= []
    for(const key of keys){
        if(obj[key].amount > 0){
            const temp = obj[key].item
            temp.donation_amount = obj[key].amount
            review.push(temp)
        }
    }
    console.log("the list", review)
    return (
      <div className="demo-infinite-container">

        <List
          dataSource={review}
          renderItem={item => (
            <List.Item 
                key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={this.getImageUrl(item.image_url, item.product_name)} size={60} />}
                title={item.product_name}
                description={item.unit_quantity+" "+item.unit_of_measurement}
              />
              <div>
                <h1 style={{  }}>Amount: {item.donation_amount}</h1>
              </div>
              </List.Item>
          )}
        >
        </List>
      </div>
    );
  }
}

export default ReviewDonateItem;
