import React from 'react';
import {
  List, Avatar, Button,
} from 'antd';
import Item from './Item'
import icon from '../images/icon.jpg';
import fruit from '../images/fruit.png'
import meat from '../images/meat.png'
import veggie from '../images/veggie.png'
import dairy from '../images/dairy.png'
import canned from '../images/canned.png'

class Product extends React.Component {
    imageMap = {
      "Other":icon,
      "Livestock":meat,
      "Vegetable":veggie,
      "Fruit":fruit,
      "Beverage & Canned":canned,
      "Dairy":dairy,
    };
    getImage = (name) => {
      if(name!=null){
        name = name.toLowerCase()
      }
      const available_veggies = [
        "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
        "corn", "arugula", "beansprouts", "cucumber", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
      ]
      if(available_veggies.includes(name)){
        const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
        return url
      }
      else{
        return icon
      }
    }
  render() {
    return (
      <div className="product">
          <List
            className = "list"
            dataSource={this.props.data}
            renderItem={item => (
              <List.Item key={item.id} actions={
                [
                item.is_owner
                ? 
                  <Button type="primary"  style = {{marginRight: "1vw", background: "#00cb70", border: "2px solid #00cb70", width:"4vw", height:"4vh", fontSize: "16px"}}> 
                  <a href={`/items/${item.id}`}>Edit</a>
                  </Button> 
                  :
                  null
                ]

              }>
                <List.Item.Meta
                  avatar={<Avatar src={this.getImage(item.name)} style={{
                    marginLeft: "1vw"
                  }}
                  size={70} shape = "circle" />}
                  title={item.name}
                  description={<p style = {{fontSize:"14px"}}>{item.description}</p>}
                />
              <div>
                  {
                    item.items != null ?
                   
                    <Item  data = {item.items} owner = {item.is_owner} />
                    :
                    null
                  }
                </div>

              </List.Item>
            )}
          >
          </List>
      </div>
    );
  }
}

export default Product;