import React from 'react';
import {
  List, Avatar, InputNumber, Button, Popconfirm, message
} from 'antd';

import icon from '../images/icon.jpg';
import fruit from '../images/fruit.png'
import meat from '../images/meat.png'
import veggie from '../images/veggie.png'
import axios from 'axios'

class DonateItem extends React.Component {
  imageMap = {
    "Other": icon,
    "Livestock": meat,
    "Vegetable": veggie,
    "Fruit": fruit,
  };
  config = {
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  updateValue = (id, e) => {
    const url = "https://api.farmersofamerica.net/api/itemorder/" + id + "/"
    axios.patch(url, {
      quantity: e
    }, this.config)
  }
  confirm = (id) => {
    const url = "https://api.farmersofamerica.net/api/itemorder/" + id + "/"
    axios.delete(url,this.config)
    message.info('Item succesfully removed');
    this.props.delete(id)

  }
  getImageUrl(url, name){
    if(url == null  || url == ""){
      return this.getImage(name)
    } else{
        return url
    }
  }
  getImage = (name) => {
    if(name!=null){
      name = name.toLowerCase()
      name = name.split(" ")[0]
    }
    console.log("names",name)
    const available_veggies = [
      "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
      "corn", "arugula", "beansprouts", "cucumber", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
    ]
    if(available_veggies.includes(name)){
      const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
      return url
    }
    else{
      return icon
    }
  }
  render() {
    console.log("from donate", this.props.data)
    const products = this.props.data
    const items = []
    for(let i = 0; i<products.length; i++){
        for(let j = 0; j<products[i].items.length; j++){
            var temp_item = products[i].items[j]
            temp_item["image_url"] = products[i]["image_url"]
            items.push(temp_item)
        }
    }
    return (
      <div className="demo-infinite-container">

        <List
          dataSource={items}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={this.getImageUrl(item.image_url, item.item_name)} size={60} />}
                title={item.product_name}
                description={item.unit_quantity+" "+item.unit_of_measurement}
              />
              <div>
                <InputNumber key="1" style={{ width: "60px", marginLeft: "2vw", marginRight: "3vw" }} min={0} max={item.amount_in_stock} defaultValue={0} onChange={(e) => this.props.update(item, e)} />
              </div>

            </List.Item>
          )}
        >
        </List>
      </div>
    );
  }
}

export default DonateItem;
