import React from 'react';
import {
    Input, List, Avatar, Button, Select, notification
} from 'antd';
import { Link } from 'react-router-dom'
import icon from '../images/correctgreen.png'
import axios from 'axios';
import {NavLink} from 'react-router-dom';
const { Search } = Input;
const Option = Select.Option
class Client extends React.Component {
    state = {
        refresh: true
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    sendRequest = (id, username) => {
        const url = `https://api.farmersofamerica.net/api/requests/`
        const data = { target: id }
        axios.post(url, data, this.config)

        for(var index = 0; index<this.props.data.length; index++){
            if(this.props.data[index].id === id){
                this.props.data[index].client_status = "sent"
                this.setState(prevState => ({
                    refresh: !prevState.refresh
                  }));
                break
            }
        }
        notification["success"]({
            message: 'Request Sent to '+username,
            duration: 2
          });
    }
    handleChange(value) {
        const values = value.split("#")

        const url = `https://api.farmersofamerica.net/api/viewprivilege/${values[1]}/`
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        axios.put(url, { number: values[0] }, config)

    }
    getButton(status, id, username, isFarmer) {
        console.log(username, isFarmer)
        switch(status){
            case "connected":
                return(
                    <NavLink to ={`profile/${id}`}>
                <button style = {{fontSize: 12.5, width: 150}} className = "button" >
                    View Profile
            </button>
            </NavLink>)
            case "not_connected":
                return(<button style = {{fontSize: 12.5, width: 150}}  className = "button" onClick={(e) => this.sendRequest(id, username)}>
                {isFarmer === false ? "Add Client": "Add Farm"}
            </button>)

            case "sent":
                return(
                    <button style = {{fontSize: 12.5, width: 150}} className = "button" disabled>
                        Sent
                    </button>
                )
        }
    }
    getProfilePicture(url){
        if(url == null || url == ""){
            return icon
        } 
        return url
    }
    convertToMain(value){
        if(value === "0"){
            return "Main"
        } else{
            return value
        }
    }

    getInvenSelects(id){
        const names = this.props.inven_names
        if(names.length === 0){
            return []
        }
        const toReturn = []
        for(const name of names){
            toReturn.push(<Option value={`${name.id}#${id}`} key={name.id}>{name.name}</Option>,)
        }
        return toReturn
    }
    render() {
        return (
            <div className="clientList" style={{ height: "60vh"}}>
                <List
                    locale={{emptyText: " "}}
                    itemLayout="horizontal"
                    dataSource={this.props.data}
                    renderItem={user => (
                        <List.Item>
                            <div class="column is-5">
                            <List.Item.Meta
                                avatar={<Avatar size="large" style={{marginTop: 5}} src = {this.getProfilePicture(user.profile_url)}/>}
                                title={user.username}
                                description={user.email}
                                key = {user.email}
                                style={{marginLeft:10}}
                            />
                            </div>
                            {
                                this.props.search ?
                                <div style = {{marginLeft: 100}}class="column is-4">
                                    {this.getButton(user.client_status, user.id, user.username, user.is_farmer)}
                                </div>
                                    :
                                    user.is_farmer ?
                                        [
                                            <div class="column is-3">
                                        <NavLink to={`inventory/${user.id}`}>
                                            <button class = "button" key="inventory" style={{ width: 150, fontSize: 14 ,marginRight: 20 }}>
                                                View Inventory
                                            </button>
                                        </NavLink>
                                        </div>,
                                        <div class="column is-3">
                                            
                                                <Link to={`profile/${user.id}`}>
                                                <button class = "button" key="profile"  style={{ width: 150, fontSize: 14, marginRight: 30 }}>
                                                    View Profile
                                                    </button>
                                                </Link>
                                            
                                            </div>
                                        ]
                                        :
                                        [
                                            <div class="column is-2">
                                            <h1 style={{ marginRight: 10 }}>
                                                Set View Privilege:
                                            </h1>
                                            </div>,
                                            <div class="column is-3">
                                            <Select defaultValue={this.convertToMain(user.view_privilege)} style={{ fontSize: 11.5, width: 135}} onChange={this.handleChange}>
                                                {this.getInvenSelects(user.id)}
                                            </Select>
                                            </div>,
                                            <div class="column is-3">
                                            <button  className = "button" style={{fontSize: 13, width: 100}}>
                                                <Link to={`profile/${user.id}`}>
                                                    View Profile
                                            </Link>
                                            </button>
                                            </div>,
                                        ]
                            }
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}

export default Client;