import React from 'react';
import axios from 'axios';
import TransactionItems from '../components/TransactionItems'
class TransactionItemContainer extends React.Component {
    state = {
        refresh: false,
        transactions : []
    }
    componentWillReceiveProps() {
        const config = {
            headers:{
                'Authorization' : 'Token ' + localStorage.getItem('token')
        }
       }
       const transactionId = this.props.match.params.transId
       const url = "https://api.farmersofamerica.net/api/transactionitems/"+transactionId
        axios.get(url, config)
            .then(res => {
                this.setState({
                    transactions: res.data
                })
                console.log(this.state.transactions)
            })
    }
    componentDidMount() {
        const config = {
            headers:{
                'Authorization' : 'Token ' + localStorage.getItem('token')
            }
        }
        const transactionId = this.props.match.params.transId
       const url = "https://api.farmersofamerica.net/api/transactionitems/"+transactionId
        axios.get(url, config)
            .then(res => {
                this.setState({
                    transactions: res.data
                })
                console.log(this.state.transactions)
            })
    }
    render(){
        return (
            <div>
                <h2 style = {{fontSize: 25}}>
                    Transaction Date: {this.state.transactions.length!=0 ? this.state.transactions[0].transaction_date.substring(0,10) : ""}
                     <TransactionItems data={this.state.transactions}/>
                    </h2>
            </div>
        );
    }
}
export default TransactionItemContainer;