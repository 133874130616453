import React from 'react'
import topLogo from '../images/bon-harvest-title-green.png'
import officeMap from '../images/office-map-png.png'
import veggies from '../images/dataproblem.jpg'
class About extends React.Component {

    render() {
        return (
            <body style = {{fontFamily: 'GothamRound'}}>
                <nav class="navbar" role="navigation" aria-label="main navigation">
                    <div class="navbar-brand">
                        <a class="navbar-item" href="/">
                            <img src={topLogo} />
                        </a>
                        <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div class="navbar-menu is-size-5 has-text-weight-medium">
                        <div class="navbar-end">
                            <a href="/data-problem" class="navbar-item is-active">
                                The Data Problem
                             </a>
                            <div class="navbar-item has-dropdown is-hoverable">
                                <a href="/about" class="navbar-link is-active">
                                    About
                                </a>
                                <div class="navbar-dropdown">
                                    <a href="#story" class="navbar-item">
                                        Story
                                    </a>
                                    <a href="#ethics" class="navbar-item">
                                        Ethics
                                    </a>
                                    <a href="#history" class="navbar-item">
                                        History
                                    </a>
                                    <hr class="navbar-divider" />
                                    <a href="/team" class="navbar-item">
                                        Team
                                    </a>
                                </div>
                            </div>
                            <a href="/rethink" class="navbar-item">
                                Rethink Food
                            </a>
                            <a href="https://blog.bonharvest.com" class="navbar-item">
                                Blog
                            </a>
                            <div class="navbar-item">
                                <div class="buttons">
                                    <a href="/farmer_signup" class="button is-medium is-rounded is-danger has-text-weight-semibold">
                                        Get early access
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section class="hero is-large parallax" style={{"backgroundImage": `url(${veggies})`}}>
                    <div class="hero-body">
                        <div class="container">
                            <h1 class="title is-size-1 has-text-white has-text-weight-bold">
                                About Bon Harvest
                             </h1>
                        </div>
                    </div>
                </section>
                <section class="section">
                    <div class="container">
                        <div class="columns is-centered">
                            <div class="column is-two-thirds">
                                <div class="content is-medium">
                                    <h2 id="story">Our Story</h2>
                                    <p>
                                        We’re a diverse group of individuals—each of us coming from different professional, educational, generational, and geographical backgrounds—unified by, and working towards the same, two-fold mission: 1) Leverage technology to improve the lives of the hardworking, under-appreciated people who feed us, and 2) Reduce the amount of food we as a society currently waste.
                                    </p>
                                    <p>
                                        If you know anyone who operates a farm or a restaurant, you probably already know how much pressure they’re constantly under. Even if you remove the financial stressors that operating a farm or a restaurant invokes, there are scores of additional mission-critical tasks and challenges that actively work against them.
                                    </p>
                                    <p>
                                        <span class="has-text-weight-semibold">We at Bon Harvest are focused on just one: Inventory Management</span>. While its true that farms across the world are deploying new-age technologies to grow better-tasting, healthier food, the truth remains that when it comes to real-time communication with their produce buyers, farms still suffer huge financial losses from food waste. Our easy-to-use, mobile-friendly software dramatically reduces the amount of time needed to sufficiently communicate what items are available (to the buyers), and helps eliminate farmer-side food waste by enabling restaurants to competitively bid on produce nearing its “unsellable” date.
                                    </p>
                                    <p>
                                        Bon Harvest isn’t going to solve all of the challenges that farms and restaurants are facing right now, but we are certain that as it pertains to maintaining and communicating real-time inventory to-and-from the farm to the restaurant, we will make a massive impact, both in terms of time and money saved.
                                    </p>
                                    <h2 id="ethics">Our Ethics</h2>
                                    <p>
                                        In alignment with our core mission to provide our farmers and food buyers with a fair and ethical platform to communicate over inventory, Bon Harvest pledges to…
                                    </p>
                                    <div class="columns">
                                        <div class="column">
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-users"></i>
                                                </span>
                                                Put Food People First
                                            </h5>
                                            <p>
                                                We know how challenging it is to be in the food industry, because we’re in it with you. Strategic business decisions that we make will always be with you, the farmer, the chef, and the restaurant owner in mind.
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-columns"></i>
                                                </span>
                                                Keep it Simple
                                            </h5>
                                            <p>
                                                As a farm or restaurant operator, we know that you have thousands of various technologies at your disposal; so many of them are unnecessarily complex. Our technology will always be built for ease-of-use, with the ultimate goal of saving you time and money.
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-piggy-bank"></i>
                                                </span>
                                                Keep it Cheap
                                            </h5>
                                            <p>
                                                From an economics standpoint, few food industry businesses have it easy. Budgets are tight, and profits are ever-thinning. To our farming partners: we vow to never raise your subscription price without ample notice and thorough explanation. To our food buyers: in an effort to always provide our farms with the highest produce selling potential, Bon Harvest will always be free.
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-people-arrows"></i>
                                                </span>
                                                Exclude No Grower or Buyer
                                            </h5>
                                            <p>
                                                Our intention is to create a platform that opens up the food industry. With that in mind, we will never exclude any growers or buyers from using the platform, so long as they adhere to our community guidelines
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-chart-line"></i>
                                                </span>
                                                Display All Data
                                            </h5>
                                            <p>
                                                Your trust is essential to the success and strength our business. Part of earning that trust is ensuring that you will have access to all of the information you need to make better short and long-term decisions for your farm or restaurant.
                                            </p>
                                        </div>
                                        <div class="column">
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-user-secret"></i>
                                                </span>
                                                Respect Your Privacy
                                            </h5>
                                            <p>
                                                While our goal is to build a more-transparent food industry, we also know that many farms and restaurants already have exclusive pricing arrangements together. Bon Harvest will never publicly display any pricing or inventory data that you wish to keep private. We’ll publish only the information you choose to display.
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-tractor"></i>
                                                </span>
                                                Protect Farmers From Price Bullying
                                            </h5>
                                            <p>
                                                We will never implement a feature or offer services that allow a food buyer—regardless of their size or financial strength—to gain a competitive position over a farmer using Bon Harvest. Your farm, your produce, your terms.
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-comments"></i>
                                                </span>
                                                Monitor Buyer & Seller Reviews
                                            </h5>
                                            <p>
                                                Integrity is inherently built into Bon Harvest’s culture, and also into how our platform operates. Doing business online makes it challenging to truly know who you’re working with. And so, our team regularly reviews information entered by our farms, and routinely ensures that our food buyers are communicating honestly about their business. If we discover dishonest, misleading, or intentionally incomplete information, we will address it directly.
                                            </p>
                                            <h5 class="has-text-primary">
                                                <span class="icon is-medium">
                                                    <i class="fas fa-user-shield"></i>
                                                </span>
                            Leverage Data Responsibly
                        </h5>
                                            <p>
                                                As we continue to build a better platform—and leverage your data to do so—we vow to never violate your trust by inappropriately using your data. When we do use it, we will always use data in its aggregated, anonymized form. Without explicit permission, we will never share individually identifiable data with a third party, for any reason. Ever.
                        </p>
                                        </div>
                                    </div>
                                    <h2 id="history">Our History</h2>
                                    <p>
                                        We didn’t start out as a software company. The origin of our story traces back to a Harvard Business School case study - <a href="https://store.hbr.org/product/doug-rauch-solving-the-american-food-paradox/512022">Doug Rauch: Solving The American Food Paradox</a> — that our Founder and CEO, Teymour El Derini, worked on in his MBA program at NYU’s Stern School of Business back in 2013.
                </p>
                                    <p>
                                        The paradox that Rauch covers in the study is simple: How can such a powerful, wealthy, and technologically advanced country like the United States have millions of citizens going hungry every day, but at the same time be routinely throwing out billions of tons of perfectly edible and delicious produce? This notion got Teymour thinking about ways he could address the problem, even at a small scale.
                </p>
                                    <p>
                                        Fast forward to 2017— through business school, and four more years working in finance— and Teymour launched Bon Harvest. Having never lost interest in the food waste problem, Bon Harvest initially began as a distributor of “ugly" produce, a term that refers to vegetables and fruits with aesthetic imperfections that are often immediately thrown in the trash. By 2019, nearly 100 farms and over 40 restaurants in the Tri-State area were regularly using Bon Harvest to buy and sell ugly produce.
                </p>
                                    <p>
                                        However, through daily interactions with our partner farmers, chefs, and restaurant owners, more pressing and pervasive issues began to reveal themselves to us:
                </p>
                                    <ul>
                                        <li>Lack of transparency and data visibility in the food supply chain</li>
                                        <li>Inefficient communication between farms and restaurants</li>
                                        <li>Shockingly high rate of late payments [to farmers] from produce buyers</li>
                                    </ul>
                                    <p>
                                        While our produce distribution business was growing steadily, it became obvious that we could have a bigger impact on food peoples’ lives by addressing each of the above issues. And so, we got to work on building a software platform that would increase inventory transparency between the farm and the kitchen, that would simplify and expedite communications between farmers and chefs, and that would offer American farmers the option to be paid the moment their produce is shipped.
                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="footer">
                    <div class="container">
                        <div class="columns">
                            <div class="column is-3">
                                <div class="content">
                                    <a href="#"><img src={topLogo} /></a>
                                    <h4><strong>Produce</strong>: Helping farmers sell it, helping chefs buy it.</h4>
                                    <p>
                                        <button onClick={()=>this.props.history.push("/farmer_signup")} class="button is-rounded is-warning">
                                            <span class="icon">
                                                <i class="fas fa-tractor"></i>
                                                
                                            </span>
                                            <span><strong>Get early access</strong></span>
                                        </button>
                                    </p>
                                    <h4><a href="/about">About</a></h4>
                                    <h4><a href="/team">Team</a></h4>
                                    <h4><a href="/rethink">Rethink</a></h4>
                                    <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                                    <h4><a href="/data-problem">The Data Problem</a></h4>
                                </div>
                            </div>
                            <div class="column">
                                <div class="content">
                                    <div class="box">
                                        <article class="media">
                                            <div class="columns is-variable is-0">
                                                <div class="column">
                                                    <div class="media-left">
                                                        <a href="#"><img src={officeMap} alt="Bon Harvest office map" /></a>
                                                    </div>
                                                </div>
                                                <div class="column is-one-third">
                                                    <div class="media-content">
                                                        <div class="content has-text-white">
                                                            <p class="has-text-weight-bold">New Lab</p>
                                                            <p>
                                                                Building 128<br></br>
                                                19 Morris Ave<br></br>
                                                Brooklyn, NY 11205<br></br>
                                                USA
                                            </p>
                                                            <p>
                                                                <span class="icon has-text-primary">
                                                                    <i class="fas fa-phone"></i>
                                                                </span>
                                                917-724-4474
                                                <br></br>
                                                                <span class="icon has-text-primary">
                                                                    <i class="fas fa-envelope"></i>
                                                                </span>
                                                hello@bonharvest.com
                                            </p>
                                                        </div>
                                                        <nav class="level">
                                                            <div class="level-left">
                                                                <a class="level-item">
                                                                    <span class="icon is-medium has-text-warning">
                                                                        <i class="fab fa-2x fa-facebook-f"></i>
                                                                    </span>
                                                                </a>
                                                                <a class="level-item">
                                                                    <span class="icon is-medium has-text-warning">
                                                                        <i class="fab fa-2x fa-twitter"></i>
                                                                    </span>
                                                                </a>
                                                                <a href="https://www.instagram.com/bonharvest/" class="level-item">
                                                                    <span class="icon is-medium has-text-warning">
                                                                        <i class="fab fa-2x fa-instagram"></i>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </body>
        )
    }
}
export default About;