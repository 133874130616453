
import React from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import axios from 'axios';
import defaultProfile from '../images/correctgreen.png'
import rethink from '../images/rethink.png'
const { Content} = Layout;
const { SubMenu } = Menu;
class NewLayout extends React.Component {
    state = {
        businessName: "",
        firstName: "",
        lastName: "",
        dashboard:"is-active",
        profile:" is-large",
        inventory:"is-large",
        customers:"is-large",
        orders:"is-large",
        rethink: "is-large",
        cart: "is-large",
        clientsearch: "is-large",
        profileUrl: null,
        loaded: false,
        logout: false,
        cartSize: 0,
        loaded: false,
    }
    updateCart(n){
        if(n===1.5){
            this.setState({cartSize: 0})
        } else{
            this.setState({cartSize: this.state.cartSize+n})
        }
    }
    updateCart = this.updateCart.bind(this);
    logout = () =>{
        this.props.history.push('/')
        this.props.logout()
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    changeSelection = (value) =>{
        console.log(value)
    }
    componentDidMount() {
        this.setActive()
        if(localStorage.getItem('token')==null){
            this.props.history.push("/")
        }
        if (this.userId == null) {
            this.url = 'https://api.farmersofamerica.net/api/profile'
        }
        else {
            this.url = 'https://api.farmersofamerica.net/api/profile/' + this.userId
        }
        axios.get(this.url, this.config)
            .then(res => {
                this.setState({
                    businessName: res.data.username,
                    firstName: res.data.first_name,
                    lastName: res.data.last_name,
                    profileUrl: res.data.profile_url,
                    loaded: true
                })
            }
            ).catch(res=>{
                const status = res.response.status
                switch(status){
                    case 404:
                        this.props.history.push("/")
                        break;
                    case 401:
                        this.props.history.push("/")
                        break;
                    case 403:
                        this.props.history.push("/")
                        break;
                    case 500:
                        this.props.history.push("/")
                }
            })
        axios.get('https://api.farmersofamerica.net/api/cartsize/', this.config).then(res=>{
            this.setState({cartSize: res.data.size})
        })

    }
    getProfilePicture() {
        if (!this.state.loaded) {
            return null
        }
        if (this.state.profileUrl == null || this.state.profileUrl.length == 0) {
            return defaultProfile
        }
        return this.state.profileUrl
    }
    setActive=()=>{
        var pathname = this.props.location.pathname.split("/")[1]
        const names = {
            "dashboard":"is-large",
            "profile": "is-large",
            "inventory":"is-large",
            "orders":"is-large",
            "rethink": "is-large",
            "clients": "is-large",
            "cart":"is-large"
        }
        if(pathname ==="requests" || pathname === "clientsearch"){
            pathname = "clients"
        } else if (pathname === "orders"){
            pathname = "orders"
        } else if (pathname == "" || pathname == null || pathname == "login"){
            pathname = "dashboard"
        } else if(pathname == "donate"){
            pathname = "rethink"
        }
        names[pathname] = "is-active"
        this.setState({
            dashboard:names["dashboard"],
            profile: names["profile"],
            inventory:names["inventory"],
            orders:names["orders"],
            clients:names["clients"],
            rethink:names["rethink"],
            cart:names['cart']
        })
    }
 
    
    render() {
        if(this.state.loaded !== true){
            return <div></div>
        }
        const isFarmer = localStorage.getItem('is_farmer')
        const token = localStorage.getItem('token');
        if(token==null){
            this.props.history.push("/")
        }
        return (
         <div style = {{fontFamily: 'GothamRound'}}>
            <section class="section">
                <div class="container" >
                    <div class="columns is-variable is-4">
                        <div class="column is-one-quarter">
                            <div class="columns is-variable is-2" >
                                <div class="column is-one-quarter" >
                                    <figure class="image is-circle">
                                        <a href="/profile"> <img class="is-rounded"style = {{maxWidth: 100, maxHeight: 100}} src={this.getProfilePicture()} alt=""/></a>
                                    </figure>
                                </div>
                                <div class="column">
                                    <h3 class="is-size-5 has-text-weight-semibold">{this.state.firstName+" "+this.state.lastName}</h3>
                                    <h4 class="has-text-grey">{this.state.businessName}</h4>
                                </div>
                            </div>
                            <aside class="menu main-menu" >
                                <ul class="menu-list">
                                    <li style = {{width: 225}}>
                                        <a href="/dashboard" className={this.state.dashboard}>
                                            <span class="icon is-large">
                                                <i class="fas fa-house-user"></i>
                                            </span>
                                            Dashboard
                                        </a>
                                    </li>
                                    <li style = {{width: 225}}>
                                        <a href="/profile" className={this.state.profile}>
                                            <span class="icon is-large">
                                                <i class="fas fa-user"></i>
                                            </span>
                                            Profile 
                                        </a>
                                    </li>
                                    {
                                        isFarmer==='true' ?
                                    
                                    <li style = {{width: 225}}>
                                        <a href="/inventory" className={this.state.inventory}>
                                            <span class="icon is-large">
                                                <i class="fas fa-store"></i>
                                            </span>
                                            Inventory
                                        </a>
                                    </li>
                                    :
                                    <li style = {{width: 225}}>
                                    <a href="/cart" className={this.state.cart}>
                                        <span class="icon is-large">
                                            <i class="fas fa-shopping-cart"></i>
                                        </span>
                                        Cart ({this.state.cartSize})
                                    </a>
                                    </li>
                                    }
                                    <li style = {{width: 225}}>
                                        <a href="/clients" className={this.state.clients}>
                                            <span class="icon is-large">
                                                {isFarmer === "true" ? <i class="fas fa-users"></i> : <i class="fas fa-tractor"></i>}
                                            </span>
                                            {isFarmer === "true" ? "Clients" : "Farms"}
                                        </a>
                                    </li>
                                    <li style = {{width: 225}}>
                                        <a href="/orders"className={this.state.orders}>
                                            <span class="icon is-large">
                                                <i class="fas fa-search-dollar"></i>
                                            </span>
                                            Orders
                                        </a>
                                    </li>
                                    <li style = {{width: 225}}>
                                        <a href="/donate">
                                            <span style = {{width: 100}}class="icon is-large">
                                                <i class = "fas fa-donate"></i>
                                                <img style = {{width: 98, marginLeft: 15, marginRight: -60}}src = {rethink}/>
                                            </span>
                                        </a>
                                    </li>
                                    <li style = {{width: 225}}>
                                        <a href="" onClick={() => { this.logout() }}>
                                            <span className="icon is-large">
                                                <i className="fas fa-lock"></i>
                                            </span>
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </aside>
                        </div>
                        <div class="column">
                        {React.cloneElement(this.props.children, { updateCart: this.updateCart })}
                        </div>
                    </div>
                </div>
            </section>
        </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}

export default withRouter(connect(null, mapDispatchToProps)(NewLayout));