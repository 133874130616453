import React from 'react';
import {
    Form, Input, Select, 
} from 'antd';

const { Option } = Select;

class ProductEditForm extends React.Component {
    state = {
        timeout: 0
    }
    itemData = []
    config = {
      headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }

    
    render() {
        return (
            <div key = {this.props.data.id}>
                <Form layout = 'inline'>
                    <Form.Item
                    label="Product Name"
                    >
                        
                        <Input onChange = {this.props.updateName} defaultValue = {this.props.data.name || ''} autoComplete = "off" style = {{width: "150px"}} name = "name" placeholder="Ex: Organic Apple" />
                    </Form.Item>
                    <Form.Item
                        label="Description">
                        <Input onChange = {this.props.updateDescription} defaultValue = {this.props.data.description  || ''} autoComplete = "off" style = {{width: "200px"}} name = "description" placeholder="Ex: Juicy organic apples!" />
                    </Form.Item>
                    <Form.Item label="Category">
                        <Select onChange = {this.props.updateCategory} defaultValue = {this.props.data.category || '' } style = {{width: "160px"}}  

                         name="category" placeholder="Please select one">
                            <Option value="Fruit">Fruit</Option>
                            <Option value="Vegetable">Vegetable</Option>
                            <Option value="Poultry & Livestock">Poultry &amp; Livestock</Option>
                            <Option value="Dairy & Grain">Dairy &amp; Grain</Option>
                            <Option value="Canned">Beverage &amp; Canned</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>

        );
    }
}


export default ProductEditForm;