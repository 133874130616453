import React from 'react'
import topLogo from '../images/bon-harvest-title-green.png'
import officeMap from '../images/office-map-png.png'
import veggies from '../images/vegetables-cutting-board.jpg'
import radish from '../images/raddish-greens.jpg'
import jars from '../images/jars.jpg'
import daniel from '../images/team/daniel_real.png';
import teymour from '../images/team/teymour.jpg';
class Team extends React.Component {

    render() {
        return (
            <body>
        <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" href=".">
                    <img src={topLogo}/>
                </a>
                <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div class="navbar-menu is-size-5 has-text-weight-medium">
                <div class="navbar-end">
                    <a href="/data-problem" class="navbar-item">
                        The Data Problem
                    </a>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a href="/about" class="navbar-link">
                            About
                        </a>
                        <div class="navbar-dropdown">
                            <a href="/about#story" class="navbar-item">
                                Story
                            </a>
                            <a href="/about#ethics" class="navbar-item">
                                Ethics
                            </a>
                            <a href="/about#history" class="navbar-item">
                                History
                            </a>
                            <hr class="navbar-divider"/>
                            <a  href="/team" class="navbar-item is-active">
                                Team
                            </a>
                        </div>
                    </div>
                    <a href="/rethink" class="navbar-item">
                            Rethink Food
                        </a>
                    <a href="https://blog.bonharvest.com" class="navbar-item">
                        Blog
                    </a>
                    <div class="navbar-item">
                        <div class="buttons">
                            <a href="/farmer_signup" class="button is-medium is-rounded is-danger has-text-weight-semibold">
                                Get early access
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <section class="hero is-large parallax" style={{"backgroundImage": `url(${jars}`}}>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-size-1 has-text-white has-text-weight-bold">
                        The Bon Harvest team
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
            <div class="columns is-centered">
                <div class="column is-two-thirds">
                    <div class="content is-medium">
                        <div class="columns">
                            <div class="column is-3">
                                <figure class="image is-marginless">
                                    <img src={teymour} class="is-rounded"/>
                                </figure>
                            </div>
                            <div class="column">
                                <h3 id="teymour">Teymour el Derini, CEO</h3>
                                <p>
                                    Teymour was born in Cairo, Egypt, a country whose rich agricultural history has earned it the moniker “Mother of the World.” His weekends were spent on the family farm where he developed a deep appreciation for the human connection to fresh food - not only in taste but in its smell and the feel of its weight in your hand. Teymour spent a lot of time in his grandmother's kitchen, a wonderful woman who insisted on cooking only with vegetables in season. This, he learned, was the secret to the unbeatable flavor.
                                </p>
                                <p>
                                    This lesson stayed with Teymour, and as an adult, he helped to launch a series of successful restaurants. His main profession, however, was in financial services. Following a decade-long career in finance, Teymour decided to pursue a masters at NYU. There he was shocked to learn that roughly forty percent of American produce goes uneaten each year - up to $200 billion in waste - the majority deemed unsellable due to cosmetic oddities or minor bumps and bruises. These statistics struck Teymour deeply; discarding edible produce was not only unthinkable on principle, but it also seemed unconscionable in a country where over 40 million Americans were food insecure or lacking nutritious options. And if these were the statistics in one of the world’s leading developed countries how much waste was hiding in the global agricultural system?
                                </p>
                                <p>
                                    Inspired to take action, Teymour launched Bon Harvest in 2016.  As a restaurant owner, Teymour had learned of the inefficiencies and lacking transparency in the food service industry, and he knew the struggle faced by food service providers faced with wildly fluctuating markets and archaic inventory systems. As a child, Teymour had seen firsthand the struggle of farmers, and he felt a strong desire to empower farmers and share their stories. He knew that if only people could learn their stories, stories of the food that nourishes our bodies and the people who grow it, real and positive change could take hold that would help the food growers, preparers, and eaters alike. 
                                </p>
                                <p>
                                    Favorite Vegetable: Mushrooms
                                </p>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-3">
                                <figure class="image is-marginless">
                                    <img src={daniel} class="is-rounded"/>
                                </figure>
                            </div>
                            <div class="column">
                                <h3 id="daniel">Daniel Zhu, CTO</h3>
                                <p>
                                    Daniel graduated from New York University in 2018 with a double major in Mathematics and Computer Science. He started programming in his freshman year of college and developed a passion for coding after realizing how easy it was, or so it seemed at that time.
                                </p>
                                <p>
                                    Originally planning to pursue a career in actuarial science or academia, he realized before graduating that he found more fulfillment in developing innovative new projects and decided to drop everything to work in the volatile world that is the startup industry.
                                </p> 
                                <p>
                                    He first worked as a data scientist at Psonya and later joined the Bon Harvest team in December 2018.
                                </p>
                                <p>
                                    Daniel is also an avid chess player, ranked both nationally and internationally, and can sometimes be spotted playing in Washington Square Park or at the famous Marshall Chess Club.
                                </p>
                                <p>
                                    His favorite color is without a doubt blue, and his favorite fruit is, naturally, blueberries.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <footer class="footer">
            <div class="container">
                <div class="columns">
                    <div class="column is-3">
                        <div class="content">
                            <a href="#"><img src={topLogo}/></a>
                            <h4><strong>Produce</strong>: Helping farmers sell it, helping chefs buy it.</h4>
                            <p>
                                <button onClick = {()=>{this.props.history.push("/farmer_signup")}}class="button is-rounded is-warning"> 
                                    <span class="icon">
                                        <i class="fas fa-tractor"></i>
                                    </span>
                                    <span><strong>Get early access</strong></span>
                                </button>
                            </p>
                            <h4><a href="/about">About</a></h4>
                            <h4><a href="/team">Team</a></h4>
                            <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                            <h4><a href="/data-problem">The Data Problem</a></h4>
                        </div>
                    </div>
                    <div class="column">
                        <div class="content">
                            <div class="box">
                                <article class="media">
                                    <div class="columns is-variable is-0">
                                        <div class="column">
                                            <div class="media-left">
                                                <a href="#"><img src={officeMap} alt="Bon Harvest office map"/></a>
                                            </div>
                                        </div>
                                        <div class="column is-one-third">
                                            <div class="media-content">
                                                <div class="content has-text-white">
                                                    <p class="has-text-weight-bold">New Lab</p>
                                                    <p>
                                                        Building 128<br></br>
                                                        19 Morris Ave<br></br>
                                                        Brooklyn, NY 11205<br></br>
                                                        USA
                                                    </p>
                                                    <p>
                                                        <span class="icon has-text-primary">
                                                            <i class="fas fa-phone"></i>
                                                        </span>
                                                        917-724-4474
                                                        <br></br>
                                                        <span class="icon has-text-primary">
                                                            <i class="fas fa-envelope"></i>
                                                        </span>
                                                        hello@bonharvest.com
                                                    </p>
                                                </div>
                                                <nav class="level">
                                                    <div class="level-left">
                                                        <a class="level-item">
                                                            <span class="icon is-medium has-text-warning">
                                                                <i class="fab fa-2x fa-facebook-f"></i>
                                                            </span>
                                                        </a>
                                                        <a class="level-item">
                                                            <span class="icon is-medium has-text-warning">
                                                                <i class="fab fa-2x fa-twitter"></i>
                                                            </span>
                                                        </a>
                                                        <a href="https://www.instagram.com/bonharvest/" class="level-item">
                                                            <span class="icon is-medium has-text-warning">
                                                                <i class="fab fa-2x fa-instagram"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </body>
        )
    }
}
export default Team;