import React from 'react'
import {Input} from 'antd';
import Client from '../components/Client'
import axios from 'axios';
import { Empty } from 'antd';

const { Search } = Input;
class ClientSearch extends React.Component{

    state = {
        all_data : [],
        show_data: []
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    updateSearch = (input) => {
        const text = input.target.value
        const to_replace = []
        for(let i = 0; i<this.state.all_data.length; i++){
            let user = this.state.all_data[i]
            if(user.username.toLowerCase().includes(text.toLowerCase())){
                to_replace.push(user)
            }
        }
        this.setState({
            show_data : to_replace
        })
    }
    componentDidMount(){
        const url = "https://api.farmersofamerica.net/api/allclients/"
        axios.get(url,this.config)
            .then(res =>{
                this.setState({
                    all_data: res.data,
                    show_data: res.data
                })
        })

    }
    render() {
        const token = localStorage.getItem('token');
        if(token==null){
            this.props.history.push("/")
        }
        const isFarmer = localStorage.getItem('is_farmer')
       return (
           <div>
                {isFarmer === "true" ?
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i class="fas fa-users" aria-hidden="true"></i>
                                    </span>
                                    <span>Clients</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    :
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i class="fas fa-tractor" aria-hidden="true"></i>
                                    </span>
                                    <span>Farms</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
                <div class="tabs">
                    <ul>
                        <li>
                            {isFarmer === "true" ?
                            <a href="/clients">My Clients</a>:
                            <a href="/clients">My Farms</a>
                            }
                            </li>
                        <li className="is-active"><a href="/clientsearch">Search</a></li>
                        <li><a href="/requests">Requests</a></li>
                    </ul>
                    </div>
            <br/>
               <Search
      placeholder="Search for clients"
      onChange = {this.updateSearch}
      style={{ width: "20vw", marginBottom: "4vh" }}
    />
 
           <Client data = {this.state.show_data} search = {true}/>
           </div>
       )
    }
}
export default ClientSearch;