import React, { Component } from 'react';
import 'antd/dist/antd.css';
import BaseRouter from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/auth'
import NewLayout from './containers/NewLayout';
class App extends Component {

  componentDidMount() {

    this.props.onTryAutoSignup();

  }
  async handleLogout(){

  }
  render() {
    return (

      <div>
        <Router>{
          this.props.isAuthenticated ? 
          <NewLayout {...this.props}>
            <BaseRouter/>
          </NewLayout> :
          
          <BaseRouter/>
  }
        </Router>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    isAuthenticated: state.token !== null,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStatetoProps, mapDispatchToProps)(App);
