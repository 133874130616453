import React from 'react'
import Client from '../components/Client'
import axios from 'axios';
class ClientList extends React.Component {
    state = {
        clients: [],
        inven_names:[],
        loaded: false
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount() {
        const url = "https://api.farmersofamerica.net/api/myclients/"
        axios.get(url, this.config)
            .then(res => {
                this.setState({
                    clients: res.data,
                    loaded: true
                })
            })
        const isFarmer = localStorage.getItem('is_farmer')
        if(isFarmer === "true"){
            const inven_url = 'https://api.farmersofamerica.net/api/inventorynames'
            axios.get(inven_url, this.config).then(
                res=>{
                    const names = res.data
                    this.setState({inven_names: names})
                }
            )
        }

    }
    render() {
        const token = localStorage.getItem('token');
        const isFarmer = localStorage.getItem('is_farmer')
        if (token == null) {
            this.props.history.push("/")
        }
        return (
            <div>
                {isFarmer === "true" ?
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i style = {{fontSize: 25}} class="fas fa-users" aria-hidden="true"></i>
                                    </span>
                                    <span style = {{fontSize: 25}}>Clients</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    :
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i  style = {{fontSize: 25}}class="fas fa-tractor" aria-hidden="true"></i>
                                    </span>
                                    <span style = {{fontSize: 25}}>Farms</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
                <div class="tabs">
                    <ul>
                        <li class="is-active">
                            <a href="/clients">{isFarmer === "true" ? "My Clients" : "My Farms"}</a></li>
                        <li><a href="/clientsearch">Search</a></li>
                        <li><a href="/requests">Requests</a></li>
                    </ul>
                </div>
                {!this.state.loaded ?
                <div style = {{marginLeft: "20vw", marginTop: "10vh"}}class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                
                </div> 
                :
                <Client inven_names={this.state.inven_names} data={this.state.clients} search={false} />
                }

            </div>
        );
    }
}
export default ClientList;