import React from 'react'
import axios from 'axios';
import { Avatar, Skeleton, Button } from 'antd';
import icon from '../images/correctgreen.png'
import { NavLink } from 'react-router-dom';
import { Divider } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import '../index.css';
class ProfileView extends React.Component {
    state = {
        loaded: false,
        businessName: "",
        email: "",
        farm_type: "",
        address1: "",
        address2: "",
        is_farmer: false,
        profileUrl: "",
        tempProfileUrl: "",
        file: null,
        isOwner: false,
        editModal: "modal",
        about: "",
        tempEmail: "",
        tempAddress1: "",
        tempAddress2: "",
        tempPhone: "",
        tempAbout: "",
        fileRef: React.createRef(),
        fileName: "No file uploaded"
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    userId = this.props.match.params.userID;
    url;
    componentDidMount() {

        if (this.userId == null) {
            this.url = 'https://api.farmersofamerica.net/api/profile/'
            this.setState({ is_owner: true })
        }
        else {
            this.url = 'https://api.farmersofamerica.net/api/profile/' + this.userId

        }
        axios.get(this.url, this.config)
            .then(res => {
                const produce_type = res.data.produce_type.join(", ")
                var fileName = "No file uploaded"
                if(res.data.profile_url != null && res.data.profile_url.length>0){
                    fileName = res.data.profile_url
                    fileName = fileName.split("/")
                    fileName = fileName[fileName.length - 1]
                }
                this.setState({
                    loaded: true,
                    businessName: res.data.username,
                    email: res.data.email,
                    farm_type: res.data.farm_type,
                    farm_size: res.data.farm_size,
                    produce_type: produce_type,
                    about: res.data.about,
                    address1: res.data.address_line_1,
                    tempAddress1: res.data.address_line_1,
                    tempAddress2: res.data.address_line_2,
                    tempAbout: res.data.about,
                    tempEmail: res.data.email,
                    tempPhone: res.data.phone_number,
                    address2: res.data.address_line_2,
                    profileUrl: res.data.profile_url,
                    tempProfileUrl: res.data.profile_url,
                    about: res.data.about,
                    city: res.data.city,
                    state: res.data.state,
                    zipcode: res.data.zipcode,
                    tempZip: res.data.zipcode, 
                    tempCity: res.data.city,
                    tempState: res.data.state,
                    phone: res.data.phone_number,
                    is_farmer: res.data.is_farmer,
                    isOwner: res.data.is_owner,
                    fileName: fileName
                })
            }
            ).catch(res => {
                const status = res.response.status
                switch (status) {
                    case 404:
                        this.props.history.push("/")
                        break;
                    case 401:
                        this.props.history.push("/")
                        break;
                    case 403:
                        console.log("Is this even possible")
                        this.props.history.push("/")
                        break;
                    case 500:
                        console.log("this especially should not happen")
                        console.log(res.response)
                        this.props.history.push("/")
                }
            })

    }
    getProfilePicture() {
        if (!this.state.loaded) {
            return null
        }
        if (this.state.profileUrl == null || this.state.profileUrl.length == 0) {
            return icon
        }
        return this.state.profileUrl
    }
    getCharCount() {
        if (this.state.about == null) {
            return "0"
        }
        return this.state.tempAbout.length
    }
    formatPhone() {
        if (this.state.phone == null) {
            return ""
        }
        return "(" + this.state.phone.substring(0, 3) + ")-" + this.state.phone.substring(3, 6) + "-" + this.state.phone.substring(6, 10)
    }
    getTelephone() {
        var value = this.state.tempPhone
        if (value == null || value.length === 0) {
            return ""
        }
        var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        value = !x[2] ? x[1] : '(' + x[1] + ')-' + x[2] + (x[3] ? '-' + x[3] : '');
        return value
    }
    handleEdit(value, field) {
        this.setState({ [field]: value })

    }
    cancel() {
        this.setState({
            editModal: "modal",
            tempAbout: this.state.about,
            tempAddress1: this.state.address1,
            tempAddress2: this.state.address2,
            tempPhone: this.state.phone,
            tempEmail: this.state.email,
            tempCity: this.state.city,
            tempZip: this.state.zipcode,
            tempState: this.state.state,
            file: null,
            tempProfileUrl: ""
        })
    }
    handleUpload() {
        const files = this.state.fileRef.current.files
        if (files.length > 0) {
            const fileName = files[0].name
            this.setState({ tempProfileUrl: fileName, file: files[0], fileName: fileName})
        }
    }
    async handleSubmit() {
        const address1 = this.state.tempAddress1
        const address2 = this.state.tempAddress2
        const phone = this.state.tempPhone.replace(/-/g,"").replace(/["'\(\)]/g,"")
        const about = this.state.tempAbout
        const file = this.state.file
        const state = this.state.tempState
        const zipcode = this.state.tempZip
        const city = this.state.tempCity

        if (file !== null) {
            const fileName = this.state.tempProfileUrl
            var path = "https://bonharvest-product-photos.s3.amazonaws.com/product-photos/" + uuidv4() + "/" + fileName
        } else {
            var path = ""
        }
        var formData = new FormData()
        formData.append("address1", address1)
        formData.append("address2", address2)
        formData.append("phone", phone)
        formData.append("about", about)
        formData.append("path", path)
        formData.append("file", file)
        formData.append("state", state)
        formData.append("city", city)
        formData.append("zipcode", zipcode)
        let res = await axios.put('https://api.farmersofamerica.net/api/editprofile/', formData, this.config)
        this.state.profileUrl = path
        this.state.address1 = address1
        this.state.address2 = address2
        this.state.city = city
        this.state.state = state
        this.state.zipcode = zipcode
        this.state.about = about
        this.state.phone = phone
        
        this.cancel()
        window.location.reload(false);
    }
    removePhoto() {
        this.setState({ file: null, tempProfileUrl: "", fileName: "No file uploaded" })
    }
    editForm() {
        var fileName = this.state.fileName
        if (fileName.length > 20) {
            fileName = fileName.substring(0, 20) + "..."
        }
        return (
            <div class={this.state.editModal}>
                <form>
                    <div class="modal-background"></div>
                    <div class="modal-card">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Edit Profile</p>
                            <button type="button" onClick={() => this.cancel()} class="delete" aria-label="close"></button>
                        </header>
                        <section class="modal-card-body">
                            <label class="label is-large">Address details</label>
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control is-expanded">
                                            <input style = {{"width":"20vw"}} value={this.state.tempAddress1} onChange={(e) => this.handleEdit(e.target.value, "tempAddress1")} class="input is-medium" type="text" placeholder="Address Line 1" />
                                        </div>
                                    </div>
                                    <div class="field">
                                        <p class="control is-expanded">
                                            <input value={this.state.tempAddress2} onChange={(e) => { this.handleEdit(e.target.value, "tempAddress2") }} class="input is-medium" type="text" placeholder="Address Line 2" />
                                        </p>
                                    </div>
                                </div>
                                <br></br>
                            <div class="field-body">
                                <div class="field">
                                    <p class="control is-expanded">
                                        <input value={this.state.tempCity} id={this.state.id} onChange={(e) => { this.handleEdit(e.target.value, "tempCity") }} class="input is-medium" type="text" placeholder="City" />
                                    </p>
                                </div>
                                <div class="field">
                                    <p class="control is-expanded">
                                        <input value={this.state.tempState} id={this.state.id} onChange={(e) => { this.handleEdit(e.target.value, "tempState") }} class="input is-medium" type="text" placeholder="State" />
                                    </p>
                                </div>
                                <div class="field">
                                    <p class="control is-expanded">
                                        <input value={this.state.tempZip} id={this.state.id} onChange={(e) => { this.handleEdit(e.target.value, "tempZip") }} maxLength = {5} class="input is-medium" type="text" placeholder="Zipcode" />
                                    </p>
                                </div>
                            </div>
                            <label class="label is-large">Contact information</label>

                            <div class="field is-horizontal">
                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input value={this.getTelephone()} onChange={(e) => { this.handleEdit(e.target.value, "tempPhone") }} class="input is-medium" type="text" placeholder="Phone number" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <label class="label is-large">About</label>
                            <div class="field is-horizontal">
                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <textarea maxLength={1000} value={this.state.tempAbout} onChange={(e) => { this.handleEdit(e.target.value, "tempAbout") }} class="textarea" placeholder="Your story"></textarea>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <h1 style={{ "float": "right" }}>{this.getCharCount()}/1000</h1>
                            <div class="field">
                                <div class="file is-warning has-name">
                                    <label class="file-label">
                                        <input onChange={(e) => { this.handleUpload() }} class="file-input" type="file" name="resume" ref={this.state.fileRef} />
                                        <span class="file-cta">
                                            <span class="file-icon">
                                                <i class="fas fa-upload"></i>
                                            </span>
                                            <span class="file-label">
                                                Upload a photo...
                                                        </span>
                                        </span>
                                        <span class="file-name">
                                            {fileName}
                                        </span>
                                    </label>
                                    {this.state.fileName !=="No file uploaded" ? <button style={{ marginTop: "1vh", "marginLeft": "1vw" }} type="button" onClick={() => this.removePhoto()} class="delete" aria-label="close"></button>
                                            :
                                            null}
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button type="button" onClick={() => this.handleSubmit()} className="button is-success">Save changes</button>
                            <button type="button" onClick={() => this.cancel()} className="button">Cancel</button>

                        </footer>
                    </div>
                </form>
            </div>
        )
    }
    profile() {
        return (
            <div class="column">
                <div class="content">
                    <div class="tile is-ancestor">
                        <div class="tile is-vertical is-5">
                            <div class="tile">
                                <div class="tile is-parent is-vertical">
                                    <article class="tile is-child notification is-grey">
                                        <h3 class="is-size-3">Address</h3>
                                        <div class="is-divider" data-content="OR"></div>
                                        <Divider />
                                        <p>
                                            {!this.state.address1 ? <Skeleton active /> :
                                                this.state.address1 + " " + this.state.address2}
                                        </p>
                                        <p>{this.state.city + ", " + this.state.state + " " + this.state.zipcode}.</p>
                                    </article>
                                    <article class="tile is-child notification">
                                        <h3 class="is-size-3">Contact</h3>
                                        <Divider />
                                        <p><i style={{"marginRight":"1vw"}} class="fa fa-envelope" aria-hidden="true"></i>{this.state.email}</p>
                                        <p><i style={{"marginRight":"1vw"}} class="fa fa-phone" aria-hidden="true"></i>{this.formatPhone()}</p>
                                    </article>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification">
                                    <h3 class="is-size-3">About</h3>
                                    <Divider></Divider>
                                    <p></p>
                                    <div class="content">
                                        {this.state.about}
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child notification">
                                <div class="content">
                                    <h2 style={{ "textAlign": "center" }}>
                                        {this.state.businessName}
                                    </h2>
                                    <Divider />
                                    <figure class="image is-256x256">
                                        <img src={this.getProfilePicture()} />
                                    </figure>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                {this.editForm()}
                {this.state.isOwner ?
                    <a onClick={() => this.setState({ "editModal": "modal is-active" })} style={{ "float": "right" }} class="button is-large is-rounded is-warning">
                        <span class="icon">
                            <i class="fas fa-edit"></i>
                        </span>
                        <span><strong>Edit profile</strong></span>
                    </a>
                    :
                    null
                }
            </div>
        )
    }
    render() {
        return (
            <div class="column">
                <div class="content">
                    <div class="tile is-ancestor">
                        <div class="tile is-vertical is-5">
                            <div class="tile">
                                <div class="tile is-parent is-vertical">
                                    <article class="tile is-child notification is-grey">
                                        <h3 class="is-size-3">Address</h3>
                                        <div class="is-divider" data-content="OR"></div>
                                        <Divider />
                                        <p>
                                            {!this.state.address1 ? <Skeleton active /> :
                                                this.state.address1 + " " + this.state.address2}
                                        </p>
                                        <p>{this.state.city + ", " + this.state.state + " " + this.state.zipcode}.</p>
                                    </article>
                                    <article class="tile is-child notification">
                                        <h3 class="is-size-3">Contact</h3>
                                        <Divider />
                                        <p><i style={{"marginRight":"1vw"}} class="fa fa-envelope" aria-hidden="true"></i>{this.state.email}</p>
                                        <p><i style={{"marginRight":"1vw"}} class="fa fa-phone" aria-hidden="true"></i>{this.formatPhone()}</p>
                                    </article>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification">
                                    <h3 class="is-size-3">About</h3>
                                    <Divider></Divider>
                                    <p></p>
                                    <div class="content">
                                        {this.state.about}
                                    </div>
                                </article>
                            </div>
                        </div>
                        <div class="tile is-parent">
                            <article class="tile is-child notification">
                                <div class="content">
                                    <h2 style={{ "textAlign": "center" }}>
                                        {this.state.businessName}
                                    </h2>
                                    <Divider />
                                    <figure class="image is-256x256">
                                        <img src={this.getProfilePicture()} />
                                    </figure>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                {this.editForm()}
                {this.state.isOwner ?
                    [<a onClick={() => this.setState({ "editModal": "modal is-active" })} style={{ "float": "right" }} class="button is-large is-rounded is-warning">
                        <span class="icon">
                            <i class="fas fa-edit"></i>
                        </span>
                        <span><strong>Edit profile</strong></span>
                    </a>
                    ,
                    <a href = "/change_password" style={{ "float": "right", marginRight: 10 }} class="button is-large is-rounded ">
                    <span class="icon">
                        <i class="fas fa-edit"></i>
                    </span>
                    <span><strong>Change password</strong></span>
                </a>
                ]
                    :
                    null
                }
            </div>
        )
    }
}
export default ProfileView;