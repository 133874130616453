import React from 'react'
import FriendRequest from '../components/FriendRequest'
import axios from 'axios';
class FriendRequestView extends React.Component {
    state = {
        requests: [],
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount(){
        const url = "https://api.farmersofamerica.net/api/requests/"
        axios.get(url,this.config)
            .then(res =>{
                this.setState({
                    requests: res.data
                })
                console.log(this.state)
        })

    }
    updateRequests = (id) => {
        console.log("updating state?")
        var copy = [...this.state.requests]
        for(let i = 0; i<copy.length; i++){
            if(copy[i].id === id){
                var index = i
                break;
            }
        }
        if(index!=null){
            copy.splice(index,1)
            this.setState({
                requests: copy
            })
        }
    }
    render(){
        const token = localStorage.getItem('token');
        if(token==null){
            this.props.history.push("/")
        }
        const isFarmer = localStorage.getItem('is_farmer')
        return (
            <div>
                {isFarmer === "true" ?
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i class="fas fa-users" aria-hidden="true"></i>
                                    </span>
                                    <span>Clients</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    :
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i class="fas fa-tractor" aria-hidden="true"></i>
                                    </span>
                                    <span>Farms</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                }
                <div class="tabs">
                    <ul>
                        <li>
                        {isFarmer === "true" ?
                            <a href="/clients">My Clients</a>:
                            <a href="/clients">My Farms</a>
                            }</li>
                        <li><a href="/clientsearch">Search</a></li>
                        <li class="is-active"><a href="/requests">Requests</a></li>
                    </ul>
                    </div>
                <FriendRequest data = {this.state.requests} update_requests = {this.updateRequests} />
            </div>
        );
    }
}
export default FriendRequestView;