import React from 'react';
import ProductForm from '../components/ProductForm'
import ItemForm from '../components/ItemForm'
class FormContainer extends React.Component {
    state = {
        productId: "",
        toUpload: [],
    }
    getProductId = (id) => {
        this.setState({productId: id})
    }
    resetProductId = () => {
        this.setState({productId: ""})
    }
    render() {
        return (
            <div>
                {
                    this.state.productId.length > 0 ?
                    <ItemForm requestType="post" itemID = {null} product = {this.state.productId} resetId = {this.resetProductId} update = {this.props.update}/>
                    :
                    <ProductForm requestType="post" itemID = {null} getId = {this.getProductId} update = {this.props.update}/>
                }
            </div>
        );
    }
}
export default FormContainer;