import React from 'react';
import { Form } from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import Confirmation from '../components/ConfirmationSignUp'
import FarmDetails from '../components/FarmDetailsSignUp'
import UserDetails from '../components/UserDetailsSignUp'
import UserType from '../components/UserTypeSignUp'
import AddressDetails from '../components/AddressDetailsSignUp'

//const FormItem = Form.Item;

class RegistrationForm extends React.Component {
  state = {
    step: 0,
    confirmDirty: false,
    username: "",
    email: "",
    password: "",
    password2: "",
    is_farmer: false,
    phone_number: "",
    first_name: "",
    last_name: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    zipcode: "",
    farm_size: "",
    farm_type: "",
    produce_type: []

  };



  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords must match!');
    } else {
      callback();
    }
  }

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }
  makeBuyer = () => {
        this.setState({
            is_farmer: false,
            farm_size: "N/A",
            farm_type: "N/A"
        })
        this.nextStep()
  }
  makeFarmer = () => {
        this.setState({
            is_farmer: true
        })
        this.nextStep()
  }
    nextStep = () => {
      console.log("yes")
        const { step } = this.state
        this.setState({
            step : step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step : step - 1
        })
    }

    handleChange = input => event => {
        this.setState({ [input] : event.target.value })
    }
    updateFarmSize = (value) => {
      this.setState({
        farm_size: value
      })
    }
    updateFarmType = (value) => {
      this.setState({
        farm_type: value
      })
    }
    updateProduceType = (value) => {
      this.setState({
        produce_type: value
      })
    }

  render() {
      const {username, email,password,password2, is_farmer, first_name,
            last_name, address_line_1, address_line_2, city,state,
            zipcode,farm_size,farm_type, produce_type, phone_number
            } = this.state
      const values = {username, email,password,password2, is_farmer, first_name,
            last_name, address_line_1, address_line_2, city,state,
            zipcode,farm_size,farm_type, produce_type, phone_number
            }
      const {step} = this.state
        switch(step){
        case 0:
            return (
                <UserType
                    makeFarmer = {this.makeFarmer}
                    makeBuyer = {this.makeBuyer}
                    values = {values}
                />

            )
        case 1:
            return (
                <UserDetails
                    next = {this.nextStep}
                    prev = {this.prevStep}
                    values = {values}
                    handleChange = {this.handleChange}
                   />
                )

        case 2:
            return (
                <AddressDetails
                    next = {this.nextStep}
                    prev = {this.prevStep}
                    values = {values}
                    handleChange = {this.handleChange}
                />
            )
        case 3:
              if(this.state.is_farmer){
                return (
                  <FarmDetails
                  next = {this.nextStep}
                  prev = {this.prevStep}
                  values = {values}
                  handleChange = {this.handleChange}
                  updateSize = {this.updateFarmSize}
                  updateType = {this.updateFarmType}
                  updateProduceType = {this.updateProduceType}
                  />
                )
              }else{
                return (
                    <Confirmation
                    next = {this.nextStep}
                    prev = {this.prevStep}
                    values = {values}
                    handleChange = {this.handleChange}
                    onAuth = {this.props.onAuth}
                    history = {this.props.history}
                    />
                )
              }
         
        case 4:
              return (
                  <Confirmation
                  next = {this.nextStep}
                  prev = {this.prevStep}
                  values = {values}
                  handleChange = {this.handleChange}
                  onAuth = {this.props.onAuth}
                  history = {this.props.history}
                  />
              )
        default:
            console.log("This shouldn't happen no case present in sign up.")
        }
    }
}
const WrappedRegistrationForm = Form.create()(RegistrationForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, email, password1, password2, is_farmer,
           first_name, last_name, phone_number, address_line_1, address_line_2, city, state, zipcode, farm_type, farm_size, produce_type) => 
           dispatch(actions.authSignup(username, email, password1, password2, is_farmer, first_name, last_name, phone_number, address_line_1, address_line_2, city, state, zipcode, farm_type, farm_size, produce_type)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);