import React from 'react';
import {
    Select, InputNumber, Button, message
} from 'antd';
import axios from 'axios';

class NewItem extends React.Component {
    state = {
        items: [],
        amount: 0,
        id: "",
        price: 0,
        oldPrice: 0,
        inStock:0,
        unit:"",
        quantity:0,
        index: 0,
        toAddToCart: 0
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.data.length !== 0){
        this.setState(
            {
                items: nextProps.data,
            }, () =>{
                if(nextProps.data.length==0){
                    this.changePrice(-1)
                } else{
                this.changePrice(this.state.index)
                }
            }
            )
        }
        else{
            const product = nextProps.product
            this.setState({
                id: product.id,
                price: 0, 
                oldPrice: 0, 
                quantity: 0,
                inStock: product.total_quantity,
                unit: product.total_unit,
                items: product.items
            })
        }
      }
    componentDidMount(){
        const item = this.props.data
        this.setState(
            {
                items:this.props.data,
            }
        )
        if(this.props.data.length!==0){
            this.setState({
                id: this.props.data[0].id,
                price:this.props.data[0].price,
                oldPrice: this.props.data[0].old_price,
                quantity: this.props.data[0].unit_quantity, 
                unit: this.props.data[0].unit_of_measurement,
                inStock: this.props.data[0].amount_in_stock
    
            })
        }
        else{
            const product = this.props.product
            this.setState({
                id: product.id,
                price: 0, 
                oldPrice: 0, 
                quantity: 0,
                inStock: product.total_quantity,
                unit: product.total_unit
            })
        }
    }
    changePrice(index){
        if(index==-1){
            this.setState({
                id: "",
                index: 0,
                price:0,
                oldPrice: 0,
                quantity: 0, 
                unit: 0,
                inStock: "N/A"
            })
        }else{
        const i = index
        const item = this.state.items[i]
        this.setState({
            id: item.id,
            index: i,
            price:item.price,
            oldPrice: item.old_price,
            quantity: item.unit_quantity, 
            unit: item.unit_of_measurement,
            inStock: item.amount_in_stock,
            maxAllowedOrder: item.max_allowed_order
        })
    }
    }
    updateValue = (value) =>{
        this.setState({
            amount: value
        })
    }
    placeOrder = () => {
        axios.post('https://api.farmersofamerica.net/api/itemorder/',{
            quantity: this.state.amount,
            price: this.state.price,
            item_id: this.state.id,
            type: "append"

        },this.config)
        message.success(`Added ${this.state.amount} to cart!`,0.5)
        this.props.updateCart(parseInt(this.state.amount))
    }
    getPrice =(old_price, price) => {
        old_price = parseFloat(old_price)
        price = parseFloat(price)
        if(old_price === price){
            return <font color="black">
                {"$"+(price).toFixed(2)+" = "}
            </font>
        }
        else if(old_price < price){
            return (
            <font color="green">
            {"$"+(price).toFixed(2)+" ↑ "}
            </font>
            )
        }
        else{
            return(
                <font color="crimson">
                {"$"+(price).toFixed(2)+" ↓ "}
                </font> 
            )
        }
    }
    addAmountDropdown(){
        const maxLength = this.state.maxAllowedOrder
        var toReturn = []
        for(var i = 0; i<=maxLength; i++){
            toReturn.push(<option value={i}>
                {i}
            </option>)
        }
        return toReturn
    }
    getOptions = (item) => {
        var toReturn = []
        for(var i = 0; i<item.length; i++){
            toReturn.push(<option value={i}>
                {item[i].unit_quantity+" "+item[i].unit_of_measurement}
            </option>)
        }
        return toReturn

        
    }

    dropDownChange(e){
        this.setState({amount: e.target.value})
        this.props.passProps(this.state.id, e.target.value, this.state.price)
    }
    render() {
        return (
            <>
            <div class="column is-1">
                <p style = {{fontSize: "1em"}}>
                    <strong>${Number(this.state.price).toFixed(2)}</strong>
                </p>
            </div>
            {this.state.items.length === 0? 
            [
            <div class="column is-4">
            <p style = {{fontSize: "1em", "marginLeft":"1vw"}}>
                Amount in stock: {this.state.inStock} {this.state.unit}
            </p>
            </div>
            ,
            <div class = "column is-narrow">
            <div style={{"marginLeft": this.props.isOwner? "0vw":"0vw"}} class = "select is-primary">
                        <select style={{fontSize: "0.8em", "width":150}}onChange = {(e)=>{this.changePrice(e.target.value)}} >
                        <option value={"N/A"}>
                N/A
            </option>
                        </select>
            </div>
                
            </div>
            ]

            :
            [
            <div class="column is-3">
            <p style = {{fontSize: "1em", "marginLeft":"1vw"}}>
                In stock: {this.state.inStock}
            </p>
            </div>,
            <div class = "column is-narrow">
            <div style={{"marginLeft": this.props.isOwner? "0vw":"0vw"}} class = "select is-primary">
                        <select style={{fontSize: "0.8em", "width":100}}onChange = {(e)=>{this.changePrice(e.target.value)}} >
                            {this.getOptions(this.state.items)}
                        </select>
            </div>
            
            </div>
            ]
        }
            {this.props.isOwner? 
            null
                :
                <>
                <div class="select is-primary">
                <select defaultValue = {1} style={{fontSize: "0.8em", marginLeft:"1vw", width: 60}} onChange = {(e)=>{this.dropDownChange(e)}}>
                    {this.addAmountDropdown()}
                </select>
                </div>
                <div class="column is-1">
                <div class="buttons">
                <button style = {{ width: 100,  fontSize: "0.8em", marginBottom: 15}} onClick = {()=>this.placeOrder()}class="button is-primary">Add to cart</button>
                </div>
                </div>
                </>
            }
            </>
        );
    }
}

export default NewItem;