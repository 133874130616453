import React from 'react';
import {
    Form, Input, Button, Select, AutoComplete
} from 'antd';
import axios from 'axios';

const { Option } = Select;
class ProductForm extends React.Component {
    state ={
      veggie: null,
      dataSource: [
        "Artichoke",
        "Arugula",
        "Asparagus",
        "Avocado",
        "Bamboo Shoots",
        "Bean Sprouts",
        "Beans",
        "Beet",
        "Belgian Endive",
        "Bell Pepper",
        "Bitter Melon",
        "Bok Choy",
        "Broccoli",
        "Brussels Sprouts",
        "Burdock Root",
        "Cabbage",
        "Calabash",
        "Capers",
        "Carrot",
        "Cassava/Yuca",
        "Cauliflower",
        "Celery",
        "Celery Root",
        "Celtuce",
        "Chayote",
        "Chinese Broccoli",
        "Corn",
        "Baby Corn",
        "Cucumber",
        "Daikon Radish",
        "Edamame",
        "Eggplant",
        "Elephant Garlic",
        "Endive",
        "Fennel",
        "Fiddlehead",
        "Galangal",
        "Garlic",
        "Ginger",
        "Grape Leaves",
        "Green Beans",
        "Wax Beans",
        "Greens",
        "Amaranth Leaves",
        "Beet Greens",
        "Collard Greens",
        "Dandelion Greens",
        "Kale",
        "Kohlrabi Greens",
        "Mustard Greens",
        "Rapini",
        "Spinach",
        "Swiss Chard",
        "Turnip Greens",
        "Hearts of Palm",
        "Horseradish",
        "Jerusalem Artichoke",
        "Jícama",
        "Curly",
        "Lacinato",
        "Ornamental",
        "Kohlrabi",
        "Leeks",
        "Lemongrass",
        "Lettuce",
        "Lotus Root",
        "Lotus Seed",
        "Mushrooms",
        "Napa Cabbage",
        "Nopales",
        "Okra",
        "Olive",
        "Onion",
        "Green Onions",
        "Parsley",
        "Parsley Root",
        "Parsnip",
        "Peas",
        "Peppers",
        "Plantain",
        "Potato",
        "Pumpkin",
        "Purslane",
        "Radicchio",
        "Radish",
        "Rutabaga",
        "Sea Vegetables",
        "Shallots",
        "Squash",
        "Sweet Potato",
        "Taro",
        "Tomatillo",
        "Tomato",
        "Turnip",
        "Water Chestnut",
        "Water Spinach",
        "Watercress",
        "Winter Melon",
        "Yams",
        "Zucchini",
      ]
    }
    didSubmit = false
    category = "Other"
    itemData = []
    config = {
      headers: {
          'Authorization': 'Token ' + localStorage.getItem('token')
      }
  }
  onSelect = (value) => {
    this.setState({
      veggie: value
    })
  }
  onSearch = (value) => {
    this.setState({
      veggie: value
    })
  }
    handleFormSubmit = (event, requestType, itemID) =>{
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
              
            const name = this.state.veggie
            const description = event.target.elements.description.value
            this.props.form.resetFields()
            switch (requestType) {
                case 'post':
                    return axios.post('https://api.farmersofamerica.net/api/products/',{
                        name: name,
                        description: description,
                        category: this.category
                    
                    },
                    this.config
                    )
                    .then(res=>{
                      res.data.is_owner = true
                      this.props.update(res.data)
                      this.props.getId(res.data.id)
                        })
                    .catch(err=>console.error(err));
                case 'put': 
                    return axios.put(`https://api.farmersofamerica.net/api/products/${itemID}/`,{
                        name: name,
                        descriptions: description,
                        category: this.category
                    })
                    .then(res=>console.log(res))
                    .catch(err=>console.err(err));
                default:
                    console.log("this shouldn't happen not put or post in ProductForm.js")
            }
            }
        });

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const dataSource = this.state.dataSource
        return (
            <div>
              <h2>1. Press Add Item to add an Item. Amount and pricing will be done in the next step.  </h2>
                <Form layout = 'inline' onSubmit={(event) => this.handleFormSubmit(event, this.props.requestType, this.props.itemID)}>
                    <Form.Item
                        label="Product Name"
                    >
                      {
                        getFieldDecorator('note1', {
                        rules: [{ required: true, message: 'Please input a name!' }],
                      })(
                        /*<Input autoComplete = "off" style = {{width: "150px"}} name = "name" placeholder="Ex: Organic Apple" />*/
                        <AutoComplete
                        name="name"
                        dataSource={dataSource}
                        style={{ width: 150 }}
                        placeholder = "Ex: Artichoke"
                        filterOption={(inputValue, option) =>
                          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                        onSelect = {this.onSelect}
                        onSearch = {this.onSearch}

                      />
                      )}
                    </Form.Item>
                    <Form.Item
                        label="Description"
                    >   {
                        getFieldDecorator('note2', {
                        rules: [{ required: true, message: 'Please input a description!' }],
                      })(
                        <Input autoComplete = "off" style = {{width: "200px"}} name = "description" placeholder="Ex: Juicy organic apples!" />
                      )}
                    </Form.Item>
                    <Form.Item label="Category">
                   { getFieldDecorator('note3', {
                        rules: [{ required: true, message: 'Please select a category!' }],
                      })(
                        <Select style = {{width: "160px"}} onChange={(value) => {
                          this.category = value;
                        }} name="category" placeholder="Please select one">
                            <Option value="Fruit">Fruit</Option>
                            <Option value="Vegetable">Vegetable</Option>
                            <Option value="Livestock">Livestock</Option>
                            <Option value="Dairy">Dairy</Option>
                            <Option value="Beverage & Canned">Beverage & Canned</Option>
                            <Option value="Other">Other</Option>
                        </Select>
                      )}
                    </Form.Item>
                    <Form.Item>
                        <Button  style = {{background: "#00cb70", border: "2px solid #00cb70"}} type="primary" htmlType = "submit" >Add Item</Button>
                    </Form.Item>
                </Form>
            </div>

        );
    }
}

const WrappedItemForm = Form.create()(ProductForm);
export default WrappedItemForm;