import React from 'react'
import { Button, Popconfirm, message } from 'antd';
import axios from 'axios';
import ProductEditForm from '../components/ProductEditForm'
import Product from '../components/Product';
import ItemEditForm from '../components/ItemEditForm';
import uuidv4 from 'uuid'
class EditContainer extends React.Component {
    state = {
        product: {},
        items : [],
        deleted: [],
    } 
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount() {
        const itemID = this.props.match.params.itemID
        console.log("ITEMid",itemID)
        axios.get(`https://api.farmersofamerica.net/api/products/${itemID}/`,this.config)
            .then(res => {
                this.setState({
                    product: res.data
                })
            })
        axios.get(`https://api.farmersofamerica.net/api/edit/${itemID}/`,this.config)
            .then(res => {
                this.setState({
                    items: res.data
                })
                console.log("edit items",this.state.items)
            })
        
    }
    confirm = (id) => {
        const promises = []
        const url = "https://api.farmersofamerica.net/api/products/" + id + "/"
        axios.delete(url,this.config).then(res=>promises.push(res))
        message.info('Item succesfully removed');
        Promise.all(promises).then(res=>{
            this.props.history.push('/inventory/')
        })
    
      }
    updateName = (e) => {
        let copy = Object.assign({},this.state.product)
        copy.name = e.target.value
        this.setState({
            product: copy
        })
        
    }
    updateDescription = (e) => {
        let copy = Object.assign({},this.state.product)
        copy.description = e.target.value
        this.setState({
            product: copy
        })
        
    }
    updateQuantity = (value,id) => {
        var copy = [...this.state.items]
        for(let i = 0; i<copy.length; i++){
            if(copy[i].id === id){
                copy[i].unit_quantity = value.target.value;
                break;
            }
        }
        this.setState({
            items: copy
        })
        
    }
    updateMeasurement = (value, id) => {
        var copy = [...this.state.items]
        for(let i = 0; i<copy.length; i++){
            if(copy[i].id === id){
                copy[i].unit_of_measurement = value.target.value;
                break;
            }
        }
        this.setState({
            items: copy
        })
        
    }
    updatePrice = (value, id) => {
        var copy = [...this.state.items]
        for(let i = 0; i<copy.length; i++){
            if(copy[i].id === id){
                copy[i].price = value;
                break;
            }
        }
        this.setState({
            items: copy
        })
        
    }
    updateStock = (value, id) => {
        var copy = [...this.state.items]
        for(let i = 0; i<copy.length; i++){
            if(copy[i].id === id){
                copy[i].amount_in_stock = value;
                break;
            }
        }
        this.setState({
            items: copy
        })
        
    }
    printState = () => {
        this.setState({
            test: !this.state.test
        })
        console.log(this.state)
    }
    updateCategory = (category) => {
        console.log(category)
        let copy = Object.assign({},this.state.product)
        copy.category = category
        this.setState({
            product: copy
        })
        
    }
    addEmptyItem = () =>{
        var empty = {id:uuidv4(),new_item: true,product: this.state.product.id}
        console.log("added empty item",empty.id,empty.new_item)
        this.setState({
            items: [...this.state.items,empty]
        })
    }
    deleteItem = (id) => {
        var copy = []
        const toDelete = this.state.deleted
        console.log(this.state.items.length)
        for(let i = 0; i<this.state.items.length; i++){
            if(this.state.items[i].id !== id){
                console.log("adding you",this.state.items[i].amount_in_stock)
                copy.push(this.state.items[i])
                
            }
            else{
                toDelete.push(this.state.items[i])
            }
            
        }
        this.setState({deleted: toDelete})
        this.setState({items: copy})
        
    }
    saveChanges = () => {
        console.log("state:",this.state.items)
        const productId = this.state.product.id
        const product_url = `https://api.farmersofamerica.net/api/products/${productId}/`
        axios.put(product_url,this.state.product, this.config)
        for(let i = 0; i<this.state.items.length; i++){
            let itemId = this.state.items[i].id
            if(this.state.items[i].new_item===true){
                const itemUrl = `https://api.farmersofamerica.net/api/items/`

                axios.post(itemUrl,this.state.items[i],this.config)
            }
            else{
                const itemUrl = `https://api.farmersofamerica.net/api/items/${itemId}/`
                axios.put(itemUrl,this.state.items[i],this.config)
            }
        }
        for(let i = 0; i<this.state.deleted.length; i++){
            let itemId = this.state.deleted[i].id
            let deleteUrl = `https://api.farmersofamerica.net/api/items/${itemId}/`
            axios.delete(deleteUrl,this.config)
        }
        this.props.history.push('/inventory')
    }
    render() {
        return (
            <div>     
                <h2>Product Edit Page</h2> 
                <Product data = {[this.state.product]}/>       
                    <ProductEditForm 
                        data = {this.state.product} 
                        updateName={this.updateName} 
                        updateDescription={this.updateDescription} 
                        updateCategory={this.updateCategory}
                    />
                    <ItemEditForm 
                    data={this.state.items}
                    addEmptyItem = {this.addEmptyItem}
                    delete = {this.deleteItem}
                    updateMeasurement = {this.updateMeasurement}
                    updateQuantity = {this.updateQuantity}
                    updateStock = {this.updateStock}
                    updatePrice = {this.updatePrice}
                    />
                    <div>
              <Button 
              onClick = {(e)=>this.saveChanges()}
              style = {{
                  marginTop: "3vw",
                  background: "#00cb70",
                  border: "2px solid #00cb70"
                }}
                size = "large"
                type = "primary"
              >
              Save Changes
              </Button>
              <Popconfirm 
                    placement="topRight" 
                    title={"Delete this product and all of its items?"} 
                    onConfirm={(e)=>this.confirm(this.state.product.id)} 
                    okText="Yes" 
                    cancelText="No"
                    >
                        <Button 
                        style={{
                            marginLeft : "38vw",
                        }}
                        type = "danger"
                        >
                        
                        Delete All</Button>
                    </Popconfirm>  
              </div>
                <div>
                    </div>
            </div>
        );
    }
}
export default EditContainer;