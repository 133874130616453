import React from 'react';
import { Route } from 'react-router-dom';
import CartView from './containers/CartView';
import ProductList from './containers/ProductListView';
import ProfileView from './containers/ProfileView';
import EditContainer from './containers/EditContainer';
import Login from './containers/Login';
import RegistrationForm from './containers/Signup';
import ClientList from './containers/ClientContainer'
import ClientSearch from './containers/ClientSearch'
import FriendRequestView from './containers/FriendRequestView'
import TransactionContainer from './containers/TransactionContainer';
import Home from './containers/Home';
import ReviewPurchase from './containers/ReviewPurchase';
import ChangePasswordView from './containers/ChangePasswordView';
import TransactionItemContainer from './containers/TransactionItemContainer';
import FarmerSignUp from './containers/FarmerSignUp'
import InventoryContainer from './containers/InventoryContainer';
import DashboardContainer from './containers/DashboardContainer';
import BuyerSignUp from './containers/BuyerSignUp';
import About from './containers/About';
import DataProblem from './containers/DataProblem';
import Team from './containers/Team';
import DonateContainer from './containers/DonateContainer';
import DonationContainer from './containers/DonationContainer';
import DonationItemContainer from './containers/DonationItemContainer';
import AboutRethink from './containers/AboutRethink';
const BaseRouter = (LayoutProps) => (

    <div>
        <Route exact path = '/review/' render={(props) => (<ReviewPurchase {...props} updateCart={LayoutProps.updateCart} />) } />
        <Route exact path='/items/:itemID/' component={EditContainer} />
        <Route exact path= '/login/' component={Login}/>
        <Route exact path ='/signup/' component={RegistrationForm}></Route>
        <Route exact path ='/inventory/' render={(props) => (<InventoryContainer {...props} updateCart={LayoutProps.updateCart} />) }></Route>
        <Route exact path ='/inventory/:param1/'render={(props) => (<InventoryContainer {...props} updateCart={LayoutProps.updateCart} />) }></Route>
        <Route exact path ='/cart/' render={(props) => (<CartView {...props} updateCart={LayoutProps.updateCart} />) } ></Route>   
        <Route exact path ='/clients/' component={ClientList}></Route>   
        <Route exact path = '/clientsearch/' component = {ClientSearch}></Route>
        <Route exact path ='/requests/' component={FriendRequestView}></Route>   
        <Route exact path ='/profile/' component={ProfileView}></Route>
        <Route exact path = '/change_password/' component={ChangePasswordView}></Route>
        <Route exact path ='/profile/:userID/' component={ProfileView}></Route> 
        <Route exact path ='/orders/' component={TransactionContainer}></Route>
        <Route exact path ='/orders/:transId/' component={TransactionItemContainer}></Route>
        <Route exact path = '/' component = {Home} ></Route>
        <Route exact path = '/farmer_signup/' component = {FarmerSignUp}></Route>
        <Route exact path = '/buyer_signup/' component = {BuyerSignUp}></Route>
        <Route exact path = '/dashboard/' component = {DashboardContainer}></Route>
        <Route exact path = '/about/' component = {About}></Route>
        <Route exact path = '/data-problem/' component = {DataProblem}></Route>
        <Route exact path = '/team/' component = {Team}></Route>
        <Route exact path = '/donate/' component = {DonateContainer}></Route>
        <Route exact path = '/donations/' component = {DonationContainer}></Route>
        <Route exact path = '/donations/:donationId/' component = {DonationItemContainer}></Route>
        <Route exact path = '/rethink/' component = {AboutRethink}></Route>


    </div>
);

export default BaseRouter;