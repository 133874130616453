import React from 'react'
class BuyerDashboard extends React.Component {
    state = {
        products: [],
        inventory_owner: "My",
        is_owner: true
    }
    url = ""
    componentWillReceiveProps() {
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    }
    componentDidMount() {
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    }
    render() {
        return (
            <body style = {{fontFamily: 'GothamRound'}}>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li class="is-active">
                            <a href="#">
                                <span class="icon">
                                    <i class="fas fa-house-user" aria-hidden="true"></i>
                                </span>
                                <span>Dashboard</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div class="column">
                    <div class="content">
                        <div class="tile is-ancestor">
                            <div class="tile is-vertical is-8">
                                <div class="tile">
                                    <div class="tile is-parent">
                                        <article class="tile is-child notification">
                                            <h3 class="is-size-3">Past orders</h3>

                                        </article>
                                    </div>
                                </div>
                                <div class="tile is-parent">
                                    <article class="tile is-child notification">
                                        <h3 class="is-size-3">Relevant news</h3>
                                        <p></p>
                                        <div class="content">
                                            More content here.
                                </div>
                                    </article>
                                </div>
                            </div>
                            <div class="tile is-parent">
                                <article class="tile is-child notification">
                                    <div class="content">
                                        <h3 class="is-size-3">Farms</h3>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        )
    }
}
export default BuyerDashboard;