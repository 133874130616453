import React from 'react'
import FarmerDashboard from '../components/FarmersDashboard'
import BuyerDashboard from '../components/BuyerDashboard'
class DashboardContainer extends React.Component {
    state = {
        products: [],
        inventory_owner: "My",
        is_owner: true
    }
    url = ""
    componentWillReceiveProps() {
        const config = {
            headers:{
                'Authorization' : 'Token ' + localStorage.getItem('token')
            }
        }
    }

    render() {
        const token = localStorage.getItem('token');
        var isFarmer = localStorage.getItem('is_farmer')
        if(token===null){
            this.props.history.push("/")
        }
        return (    
            
                isFarmer === "true" ? 
                <FarmerDashboard/>
                :
                <BuyerDashboard/>
            

        )
    }
}
export default DashboardContainer;