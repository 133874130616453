import React from 'react';
import DonateItem from '../components/DonateItem';
import ReviewDonateItem from '../components/ReviewDonateItem';
import RethinkContainer from '../containers/RethinkContainer';
import { Button, Popconfirm, message } from 'antd';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import FarmerDonateItem from '../components/FarmerDonateItem';
class DonateContainer extends React.Component {
    state = {
        products: [],
        to_donate: {},
        review: false
    }
    updateDonation = this.updateDonation.bind(this)
    buyerUpdateDonation = this.buyerUpdateDonation.bind(this)
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    donate = () =>{
        this.setState({clicked: true})
        const url = 'https://api.farmersofamerica.net/api/donate/'
        axios.post(url,this.state.to_donate,this.config)
        .then(res=>{
        this.props.history.push('/dashboard')
        message.success("Thank you for your donation to Rethink!",0.75)
        }).catch(
            err => {
                this.setState({clicked: false})
            }

        )
    }
    buyerUpdateDonation(item, amount){
        if(amount === 0){
            const temp = this.state.to_donate
            delete temp[item.id]
            this.setState({to_donate: temp})
            return
        }
        const temp = this.state.to_donate
        item.unit_quantity = amount
        temp[item.id] = {
            item: item,
            amount: amount,
            unit: item.unit_of_measurement
        }
        this.setState({
            to_donate: temp
        }) 
    }
    updateDonation(item, amount) {
        if(amount === 0){
            const temp = this.state.to_donate
            delete temp[item.id]
            this.setState({to_donate: temp})
            return
        }
        const temp = this.state.to_donate
        console.log("updating donation",item, amount)
        temp[item.id] = {
            item: item,
            amount: amount,
            unit: item.unit_of_measurement
        }
        this.setState({
            to_donate: temp
        })
    }
    returnReviewDonation() {
        return (
            <div>
                <h2 style={{ fontWeight: "bold" }}>
                    Review your donation
        </h2>
                <ReviewDonateItem data={this.state.to_donate}/>
                {!this.state.clicked ?
                    [
                        <button class="button is-primary" style={{ marginTop: "1vh" }} onClick={this.donate}>
                            Confirm Donation
        </button>
                        ,
                        <button class="button" onClick = {()=>this.setState({review: false})} style={{ marginTop: "1vh", marginLeft: "3vw" }}>
                            Go Back
     </button>
                    ]
                    :
                    <button class="button is-primary is-loading" style={{ marginTop: "1vh" }}>Confirm Donation</button>
                }
                <NavLink to='/cart'>
                </NavLink>
            </div>
        )
    }
    componentDidMount() {
        const isFarmer = localStorage.getItem("is_farmer")
        if (isFarmer === "false") {
            return
        }
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        const url = 'https://api.farmersofamerica.net/api/inventory/'
        axios.get(url, config)
            .then(res => {
                console.log("test", res.data)
                this.setState({
                    products: res.data
                })
            })
            .catch(res => {
                this.props.history.push('/')

            })
        const url2 = 'https://api.farmersofamerica.net/api/rethink/'
        axios.get(url2, config)
                .then(res => {
                    this.setState({
                        rethink: res.data.rethink
                    })
                })
                .catch(res => {
                    this.props.history.push('/')
    
                })
    }
    updateState = (id) => {
        var copy = [...this.state.items]
        var index = -1
        for (let i = 0; i < copy.length; i++) {
            if (copy[i]["id"] === id) {
                index = i
                break
            }
        }
        if (index === -1) {
            return
        }
        else {
            copy.splice(index, 1)
            this.setState({ items: copy })
        }
    }
    emptyCart() {
        const url = "https://api.farmersofamerica.net/api/cart/0/"
        axios.delete(url, this.config).then(res => {
            this.setState({ items: [] }, () => {
                message.success('Cart has been emptied');
            })
        })
    }
    render() {
        const token = localStorage.getItem('token');
        const isFarmer = localStorage.getItem('is_farmer')
        if (token === null) {
            this.props.history.push("/")
        }
        if(this.state.rethink === true){
            return <div>
            <nav class="breadcrumb" aria-label="breadcrumbs">
                <div className = "columns">
                    <div className = "column is-8">
                    <li class="is-active">
                        <a href="#">
                            <span class="icon">
                                <i class="fas fa-donate" aria-hidden="true"></i>
                            </span>
                            <span>Pending Donations</span>
                        </a>
                    </li>
                    </div>
                    <a href = "/donations" style = {{ color: "grey"}}>
                        View Donations
                    </a>
                
                
                </div>
            </nav><RethinkContainer/>
            </div>
        }
        if (!this.state.review) {

            return (<div>
                <nav class="breadcrumb" aria-label="breadcrumbs">
                    <div className = "columns">
                        <div className = "column is-8">
                        <li class="is-active">
                            <a href="#">
                                <span class="icon">
                                    <i class="fas fa-donate" aria-hidden="true"></i>
                                </span>
                                <span>Donate to Rethink</span>
                            </a>
                        </li>
                        </div>
                        <button style = {{marginLeft: -70}} class="button">
                        <a href = "/donations" style = {{ color: "grey"}}>
                            View Previous Donations
                        </a>
                        </button>
                    
                    </div>
                </nav>
                {isFarmer === "false" ?
                <FarmerDonateItem data = {this.state.to_donate} update={this.buyerUpdateDonation}/> :
                <DonateItem data={this.state.products} update={this.updateDonation} />
                }
                {Object.keys(this.state.to_donate).length > 0 ?
                    [
                        <Button onClick={() => this.setState({ review: true })}>
                            Review Donation
                        </Button>,
                        <NavLink to=''>
                            <Button style={{ marginLeft: "3vw" }}>
                                Cancel
                        </Button>
                        </NavLink>]
                    :
                    [
                        <Button disabled onClick={() => this.setState({ review: true })}>
                            Review Donation
                        </Button>,
                        <NavLink to=''>
                            <Button style={{ marginLeft: "3vw" }}>
                                Cancel
                        </Button>
                        </NavLink>]
                }
            </div>)

        }
        else {
            return (
                this.returnReviewDonation()
            )
        }

    }
}
export default DonateContainer;