import React from 'react';
import icon from '../images/icon.jpg';
import {
    List, Avatar
  } from 'antd';
class TransactionItems extends React.Component {
    state = {
        transactions : []
    }

    getStatusColor(status) {
        if(status==="Pending"){
            return "#f5d142"
        } else if(status === "Confirmed"){
            return "#18B259"
        } else if(status === "Denied") {
            return "#D12D2D"
        } else {
            return "#000000"
        }
    }
    getImageUrl(url, name){
      if(url === null  || url === ""){
        return this.getImage(name)
      } else{
          return url
      }
    }
    getImage = (name) => {
      if(name!=null){
        name = name.toLowerCase()
        name = name.split(" ")[0]
      }
      console.log("names",name)
      const available_veggies = [
          "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
          "corn", "arugula", "beansprouts", "cucumber", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
        ]
      if(available_veggies.includes(name)){
        const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
        return url
      }
      else{
        return icon
      }
    }
    render(){
        console.log("trans items",this.props.data)
        return (
            <div className="transactionList">
                <List
                  className = "transactionListItem"
                  dataSource={this.props.data}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                      avatar = {<Avatar src={this.getImageUrl(item.image_url, item.product_name)} size={60} />}
                        title={<h2 className = "transactionListItemTitle">{item.product_name}</h2>}
                        description={<p className="transactionListItemDescription">Price: ${Number.parseFloat(item.price).toFixed(2)}‎‎‎‎
                        <p style ={{marginTop: "1vh"}}>Quantity:{item.quantity}</p>

                        
                        </p>}
                      />

      
                    </List.Item>
                  )}
                >
                </List>
            </div>
          );
    }
}
export default TransactionItems;