import React from 'react';
import {
    Select, InputNumber, Button, message
} from 'antd';
import axios from 'axios';
const Option = Select.Option

class Item extends React.Component {
    state = {
        id: null,
        price : 0,
        old_price: 0,
        instock:0,
        amount: 0
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount(){
        if(this.props.data[0] != null){
            this.setState({
                id: this.props.data[0].id,
                price: this.props.data[0].price,
                old_price: this.props.data[0].old_price,
                instock: this.props.data[0].amount_in_stock,
                amount: this.props.data[0].unit_quantity
            })
        }
    }
    changePrice = (value) => {
        const split = value.split("#")
        this.setState({
            price: split[0],
            instock: split[1],
            old_price: split[3],
        })

    }
    updateValue = (value) =>{
        console.log("value",value)
        this.setState({
            amount: value
        })
    }
    placeOrder = () => {
        axios.post('https://api.farmersofamerica.net/api/itemorder/',{
            quantity: this.state.amount,
            price: this.state.price,
            item_id: this.state.id,

        },this.config)
        console.log("hello")
        message.success(`Added ${this.state.amount} to bart!`,0.5)
    }
    getPrice =(old_price, price) => {
        old_price = parseFloat(old_price)
        price = parseFloat(price)
        if(old_price === price){
            return <font color="black">
                {"$"+(price).toFixed(2)+" = "}
            </font>
        }
        else if(old_price < price){
            return (
            <font color="green">
            {"$"+(price).toFixed(2)+" ↑ "}
            </font>
            )
        }
        else{
            return(
                <font color="crimson">
                {"$"+(price).toFixed(2)+" ↓ "}
                </font> 
            )
        }
    }
    render() {
        return (
            <div className="item">
            <span>
                <Select 
                defaultValue={
                    this.props.data[0] == null ?
                    "N/A":
                    this.props.data[0].unit_quantity+" "+this.props.data[0].unit_of_measurement
                }  
                style={{fontSize: "1vw", width: "8vw", marginLeft: "-30vw",  position: "absolute"}}
                onChange = {this.changePrice}>   
                    {this.props.data.map((i) => 
                        <Option 
                        value={i.price+"#"+i.amount_in_stock+"#"+i.id+"#"+i.old_price}
                        key = {i.id}> 
                        {i.unit_quantity+" "+i.unit_of_measurement} 
                        </Option>
                    )}
                </Select>
               </span>
               <span>
                <b style = {{fontSize: "1vw",width:"10vw", marginLeft: "-19vw", position: "absolute"}}>
                {
                    this.getPrice(this.state.old_price, this.state.price)
                    }
                </b>
                </span>
                <em style = {{fontSize: "1vw", marginLeft: "-13vw",width:"10vw", position: "absolute"}}>{"In stock: "+this.state.instock}</em>
                {
                    this.props.owner ? 

                null

                :
                    [
                <InputNumber key = "asdf" style = {{width: "4vw",marginLeft: "-6vw", position: "absolute"}} min={0} max={999} defaultValue={0} onChange={this.updateValue}/>,
                <Button key = "qwer" type="primary" onClick = {this.placeOrder}>
                  Add to cart
                  </Button>
                    ]

                }



            </div>
        );
    }
}

export default Item;