    
import React from 'react';
import { Form, Icon, Input, Button, Spin, message, Divider } from 'antd';
import LoginBackgroundVideo from '../components/LoginBackgroundVideo'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import whiteicon from '../images/blacklogo.png'
import * as actions from '../store/actions/auth';
import daniel from '../images/team/daniel_real.png';
import teymour from '../images/team/teymour.jpg';
import topLogo from '../images/bon-harvest-title-green.png'
import video from '../videos/on-the-vine.mp4'
import whiteIcon from '../images/bon-harvest-icon-white.png'
import officeMap from '../images/office-map-png.png'
import titleWhite from '../images/bon-harvest-title-white.png'
import green from '../images/bon-harvest-title-green.png'
const FormItem = Form.Item;
const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;


class NormalLoginForm extends React.Component {
  state = {
        email:"",
        password:"",
        buttonName: "button is-block is-info is-large is-fullwidth",
        emailError: false,
        passwordError: false,
        loginError: false,
    }
  handleChange(e, field){
      this.setState({[field]:e.target.value, [field+"Error"]: false})
  }
  handleSubmit = (e) => {
    e.preventDefault();
    if(this.state.email.length>0 && this.state.password.length > 0){
    this.setState({buttonName: "button is-block is-info is-large is-fullwidth is-loading"})
    this.props.onAuth(this.state.email, this.state.password)
    const a = this.props.history
    const b = this
    setTimeout(
        function(){
            if(localStorage.getItem('token')!=null){
                a.push('/dashboard')
            } else{
                b.setState({buttonName: "button is-block is-info is-large is-fullwidth"})
                message.error("Incorrect username/password", 1)
            }

        }
    ,1000)
    } else{
        if(this.state.email.length === 0){
            this.setState({emailError: true})
        }
        if (this.state.password.length === 0){
            this.setState({passwordError: true})
        }
    }
  }
  handleSubmit2 = () => {
    this.props.onAuth(this.state.email, this.state.password)
    const a = this.props.history
    setTimeout(
        function(){
            if(localStorage.getItem('token')!=null){
                a.push('/dashboard')
            }

        }
    ,1000)
  }
  onFormSubmit = (e) => {
      e.preventDefault()
      console.log(e)
  }
  render() {
    if(localStorage.getItem('token')!=null){
        this.props.history.push('/dashboard')
    }
    let errorMessage = null;
    if (this.props.error) {
        errorMessage = (
            <h1 style={{color: "red", textAlign: "center"}}>Invalid Username or Password</h1>
        );
    }
        return ( 
        <body style = {{fontFamily: 'GothamRound'}}>
            <section class="section has-background-primary" style={{"height":750}}>
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-one-quarter">
                            <div class="content">
                                <a href="index.html"><img src={titleWhite}alt="Bon Harvest title"/></a> 
                            </div>
                        </div>
                    </div>
                    <div style={{ "height": 550, "marginTop":30 }} class="columns is-centered">
                        <div class="column is-half has-background-white">
                        <form onSubmit={this.handleSubmit}> 
                            <div class="content">
                                <div class="field" style={{"marginTop":30}}>
                                    <label style = {{fontSize: 16}} class="label is-large">Email</label>
                                    <div class="control has-icons-left">
                                        <input name = "email" className="input is-large" type="text" placeholder="Email" onChange={(e)=>this.handleChange(e, "email")}/>
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-envelope"></i>
                                        </span>

                                    </div>
                                </div>
                                {this.state.emailError ? 
                                    <p style = {{color: 'red', marginLeft: "0.1vw", position: "absolute"}}>Email cannot be blank</p> : null
                                
                            }
                                
                                <div style={{"marginTop":40}}class="field">
                                    <label style = {{fontSize: 16}} class="label is-large">Password</label>
                                    <div style = {{height: "50%" }} class="control has-icons-left has-icons-right">
                                        <input name = "password" className="input is-large" type="password" placeholder="Password" onChange={(e)=>this.handleChange(e, "password")}/>
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-lock"></i>
                                        </span>
                                    </div>
                                </div>
                                {this.state.passwordError ? 
                                    <p style = {{color: 'red', marginLeft: "0.1vw", position: "absolute"}}>Password cannot be blank</p> : null
                                
                            }
                                
    
                                <div >
                                    <div >
                                        <button type = "submit" style={{height: 70, fontSize: 25, "marginTop": 50}}className={this.state.buttonName}>
                                            Login 
                                            <i class="fa fa-sign-in" aria-hidden="true"></i>
                                        </button>
                                    </div>  
                                </div>
                            </div>
                        </form>
                        <Divider style = {{color: "lightgrey" , marginTop: 50}}>Or</Divider>
                            <p style={{"marginTop":45,"textAlign":"center"}}class="has-text">
                        <a href="../farmer_signup">Sign Up</a> &nbsp;·&nbsp;
                        <a href="../">Forgot Password</a> &nbsp;·&nbsp;
                        <a href="../">Home</a>
                    </p>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="footer">
                <div class="container">
                    <div class="columns">
                        <div class="column is-one-quarter">
                            <div class="content">
                                <a href="/"><img src={green}/></a>
                                <h4>Quick tagline</h4>
                                <p>
                                    <button onClick = {()=>this.props.history.push("/farmer_signup")}className="button is-rounded is-danger"> 
                                        <span class="icon">
                                            <i class="fas fa-tractor"></i>
                                        </span>
                                        <span><strong>Get early access</strong></span>
                                    </button>
                                </p>
                                <h4><a href="/about#ethics">Bon Harvest Ethics</a></h4>
                                <h4><a href="/about#story">Our Story</a></h4>
                                <h4><a href="/about#history">Our History</a></h4>
                                <h4><a href="/team">Team</a></h4>
                                <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                                <h4><a href="/data-problem">The Data Problem</a></h4>
                            </div>
                        </div>
                        <div class="column is-2">
                            <div class="content">
                                <p class="has-text-weight-bold">New Lab</p>
                                <p>
                                    Building 128<br></br>
                                    19 Morris Ave<br></br>
                                    Brooklyn, NY 11205<br></br>
                                    USA
                                </p>
                            </div>
                        </div>
                        <div class="column is-2">
                            <div class="content">
                                <p>
                                    <span class="icon has-text-primary">
                                        <i class="fas fa-phone"></i>
                                    </span>
                                    917-724-4474
                                    <br></br>
                                    <span class="icon has-text-primary">
                                        <i class="fas fa-envelope"></i>
                                    </span>
                                    hello@bonharvest.com
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </body>)
    
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.authLogin(email, password)) 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);