import React from 'react';
import axios from 'axios';
import {
    List, Avatar, Button,
  } from 'antd';
import { Link } from 'react-router-dom';
class Donation extends React.Component {
    state = {
        donations : []
    }
    config = {
      headers:{
          'Authorization' : 'Token ' + localStorage.getItem('token')
      }
  }
    getStatusColor(status) {
        if(status==="Pending"){
            return "#f5d142"
        } else if(status === "Accepted"){
            return "#18B259"
        } else if(status === "Declined" || status === "Cancelled by donator") {
            return "#D12D2D"
        } else {
            return "#000000"
        }
    }
    componentWillReceiveProps(nextProps) {
      this.setState({
        donations:nextProps.data
      })
  }
    updateDonation(item, action){
      const donationId = item.id
      const url = "https://api.farmersofamerica.net/api/donations/"+donationId+'/'
      axios.put(url, {
        id: donationId,
        action: action

    }, this.config).then(res=>
        {
        const index = this.state.donations.findIndex(x=>x.id === donationId)
        var donations = this.state.donations
        var donation = donations[index]
        donation.status = action
        donations[index] = donation
        this.setState({
          donations: donations
        })
    }).catch(
      err=>{
        console.log(err)
      }
    )
    }

    formatDate(date){
      if(date == null){
        return date
      }
      const dates = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      }
      const values = date.substring(0,10).split("-")
      const year = values[0]
      const month = dates[values[1]]
      const day = values[2]
      return month+" "+day+", "+year
    }
    getButtons(item){
        const status = item.status
        const rethink = item.rethink
        if(status === "Pending"){
            if(rethink){
                return (
                    <div>
                    <button onClick = {()=>{this.updateDonation(item,"Accepted")}} style={{'marginLeft':'1vw'}} class="button is-success">
                      Accept Donation
                  </button>
                  <button onClick = {()=>{this.updateDonation(item,"Declined")}}style={{'marginLeft':'1vw'}} class="button is-danger">
                      Decline Donation
                  </button>
                  </div>
                )
            } else{
                return(
                    <button onClick = {()=>{this.updateDonation(item,"Cancelled by donator")}}style={{'marginLeft':'1vw'}} class="button is-danger">
                    Cancel Donation
                </button>
                )
            }
        }
        return null
    }
    render(){
        console.log("test", this.state.donations)
        return (
            <div className="transactionList">
                <List
                  className = "transactionListItem"
                  dataSource={this.state.donations}
                  renderItem={item => (
                    <List.Item  key={item.id}>
                      <List.Item.Meta
                        title={<h2 className = "transactionListItemTitle">Donation from {item.giver}</h2>}
                        description={<p className="transactionListItemDescription">Purchase date: {this.formatDate(item.purchase_date)} ‎‎‎‎
                        
                        <p style ={{fontSize: 15, marginTop: "1vh"}}>Status: <span style={{ color:this.getStatusColor(item.status)}}>{item.status}</span></p>

                        
                        </p>}
                      />
                      <Link to={`/donations/${item.id}`}>
                      <button  className="button">
                         View Donation
                      </button>
                      </Link>
                      {
                          this.getButtons(item)

                      }
      
                    </List.Item>
                  )}
                >
                </List>
            </div>
          );
    }
}
export default Donation;