import React from 'react';
import {
     message, List, Avatar, InputNumber
} from 'antd';
import uuid from 'react-uuid'
import icon from '../images/icon.jpg';
import fruit from '../images/fruit.png'
import meat from '../images/meat.png'
import veggie from '../images/veggie.png'
import axios from 'axios'
import DonateItem from './DonateItem';

class FarmerDonateItem extends React.Component {
    state = {
        name: "",
        quantity: 0,
        unit: "lb", 
        products: [],
        prop_donate: {}
    }
    imageMap = {
        "Other": icon,
        "Livestock": meat,
        "Vegetable": veggie,
        "Fruit": fruit,
    };
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount(){
    }
    updateValue = (value, target) => {
        this.setState({[target]: value})
    }
    updateAmount(item, e){

        this.props.update(item, e)
    }
    displayProducts(){
        if(this.state.products.length === 0){
            const temp = this.props.data
            const keys = Object.keys(temp)
            if(keys.length === 0){
                return <div></div>
            }
            const items = []
            for(const id of keys){
                items.push(temp[id].item)
            }
            this.setState({
                products: items
            })
        }
        return(
            <List
            dataSource={this.state.products}
            renderItem={item => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  avatar={<Avatar src={this.getImageUrl(item.image_url, item.product_name)} size={60} />}
                  title={item.product_name}
                />
                <div style = {{marginRight: 10}} className = "column is-3">
                  <InputNumber key="1" style={{ width: 70, marginRight: 10}} min={0} max={item.amount_in_stock} defaultValue={item.unit_quantity} onChange={(e) => this.updateAmount(item, e)} />
                      {item.unit_of_measurement}

                </div>
  
              </List.Item>
            )}
          >
          </List>
        )
    }
    addItem(){
        const product = {
            id: uuid(),
            product_name: this.state.name,
            unit_quantity: this.state.quantity,
            unit_of_measurement: this.state.unit

        }
        this.setState({
            products: [...this.state.products, product]
        }, ()=>{
            document.getElementById("donate-input-name").value = ""
            document.getElementById("donate-input-quantity").value = ""
            document.getElementById("donate-select-unit").value = "lb"
            this.props.update(product, product.unit_quantity)
        })

    }
    getImageUrl(url, name) {
        if (url == null || url == "") {
            return this.getImage(name)
        } else {
            return url
        }
    }
    getImage = (name) => {
        if (name != null) {
            name = name.toLowerCase()
            name = name.split(" ")[0]
        }
        console.log("names", name)
        const available_veggies = [
            "artichoke", "avocado", "broccoli", "cabbage", "carrot", "eggplant", "lettuce", "onion", "potato", "tomato",
            "corn", "arugula", "beansprouts", "cucumber", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
        ]
        if (available_veggies.includes(name)) {
            const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
            return url
        }
        else {
            return icon
        }
    }
    
    render() {
        return (
                <div>
                <div style = {{marginLeft: -16}}className="columns">
                    <div className="column is-4">
                        <input autoComplete = "off" id="donate-input-name" style={{ width: 300 }} onChange = {(e)=>this.updateValue(e.target.value,"name")} class="input" type="text" placeholder="Product name" />
                    </div>
                    <div className="column is-3">
                        <input autoComplete = "off" id = "donate-input-quantity" style={{ width: 200 }} onChange = {(e)=>this.updateValue(e.target.value,"quantity")} class="input" type="text" placeholder="Quantity" />
                    </div>
                    <div className="column is-2">
                        <div class="field">
                            <div class="control">
                                <div class="select is-primary">
                                    <select id = "donate-select-unit" onChange = {(e)=>this.updateValue(e.target.value,"unit")} style =  {{width : 150}}>
                                        <option>lb</option>
                                        <option>kg</option>
                                        <option>gal</option>
                                        <option>L</option>
                                        <option>units</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className = "column is-3">
                    <button onClick = {()=>{this.addItem()}} style = {{width: 80}} className="button is-primary">Add</button>

                    </div>
                </div>
                {this.displayProducts()}
                </div>
        );
    }
}

export default FarmerDonateItem;
