import React from 'react';
import {Button} from 'antd';
class Confirmation extends React.Component {
    state = {
        confirmDirty: false
    }
submit = async () => {
    const {values} = this.props
    await this.props.onAuth(
        values.username,
        values.email,
        values.password,
        values.password2,
        values.is_farmer,
        values.first_name,
        values.last_name,
        values.phone_number,
        values.address_line_1,
        values.address_line_2,
        values.city,
        values.state,
        values.zipcode,
        values.farm_size,
        values.farm_type,
        values.produce_type
    )
    this.props.history.push("/")
}

back = (e) => {
    this.props.prev()
}
render(){
    const {values} = this.props
    return(
    <div>
    <div className="confirmationDiv">
      <h2 className=" confirmationPageHeader">Confirmation Page. </h2>
        <h1 className = "confirmationPageAcctType">Account type: {
            values.is_farmer ? 
            "Farmer"
            :
            "Buyer"
        }
        </h1>
        <h1 className = "confirmationPageBizName">
        Business Name: {values.username}
        </h1>
        <h1 className = "confirmationPageEmail">
        Email: {values.email}
        </h1>
        <h1 className = "confirmationPagePassword">
        Password: {"*".repeat(values.password.length)}
        </h1>
        <h1 className = "confirmationPageName">
        Name: {values.first_name+" "+values.last_name}
        </h1>
        <h1 className = "confirmationPagePhone">
        Phone Bumber: {values.phone_number}
        </h1>
        <h1 className = "confirmationPageAddress"  > 
        Address: {values.address_line_1+" "+values.address_line_2}
        </h1>
        <h1 className = "confirmationPageCity"  >
        City: {values.city}
        </h1>
        <h1 className = "confirmationPageState"  >
        State: {values.state}
        </h1>
        <h1 className = "confirmationPageZip"  >
        Zipcode: {values.zipcode}
        </h1>
        {
            values.is_farmer ?
            [
                <h1 key = "0" className = "confirmationPageFarmSize">
                Farm Size: {values.farm_size}
                </h1>,
                <h1 key = "1" className = "confirmationPageFarmType">
                Farm Type: {values.farm_type}
                </h1>,
                <h1 key = "2" className = "confirmationPageProduceType">
                Produce Type: {values.produce_type.join(", ")}
                </h1>
            ] 
            :
            null
        }
        </div>
                    <Button
                    onClick = {this.back}
                    style = {{marginLeft: "33vw", width: "7vw", fontSize: "0.7vw"}}
                    >Previous
                </Button>
                <Button
                    onClick = {this.submit}
                    style = {{background: "#00cb70", border: "2px solid #00cb70", marginLeft: "12vw", width : "7vw", fontSize: "0.7vw"}} type="primary"
                    >Submit
                </Button>
                </div>
        )


    }
}

export default Confirmation;