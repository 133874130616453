import React from 'react'
import {Form, Icon, Input, Button} from 'antd'
const FormItem = Form.Item
class AddressDetails extends React.Component {
saveAndContinue = (rule, value, callback) => {
        const form = this.props.form;
        form.validateFields().then(
          this.props.next
        )
        .catch(
          err=>{
            console.log("Address Detail Errors",err)
          }
        )
      }
back = (e) => {
        this.props.prev()
}
    render() {
        const {values} = this.props
        const { getFieldDecorator } = this.props.form;
        return (
        <div>
        <h2 style = {{textAlign: "center",fontSize: "1.1vw"}}>Please enter your personal details. </h2>
         <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('first_name', {
                rules: [{ required: true, message: 'Please input your first name!' }],
                initialValue: values.first_name
            })(
                <Input 
                    style = {{ width: "20vw"}} 
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="First Name" 
                    onChange = {this.props.handleChange('first_name')}
                    />
            )}
        </FormItem>

        <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('last_name', {
                rules: [{ required: true, message: 'Please input your last name!' }],
                initialValue: values.last_name
            })(
                <Input 
                    style = {{ width: "20vw"}} 
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="Last Name" 
                    onChange = {this.props.handleChange('last_name')}
                    />
            )}
        </FormItem>

        <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('phone_number', {
                rules: [{ required: true, message: 'Please input your phone number!' }],
                initialValue: values.phone_number
            })(
                <Input 
                    style = {{ width: "20vw"}} 
                    prefix={<Icon type="phone" 
                    style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="Business Phone Number" 
                    onChange = {this.props.handleChange('phone_number')}
                    
                    />
            )}
        </FormItem>

        <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('address_line_1', {
                rules: [{ required: true, message: 'Please input your business address!' }],
                initialValue: values.address_line_1
            })(
                <Input 
                    style = {{ width: "20vw"}} 
                    prefix={<Icon type="home" 
                    style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="Business Address Line 1" 
                    onChange = {this.props.handleChange('address_line_1')}
                    />
            )}
        </FormItem>

        <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('address_line_2', {
                rules: [{ required: false, }],
                initialValue: values.address_line_2
            })(
                <Input 
                    style = {{ width: "20vw"}} 
                    prefix={<Icon type="home" 
                    style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="Business Address Line 2" 
                    onChange = {this.props.handleChange('address_line_2')}
                    />
            )}
        </FormItem>

        <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('city', {
                rules: [{ required: true, message: 'Please input your city!' }],
                initialValue: values.city
            })(
                <Input 
                    style = {{ width: "20vw"}} 
                    prefix={<Icon type="home" 
                    style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="City" 
                    onChange = {this.props.handleChange('city')}
                    />
            )}
        </FormItem>
        <div>
        <Form layout = "inline">
        <FormItem style = {{marginLeft: "36vw",}}>
            {getFieldDecorator('state', {
                rules: [{ required: true, message: 'Please input your state!' }],
                initialValue: values.state
            })(
                <Input 
                    style = {{ width: "9vw"}} 
                    prefix={<Icon type="home" 
                    style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="State" 
                    onChange = {this.props.handleChange('state')}
                    />
            )}
        </FormItem>

        <FormItem style = {{marginLeft: "1vw"}}>
            {getFieldDecorator('zipcode', {
                rules: [{ required: true, message: 'Please input your zipcode!' }],
                initialValue: values.zipcode
            })(
                <Input 
                    style = {{ width: "9vw"}} 
                    prefix={<Icon type="home" 
                    style={{ color: 'rgba(0,0,0,.25)' }} />} 
                    placeholder="Zipcode" 
                    onChange = {this.props.handleChange('zipcode')}
                    />
            )}
        </FormItem>
        </Form>
        </div>
        <FormItem>
        </FormItem>
        <Button
            onClick = {this.back}
            style = {{marginLeft: "36vw", width: "6vw",}}
            >Previous
        </Button>
        <Button
            onClick = {this.saveAndContinue}
            style = {{marginLeft: "7.9vw", width : "6vw"}}
            >Next
        </Button>
        </div>
        )
    }

}
const WrappedRegistrationForm = Form.create()(AddressDetails);

export default WrappedRegistrationForm;
