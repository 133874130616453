import React from 'react';
import {Button} from 'antd';
import { NavLink } from 'react-router-dom';
import farmhouse from '../images/farmhouse.png'
import chef from '../images/chef.png'
class UserType extends React.Component{

    render(){

       return(
        <div>
            <h2 style = {{fontSize: "2vw", textAlign: "center"}}>Welcome to Bon Harvest! Are you a Farmer or a Buyer?</h2>
            <div>
            <Button 
                style = {{width: "10vw", height: "10vw", marginLeft: "34vw", marginTop: "2vw"}}
                onClick = {this.props.makeFarmer}>
                    <img src={farmhouse} style = {{width: "8vw", height: "8vw"}} alt="Select this if you are a farmer!"/>
            </Button>
            <Button 
                style = {{width: "10vw", height: "10vw", marginLeft: "3vw", marginTop: "2vw"}}
                onClick = {this.props.makeBuyer}>
                     <img src={chef} style = {{width: "8vw", height: "8vw"}} alt = "Select this if you are a buyer!"/>
            </Button>
            </div>
            <h2 style={{float: "left", marginLeft: "37.4vw"}}>Farmer</h2>
            <h2 style={{float: "right", marginRight: "38.5vw"}}>Buyer </h2>
            <div>
            <h1 style = {{fontSize: ".9vw", marginTop: "2vw", marginLeft: "38vw", textAlign: "left", float: "left" }}>Already have an account?</h1>
            <NavLink to ="/login">
            <Button 
                style = {{marginTop: "1.8vw", marginLeft: "2vw", float: "left", background: "#00cb70", border: "2px solid #00cb70"}}
                type = "primary"
                
                >
            Login
            </Button>
            </NavLink>
            </div>
        </div>
        )
    }
}
export default UserType