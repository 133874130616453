import React from 'react';
import axios from 'axios';
import DonationItems from '../components/DonationItems'
class DonationItemContainer extends React.Component {
    state = {
        refresh: false,
        transactions : [],
        prevPage: 1
    }
    componentWillReceiveProps() {
        const config = {
            headers:{
                'Authorization' : 'Token ' + localStorage.getItem('token')
        }
       }
       const transactionId = this.props.match.params.donationId
       console.log(transactionId)
       const url = "https://api.farmersofamerica.net/api/donationitems/"+transactionId
        axios.get(url, config)
            .then(res => {
                this.setState({
                    transactions: res.data
                })
                console.log(this.state.transactions)
            })
    }
    componentDidMount() {
        const config = {
            headers:{
                'Authorization' : 'Token ' + localStorage.getItem('token')
            }
        }
        const transactionId = this.props.match.params.donationId
        const url = "https://api.farmersofamerica.net/api/donationitems/"+transactionId
        axios.get(url, config)
            .then(res => {
                this.setState({
                    transactions: res.data
                })
                console.log(this.state.transactions)
            })
    }
    render(){
        const token = localStorage.getItem('token');
        if(token===null){
            this.props.history.push("/")
        }
        console.log("donations", this.state.transactions)
        return (
            <div>
                <h2>
                    Donation Date: {this.state.transactions.length!=0 ? this.state.transactions[0].purchase_date.substring(0,10) : ""}
                    <h2 style = {{fontSize:14 , color: "lightgrey"}}>
                        {this.state.transactions.length > 0 ? 
                    `From: ${this.state.transactions[0].giver}`
                    :
                    null}
                </h2>
                     <DonationItems data={this.state.transactions}/>
                </h2>
                <button onClick = {()=>this.props.history.push(`/donations/`)} style = {{marginTop: 10}} button className = "button">
                    Go back
                </button>
            </div>
        );
    }
}
export default DonationItemContainer;