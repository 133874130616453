import React from 'react'
import topLogo from '../images/bon-harvest-title-green.png'
import officeMap from '../images/office-map-png.png'
import veggies from '../images/vegetables-cutting-board.jpg'
import radish from '../images/radish2.jpg'
class DataProblem extends React.Component {

    render() {
        return (
            <body style={{ fontFamily: 'GothamRound' }}>
        <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" href="/">
                    <img src={topLogo}/>
                </a>
                <a role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div class="navbar-menu is-size-5 has-text-weight-medium">
                <div class="navbar-end">
                    <a href="/data-problem" class="navbar-item is-active">
                        The Data Problem
                    </a>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a href="/about" class="navbar-link">
                            About
                        </a>
                        <div class="navbar-dropdown">
                            <a href="/about#story" class="navbar-item">
                                Story
                            </a>
                            <a href="/about#ethics" class="navbar-item">
                                Ethics
                            </a>
                            <a href="/about#history" class="navbar-item">
                                History
                            </a>
                            <hr class="navbar-divider"/>
                            <a  href="/team" class="navbar-item">
                                Team
                            </a>
                        </div>
                    </div>
                    <a href="/rethink" class="navbar-item">
                            Rethink Food
                        </a>
                    <a href="https://blog.bonharvest.com" class="navbar-item">
                        Blog
                    </a>
                    <div class="navbar-item">
                        <div class="buttons">
                            <a href="/farmer_signup" class="button is-medium is-rounded is-danger has-text-weight-semibold">
                                Get early access
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <section class="hero is-large parallax" style={{"backgroundImage": `url(${radish})`}}>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-size-1 has-text-white has-text-weight-bold">
                        The Data Problem
                    </h1>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
            <div class="columns is-centered">
                <div class="column is-two-thirds">
                    <div class="content is-medium">
                        <p>
                            Of the many issues stifling today’s food industry, the one that compels us most is the general lack of actionable, relevant, and reliable data. While other industries are using tech to capture and analyze data to become more efficient and sustainable, in comparison, the food industry continues to sputter.
                        </p>
                        <p>
                            A scalable marketplace between growers and buyers doesn’t exist. The way information is communicated is inefficient and disorganized, and leads to serious problems in the food supply chain.
                        </p>
                        <p>
                            We know that if farms and restaurants had access to the right tools, they’d quickly begin using data to their advantage. 
                        </p>
                        <p>
                            Simply by using the platform, our users automatically generate nutrient rich data. Bon Harvest takes this information, and turns it into easy-to-consume insights that will help users:
                        </p>
                        <ul>
                            <li>Understand who is growing what, and where and when.</li>
                            <li>Set more thoughtful selling/buying strategies by using our dynamic pricing data.</li>
                            <li>Map out the movement of food.</li>
                            <li>Identify trends to monetize on.</li>
                            <li>Anticipate problems, such as food shortages, long before they arise.</li>
                        </ul>
                        <p>
                            We’re using data to improve how the food industry operates, and we’re doing so without forcing our customers to make cumbersome or drastic changes to the way they do business.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <footer class="footer">
            <div class="container">
                <div class="columns">
                    <div class="column is-3">
                        <div class="content">
                            <a href="#"><img src={topLogo}/></a>
                            <h4><strong>Produce</strong>: Helping farmers sell it, helping chefs buy it.</h4>
                            <p>
                                <button onClick = {()=>{this.props.history.push("/farmer_signup")}} class="button is-rounded is-warning"> 
                                    <span class="icon">
                                        <i class="fas fa-tractor"></i>
                                    </span>
                                    <span><strong>Get early access</strong></span>
                                </button>
                            </p>
                            <h4><a href="/about">About</a></h4>
                            <h4><a href="/team">Team</a></h4>
                            <h4><a href="/rethink">Rethink</a></h4>
                            <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                            <h4><a href="/data-problem">The Data Problem</a></h4>
                        </div>
                    </div>
                    <div class="column">
                        <div class="content">
                            <div class="box">
                                <article class="media">
                                    <div class="columns is-variable is-0">
                                        <div class="column">
                                            <div class="media-left">
                                                <a href="#"><img src={officeMap} alt="Bon Harvest office map"/></a>
                                            </div>
                                        </div>
                                        <div class="column is-one-third">
                                            <div class="media-content">
                                                <div class="content has-text-white">
                                                    <p class="has-text-weight-bold">New Lab</p>
                                                    <p>
                                                        Building 128<br></br>
                                                        19 Morris Ave<br></br>
                                                        Brooklyn, NY 11205<br></br>
                                                        USA
                                                    </p>
                                                    <p>
                                                        <span class="icon has-text-primary">
                                                            <i class="fas fa-phone"></i>
                                                        </span>
                                                        917-724-4474
                                                        <br></br>
                                                        <span class="icon has-text-primary">
                                                            <i class="fas fa-envelope"></i>
                                                        </span>
                                                        hello@bonharvest.com
                                                    </p>
                                                </div>
                                                <nav class="level">
                                                    <div class="level-left">
                                                        <a class="level-item">
                                                            <span class="icon is-medium has-text-warning">
                                                                <i class="fab fa-2x fa-facebook-f"></i>
                                                            </span>
                                                        </a>
                                                        <a class="level-item">
                                                            <span class="icon is-medium has-text-warning">
                                                                <i class="fab fa-2x fa-twitter"></i>
                                                            </span>
                                                        </a>
                                                        <a href="https://www.instagram.com/bonharvest/" class="level-item">
                                                            <span class="icon is-medium has-text-warning">
                                                                <i class="fab fa-2x fa-instagram"></i>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </body>
        )
    }
}
export default DataProblem;