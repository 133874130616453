import React from 'react';
import { Form, Input, Icon, Button} from 'antd';

const FormItem = Form.Item;

class UserDetails extends React.Component {
    state = {
        confirmDirty: false
    }

saveAndContinue = (rule, value, callback) => {
  const form = this.props.form;
  form.validateFields().then(
    this.props.next
  )
  .catch(
    err=>{
      console.log("error",err)
    }
  )
}
back = (e) => {
        this.props.prev()
}
validatePassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords must match!');
    } else if(value.length <8 ) {
      callback('Password is too short! Must be at least 8 characters.')
    } else if(value.toUpperCase() === value || value.toLowerCase() === value) {
      callback('Password must contain uppercase and lowercase characters!')
    } else if (!/\d/.test(value)){
      callback('Password must contain at least one number!')
    } else {
      callback();
   }
}
emailValidator = (rule, value, callback) => {
    if(value === "dzhu@bonharvest.com"){
        callback('Email is already taken!')
    } else{
        callback();
    }

}
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }
render(){
    const { getFieldDecorator } = this.props.form;
    const {values} = this.props
    return(
    <div>
      <h2 style = {{textAlign: "center", fontSize: "1.1vw"}}>Please enter your account details. </h2>
        <FormItem style = {{marginLeft: "36vw"}}>
            {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your business name!' }],
                initialValue: values.username
            })(
                <Input
                style = {{ width: "20vw"}}
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Business Name"
                onChange = {this.props.handleChange('username')}
                />
            )}
        </FormItem>
        <FormItem style = {{marginLeft: "36vw"}}>
          {getFieldDecorator('email', {
            rules: [{type: 'email', message: 'The input is not valid email!'},
                    {required: true, message: 'Please input your email!'},
                    {validator: this.emailValidator}
                    ],
            initialValue: values.email
          })(
            <Input
            style = {{ width: "20vw"}}
            prefix={<Icon type="mail"
                        style={{ color: 'rgba(0,0,0,.25)'}}
                    />}
            placeholder="Email"
            onChange = {this.props.handleChange('email')}
            />
          )}
        </FormItem>
        <FormItem style = {{marginLeft: "36vw"}}>
          {getFieldDecorator('password', {
            rules: [{
              required: true, message: 'Please input your password!',
            }, {
              validator: this.validateToNextPassword,
            }],
            initialValue: values.password
          })(
            <Input
            style = {{ width: "20vw"}}
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            onChange = {this.props.handleChange('password')}
            placeholder="Password" />
          )}
        </FormItem>

        <FormItem style = {{marginLeft: "36vw"}}>
          {getFieldDecorator('confirm', {
            rules: [{
              required: true, message: 'Please confirm your password!',
            }, {
              validator: this.validatePassword,
            }],
            initialValue: values.password2
          })(
            <Input style = {{ width: "20vw"}}
            prefix={<Icon type="lock"
            style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Confirm your password"
            onBlur={this.handleConfirmBlur}
            onChange = {this.props.handleChange('password2')}
            />
          )}
        </FormItem>

        <Button
            onClick = {this.back}
            style = {{marginLeft: "36vw", width: "7vw", height: "4vh"}}
            >Previous
        </Button>
        <Button
            onClick = {this.saveAndContinue}
            style = {{marginLeft: "6vw", width : "7vw", height: "4vh", fontSize: "14px"}}
            >Next
        </Button>
        <h1 className="passwordTip">
          Passwords must be at least 8 characters long.
        </h1>
        <h1 className="passwordTip2">
          Contain numbers (0-9)
        </h1>
        <h1 className="passwordTip3">
          Contain upper case characters (A-Z)
        </h1>
        <h1 className="passwordTip4">
          Contain lower case characters (a-z)
        </h1>
        </div>
        )


    }
}
const WrappedRegistrationForm = Form.create()(UserDetails);

export default WrappedRegistrationForm;