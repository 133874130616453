import React from 'react';
import {message} from 'antd';
import axios from 'axios';
import {NavLink} from 'react-router-dom'
import ReviewItem from '../components/ReviewItem'
class ReviewPurchase extends React.Component {
    state = {
        items : [],
        clicked: false
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount(){
        const url = 'https://api.farmersofamerica.net/api/cart/'
        axios.get(url,this.config)
            .then(res =>{
                console.log("res data",res.data)
                this.setState({
                    items: res.data
                }, ()=>{
                    if(res.data.length === 0){
                        console.log('this', this.props.history.push('/cart'))
                    }
                })
        }).catch(
            err => {
                console.log("test")
            }

        )

    }
    updateState = (id) => {
        var copy = [...this.state.items]
        var index = -1
        for(let i = 0; i<copy.length; i++){
            if(copy[i]["id"] === id){
                index = i
                break 
            }
        }
        if(index===-1){
            return
        }
        else{
            console.log("we did it?")
            console.log(copy)
            copy.splice(index,1)
            console.log(copy)
            this.setState({items: copy})
        }
    }
    getTotal(){
        var sum = 0
        for(let i = 0; i<this.state.items.length; i++){
            sum+=(this.state.items[i].price*this.state.items[i].quantity)
        }
        return sum.toFixed(2)
    }
    purchase = () =>{
        this.setState({clicked: true})
        const url = 'https://api.farmersofamerica.net/api/cart/'
        axios.post(url,{},this.config)
        .then(res=>{
            this.props.updateCart(1.5)
            this.props.history.push('/orders')
            message.success("Successfully completed purchase",0.75)
        }).catch(
            err => {
                const error_array = err.response.data
                for(let i = 0; i<error_array.length; i++){
                    message.error("Quantity too large for "+error_array[i].item+". Max quantity: "+error_array[i].actual, 0.75)
                }
                this.setState({clicked: false})
            }

        )
    }
    render(){
        return (
            <div>
                <h2 style = {{fontWeight: "bold"}}>
                    Review your purchase
                </h2>
                <ReviewItem data = {this.state.items} delete = {this.updateState}/>
                <h2>
                    Total: ${[this.getTotal()]}
                </h2>
                {!this.state.clicked ? 
                [
                <button class="button is-primary" style = {{marginTop: "1vh" }} onClick = {this.purchase}>
                    Confirm Purchase
                </button>
                ,
                <NavLink to='/cart'>
                    <button href = "/cart" class="button" style={{marginTop: "1vh", marginLeft: "3vw"}}>
                Go Back
                </button>
                </NavLink>
                ]
                :
                <button class = "button is-primary is-loading" style = {{marginTop: "1vh"}}>Confirm Purchase</button>
                }
            </div>
        );
    }
}
export default ReviewPurchase;