import React from 'react';
import { Form, Select, Button } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;
class FarmDetails extends React.Component {
    state = {
        confirmDirty: false
    }
    produce_types = [
    <Option key = "Agri" value="Agriculture">Agriculture</Option>,
    <Option key = "Aqua" value="Aquaculture">Aquaculture</Option>,
    <Option key = "Can" value="Cannabis">Cannabis</Option>,
    <Option key = "Dairy" value="Dairy">Dairy</Option>,
    <Option key = "Horti" value="Horticulture">Horticulture</Option>,
    <Option key = "Hydro" value="Hydroponics">Hydroponics</Option>,
    <Option key = "Meat" value="Meat">Meat</Option>,
    <Option key = "Poultry" value="Poultry">Poultry</Option>,
    <Option key = "Urban" value="Urban Farming">Urban Farming</Option>,
]
    saveAndContinue = (rule, value, callback) => {
        const form = this.props.form;
        form.validateFields().then(
            this.props.next
        )
            .catch(
                err => {
                    console.log("error", err)
                }
            )
    }
    back = (e) => {
        this.props.prev()
    }
    change = (value) => {

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { values } = this.props
        return (
            <div>
                <h2 style={{ textAlign: "center", fontSize: "1.1vw" }}>Please enter your farm details </h2>

                <FormItem style={{ marginLeft: "36vw" }}>
                    {getFieldDecorator('farm_size', {
                        rules: [{ required: true, message: 'Please select your farm size!' }],
                        initialValue: values.farm_size || undefined
                    })(
                        <Select
                            placeholder="Please select a farm size"
                            style={{ width: "20vw" }}
                            onChange={value => this.props.updateSize(value)}
                            farm_size="farm_size"
                        >
                            <Option value="Small">Small (0-200 acres)</Option>
                            <Option value="Medium">Medium (200-1000 acres)</Option>
                            <Option value="Large">Large (1000+ acres)</Option>
                        </Select>
                    )}
                </FormItem>

                <FormItem style={{ marginLeft: "36vw" }}>
                    {getFieldDecorator('farm_type', {
                        rules: [{ required: true, message: 'Please select your farm type!' }],
                        initialValue: values.farm_type || undefined
                    })(
                        <Select
                            style={{ width: "20vw" }}
                            onChange={value => this.props.updateType(value)}
                            name="farm_type"
                            placeholder="Please select a farm type"
                        >
                            <Option value="Non-Organic">Non-organic</Option>
                            <Option value="Organic">Organic</Option>
                            <Option value="Both">Both</Option>

                        </Select>


                    )}
                </FormItem>
                <FormItem style={{ marginLeft: "36vw" }}>
                    {getFieldDecorator('produce_type', {
                        rules: [{ required: true, message: 'Please select at least one!' }],
                        initialValue: values.produce_type || []
                    })(
                        <Select
                            mode="multiple"
                            style={{ width: '20vw' }}
                            placeholder="Select all that apply"
                            initialValue={[]}
                            onChange={value=>{this.props.updateProduceType(value)}}
                        >
                            {this.produce_types}
                        </Select>
                    )}
                </FormItem>


                <Button
                    onClick={this.back}
                    style={{ marginLeft: "36vw", width: "7vw" }}
                >Previous
        </Button>
                <Button
                    onClick={this.saveAndContinue}
                    style={{ marginLeft: "6vw", width: "7vw" }}
                >Next
        </Button>
            </div>
        )


    }
}
const WrappedRegistrationForm = Form.create()(FarmDetails);

export default WrappedRegistrationForm;