import React from 'react'
import daniel from '../images/team/daniel_real.png';
import teymour from '../images/team/teymour.jpg';
import topLogo from '../images/bon-harvest-title-green.png'
import video from '../videos/on-the-vine.mp4' 
import whiteIcon from '../images/bon-harvest-icon-white.png'
import officeMap from '../images/office-map-png.png'
import vine from '../images/vine-leaves-mobile-background.jpg'
import vinecover from '../videos/on-the-vine-cover.png'
class Home extends React.Component {
    getHome(){
        const token = localStorage.getItem('token');
        if(token!==null){
            this.props.history.push("/dashboard")
        }
        return(
            <body style = {{fontFamily: 'GothamRound'}}>
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <a class="navbar-item" href="/">
                        <img src={topLogo}/>
                    </a>
                </div>
                <div class="navbar-menu is-active is-size-5 has-text-weight-medium">
                    <div class="navbar-end">
                        <a href="/data-problem" class="navbar-item">
                            The Data Problem
                        </a>
                        <div class="navbar-item has-dropdown is-hoverable">
                            <a href="/about" class="navbar-link">
                                About
                            </a>
                            <div class="navbar-dropdown">
                                <a href="/about#story" class="navbar-item">
                                    Story
                                </a>
                                <a href="/about#ethics" class="navbar-item">
                                    Ethics
                                </a>
                                <a href="/about#history" class="navbar-item">
                                    History
                                </a>
                                <hr class="navbar-divider"/>
                                <a  href="/team" class="navbar-item">
                                    Team
                                </a>
                            </div>
                        </div>
                        <a href="/rethink" class="navbar-item">
                            Rethink Food
                        </a>
                        <a href="https://blog.bonharvest.com/" class="navbar-item">
                            Blog
                        </a>
                        <div class="navbar-item">
                            <div class="buttons">
                                <a href="/login" class="button is-medium is-primary is-light">
                                    Sign in
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <section class="hero is-fullheight-with-navbar">
                <video autoPlay muted loop id="hero-video" class="is-hidden-touch" poster={vinecover}>
                    <source src={video} type="video/mp4"/>
                </video>
                <div class="is-hidden-desktop mobile-background is-fullheight" style={{"backgroundImage": `url(${vine})`}}>
                </div>
                <div class="hero-body">
                    <div class="container">
                        <div class="columns is-mobile is-centered">
                            <div class="column is-1-desktop is-4-mobile">
                                <img src={whiteIcon}/>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-12 has-text-centered">
                                <h1 style = {{fontFamily: 'GothamRound'}} class="title has-text-weight-bold is-spaced has-text-grey-lighter is-size-3-mobile">
                                    Farmers farm. Chefs cook. <br></br>
                                    <span class="title has-text-weight-bold has-text-white is-size-2-mobile">Bon Harvest informs.</span>
                                </h1>
                                <h2 style = {{fontFamily: 'GothamRound'}} class="subtitle is-4 has-text-weight-semibold has-text-white">
                                    Connecting the farm to the kitchen in real-time. 
                                </h2>
                                <div class="columns is-variable is-5">
                                    <div class="column">
                                        <a href="/farmer_signup" class="button is-large is-rounded is-warning"> 
                                            <span class="icon">
                                                <i class="fas fa-tractor"></i>
                                            </span>
                                            <span><strong>Get early access</strong></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section style = {{fontFamily: 'GothamRound'}} class="section features">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-4">
                            <h2 class="is-size-3 has-text-weight-bold">
                                <span class="icon is-large has-text-primary">
                                    <i class="fas fa-tractor"></i>
                                </span>
                                Farms
                            </h2>
                            <div class="columns is-gapless is-size-4">
                                <div class="column is-2 has-text-centered has-text-left-mobile">
                                    <span class="icon">
                                        <i class="fas fa-tasks"></i>
                                    </span>
                                </div>
                                <div class="column">
                                    <span class="has-text-weight-semibold">Manage & communicate</span><br />
                                    inventory from the field 
                                </div>
                            </div>
                            <div class="columns is-gapless is-size-4">
                                <div class="column is-2 has-text-centered has-text-left-mobile">
                                    <span class="icon">
                                        <i class="fas fa-tags"></i>
                                    </span>
                                </div>
                                <div class="column">
                                    <span class="has-text-weight-semibold">Update pricing</span><br />
                                    in real-time
                                </div>
                            </div>
                            <div class="columns is-gapless is-size-4">
                                <div class="column is-2 has-text-centered has-text-left-mobile">
                                    <span class="icon">
                                        <i class="fas fa-handshake"></i>
                                    </span>
                                </div>
                                <div class="column">
                                    <span class="has-text-weight-semibold">Connect</span><br />
                                    with new buyers
                                </div>
                            </div>
                        </div>
                        <div class="column is-4">
                            <h2 class="is-size-3 has-text-weight-bold">
                                <span class="icon is-large has-text-primary">
                                    <i class="fas fa-utensils"></i>
                                </span>
                                Restaurants
                            </h2>
                            <div class="columns is-gapless is-size-4">
                                <div class="column is-2 has-text-centered has-text-left-mobile">
                                    <span class="icon">
                                        <i class="fas fa-shopping-basket"></i>
                                    </span>
                                </div>
                                <div class="column">
                                    <span class="has-text-weight-semibold">Real-time produce</span><br />
                                    quantity and pricing
                                </div>
                            </div>
                            <div class="columns is-gapless is-size-4">
                                <div class="column is-2 has-text-centered has-text-left-mobile">
                                    <span class="icon">
                                        <i class="fas fa-shopping-cart"></i>
                                    </span>
                                </div>
                                <div class="column">
                                    <span class="has-text-weight-semibold">Browse the database</span><br />
                                    for specific items
                                </div>
                            </div>
                            <div class="columns is-gapless is-size-4">
                                <div class="column is-2 has-text-centered has-text-left-mobile">
                                    <span class="icon">
                                        <i class="fas fa-stopwatch"></i>
                                    </span>
                                </div>
                                <div class="column">
                                    <span class="has-text-weight-semibold">Expedite time</span><br />
                                    from farm to table
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section has-background-primary has-text-centered">
                <div class="columns is-centered is-mobile">
                    <div class="column is-1-desktop is-one-third-mobile">
                        <img src={whiteIcon} alt="Bon Harvest icon" />
                    </div>
                </div>
                <h2 class="is-size-3 has-text-weight-semibold has-text-white">Simplifying how growers and buyers communicate over produce.</h2>
            </section>
            <section class="section has-background-light">
                <div class="columns is-centered is-mobile">
                    <div class="column is-8 content is-medium is-size-5 has-text-weight-semibold">
                        <h3 class="is=size-3 has-text-weight-bold">Using <span class="has-text-primary">Bon Harvest</span>’s inventory management platform, farms and restaurants:</h3>
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-table"></i>
                            </span>
                            No longer need to use Excel spreadsheets & email to engage over produce inventory.
                        </p>
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-comments-dollar"></i>
                            </span>
                            Save time using our streamlined communication workflow.
                        </p>
                        <p>
                            <span class="icon is-large">
                                <i class="fas fa-seedling"></i>
                            </span>
                            Reduce their volume of food waste
                        </p>
                    </div>
                </div>
            </section>
            <section class="section is-medium has-background-dark">
                <div class="container">
                    <div class="content is-large">
                        <h2 class="has-text-white">The Story</h2>
                        <p class="has-text-grey-lighter">
                            We’re a diverse group of individuals—each of us coming from different professional, educational, generational, and geographical backgrounds—unified by, and working towards the same, two-fold mission: 1) Leverage technology to improve the lives of the hardworking, under-appreciated people who feed us, and 2) Reduce the amount of food we as a society currently waste.
                        </p>
                        <p>
                            <a href="/about" class="has-text-weight-semibold">About Bon Harvest</a>
                        </p>
                    </div>
                </div>
            </section>
            <section  class="section is-medium has-background-primary team">
                <div  class="container">
                    <div class="content is-medium">
                        <h2 class="has-text-white">Team</h2>
                    </div>
                    <div class="columns is-8 is-vcentered">
                        <div style = {{marginLeft: "5vw"}} class="column is-4">
                            <figure class="image is-square team-member is-relative">
                                <img class="is-rounded" src={teymour}/>
                                <div class="details is-overlay">
                                    <div class="container">
                                        <div class="columns is-variable is-1">
                                            <div class="column has-text-right">
                                                <span class="icon is-medium">
                                                    <a href="team#teymour"><i class="fas fa-info-circle fa-2x has-text-white"></i></a>
                                                </span>
                                            </div>
                                            <div class="column has-text-centered">
                                                <span class="icon is-medium">
                                                    <a href="https://www.linkedin.com/in/elderini/"><i class="fab fa-linkedin-in fa-2x has-text-primary"></i></a>
                                                </span>
                                            </div>
                                            <div class="column">
                                                <span class="icon is-medium">
                                                    <a href="https://www.instagram.com/derinzz/"><i class="fab fa-instagram fa-2x has-text-primary"></i></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <h3 class="is-size-4 has-text-white has-text-centered"><span class="has-text-weight-bold">Teymour</span>, CEO</h3>
                        </div>
                        <div style = {{marginLeft: "10vw"}}class="column is-4">
                            <figure class="image is-square team-member is-relative">
                                <img class="is-rounded" src={daniel}/>
                                <div class="details is-overlay">
                                    <div class="container">
                                        <div class="columns is-variable is-1">
                                            <div class="column has-text-right">
                                                <span class="icon is-medium">
                                                    <a href="team#daniel"><i class="fas fa-info-circle fa-2x has-text-white"></i></a>
                                                </span>
                                            </div>
                                            <div class="column has-text-centered">
                                                <span class="icon is-medium">
                                                    <a href="https://www.linkedin.com/in/daniel-m-zhu/"><i class="fab fa-linkedin-in fa-2x has-text-primary"></i></a>
                                                </span>
                                            </div>
                                            <div class="column">
                                                <span class="icon is-medium">
                                                    <a href="https://www.instagram.com/tonkotzhu/"><i class="fab fa-instagram fa-2x has-text-primary"></i></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                            <h3 class="is-size-4 has-text-white has-text-centered"><span class="has-text-weight-bold">Daniel</span>, CTO</h3>
                        </div>
                        <div class="column">
                        </div>
                    </div>
                </div>
            </section>
            <section class="section is-medium">
                <div class="container">
                    <div class="content is-large">
                        <h2>From The Blog</h2>
                    </div>
                    <div class="columns">
                        <div class="column">
                            <div class="card">
                                <div class="card-image">
                                    <figure class="image is-16by9">
                                        <a href="https://www.bonharvest.com/post/farm-one"><img src="https://static.wixstatic.com/media/9e9587_a3ab5204e3034ee4bdbcd041ca633f20~mv2.jpg" alt="Bon Harvest blog"/></a>
                                    </figure>
                                </div>
                                <div class="card-content">
                                    <div class="media">
                                        <h2 class="is-size-4 has-text-weight-semibold"><a href="https://www.bonharvest.com/post/farm-one">A Conversation with Farm.One About Coronavirus</a></h2>
                                    </div>
                                    <div class="content is-medium">
                                        <p>We all know that COVID-19’s impact on the food industry has been far-reaching and devastating in so many cases. Farms and restaurants around the globe are facing dramatic declines in revenue, and are increasingly being forced to shut down.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="card">
                                <div class="card-image">
                                    <figure class="image is-16by9">
                                        <a href="https://www.bonharvest.com/post/food-people-don-t-waste-this-crisis"><img src="https://static.wixstatic.com/media/9e9587_14d38eb47d7747be8c2e8cc334bad635~mv2.jpg" alt="Bon Harvest blog"/></a>
                                    </figure>
                                </div>
                                <div class="card-content">
                                    <div class="media">
                                        <h2 class="is-size-4 has-text-weight-semibold"><a href="https://www.bonharvest.com/post/food-people-don-t-waste-this-crisis">Food People, Don’t Waste This Crisis.</a></h2>
                                    </div>
                                    <div class="content is-medium">
                                        <p>As a result of the ongoing and increasingly-devastating situation stemming from the Coronavirus outbreak, many of us in the food/farming business have a lot more time away from our physical places of work…and a lot more time for self reflection.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="footer">
                <div class="container">
                    <div class="columns">
                        <div class="column is-3">
                            <div class="content">
                                <a href="#"><img src={topLogo}/></a>
                                <h4><strong>Produce</strong>: Helping farmers sell it, helping chefs buy it.</h4>
                                <p>
                                    <button onClick = {()=>this.props.history.push("/farmer_signup")}className="button is-rounded is-danger"> 
                                        <span class="icon">
                                            <i class="fas fa-tractor"></i>
                                        </span>
                                        <span><strong>Get early access</strong></span>
                                    </button>
                                </p>
                                <h4><a href="/about#ethics">Bon Harvest Ethics</a></h4>
                                <h4><a href="/about#story">Our Story</a></h4>
                                <h4><a href="/about#history">Our History</a></h4>
                                <h4><a href="/team">Team</a></h4>
                                <h4><a href="/rethink">Rethink</a></h4>
                                <h4><a href="https://blog.bonharvest.com">Blog</a></h4>
                                <h4><a href="/data-problem">The Data Problem</a></h4>
                            </div>
                        </div>
                        <div class="column">
                            <div class="content">
                                <div class="box">
                                    <article class="media">
                                        <div class="columns is-variable is-0">
                                            <div class="column">
                                                <div class="media-left">
                                                    <a href="#"><img src={officeMap} alt="Bon Harvest office map"/></a>
                                                </div>
                                            </div>
                                            <div class="column is-one-third">
                                                <div class="media-content">
                                                    <div class="content has-text-white">
                                                        <p class="has-text-weight-bold">New Lab</p>
                                                        <p>
                                                            Building 128<br></br>
                                                            19 Morris Ave<br></br>
                                                            Brooklyn, NY 11205<br></br>
                                                            USA
                                                        </p>
                                                        <p>
                                                            <span class="icon has-text-primary">
                                                                <i class="fas fa-phone"></i>
                                                            </span>
                                                            917-724-4474
                                                            <br></br>
                                                            <span class="icon has-text-primary">
                                                                <i class="fas fa-envelope"></i>
                                                            </span>
                                                            hello@bonharvest.com
                                                        </p>
                                                    </div>
                                                    <nav class="level is-mobile">
                                                        <div class="level-left">
                                                            <a class="level-item">
                                                                <span class="icon is-medium has-text-warning">
                                                                    <i class="fab fa-2x fa-facebook-f"></i>
                                                                </span>
                                                            </a>
                                                            <a class="level-item">
                                                                <span class="icon is-medium has-text-warning">
                                                                    <i class="fab fa-2x fa-twitter"></i>
                                                                </span>
                                                            </a>
                                                            <a href="https://www.instagram.com/bonharvest/" class="level-item">
                                                                <span class="icon is-medium has-text-warning">
                                                                    <i class="fab fa-2x fa-instagram"></i>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </body>
        )
    }
    render() {
        const token = localStorage.getItem('token');
        return(
            <div>
                <h2>
                    {
                        token !==null ?
                        
                        this.props.history.push('/dashboard')
                        :
                        this.getHome()
                    }
                </h2>
        </div>
        )
    }
}
export default Home;