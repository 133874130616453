import React from 'react';
import axios from 'axios';
import {
    List, Avatar, Button,
  } from 'antd';
class Transactions extends React.Component {
    state = {
        transactions : []
    }
    config = {
      headers:{
          'Authorization' : 'Token ' + localStorage.getItem('token')
      }
  }
    getStatusColor(status) {
        if(status==="Pending"){
            return "#f5d142"
        } else if(status === "Confirmed"){
            return "#18B259"
        } else if(status === "Declined") {
            return "#D12D2D"
        } else {
            return "#000000"
        }
    }
    componentWillReceiveProps(nextProps) {
      this.setState({
        transactions:nextProps.data
      })
  }
    updateTransaction(item, action){
      const transactionId = item.id
      const url = "https://api.farmersofamerica.net/api/transactions/"+transactionId+'/'
      axios.put(url, {
        id: transactionId,
        action: action,
        common_id: item.common_id

    }, this.config).then(res=>
        {
        const index = this.state.transactions.findIndex(x=>x.id === transactionId)
        var transactions = this.state.transactions
        var transaction = transactions[index]
        transaction.status = action
        transactions[index] = transaction
        this.setState({
          transactions: transactions
        })
    }).catch(
      err=>{
        const status = err.response.status
        if(status == 400){
            console.log(err.response.data)
        }
      }
    )
    }

    formatDate(date){
      if(date == null){
        return date
      }
      const dates = {
        "01": "Jan",
        "02": "Feb",
        "03": "Mar",
        "04": "Apr",
        "05": "May",
        "06": "Jun",
        "07": "Jul",
        "08": "Aug",
        "09": "Sep",
        "10": "Oct",
        "11": "Nov",
        "12": "Dec",
      }
      const values = date.substring(0,10).split("-")
      const year = values[0]
      const month = dates[values[1]]
      const day = values[2]
      return month+" "+day+", "+year
    }
    render(){
        return (
            <div className="transactionList">
                <List
                  className = "transactionListItem"
                  dataSource={this.state.transactions}
                  renderItem={item => (
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        title={<h2 className = "transactionListItemTitle">Order from {item.other_name}</h2>}
                        description={<p className="transactionListItemDescription">Purchase date: {this.formatDate(item.purchase_date)} ‎‎‎‎
                        Total: ${Number.parseFloat(item.total_price).toFixed(2)}
                        <p style ={{marginTop: "1vh"}}>Status: <span style={{ color:this.getStatusColor(item.status)}}>{item.status}</span></p>

                        
                        </p>}
                      />
                      <button className="button">
                      <a href={`/orders/${item.id}`}>View Order</a>
                      </button>
                      {
                          localStorage.getItem('is_farmer') === 'true' && item.status ==="Pending"?
                          <div>
                            <button onClick = {()=>{this.updateTransaction(item,"Confirmed")}} style={{'marginLeft':'1vw'}} class="button is-success">
                              Confirm Order
                          </button>
                          <button onClick = {()=>{this.updateTransaction(item,"Declined")}}style={{'marginLeft':'1vw'}} class="button is-danger">
                              Decline Order
                          </button>
                          </div>
                          :
                            null
                      }
      
                    </List.Item>
                  )}
                >
                </List>
            </div>
          );
    }
}
export default Transactions;