import React from 'react';
import CartItem from '../components/CartItem';
import {Button, Popconfirm, message} from 'antd';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
class CartView extends React.Component {
    state = {
        items : []
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentDidMount(){
        const url = 'https://api.farmersofamerica.net/api/cart/'
        axios.get(url,this.config)
            .then(res =>{
                this.setState({
                    items: res.data
                })
        })

    }
    updateState = (id) => {
        var copy = [...this.state.items]
        var index = -1
        for(let i = 0; i<copy.length; i++){
            if(copy[i]["id"] === id){
                index = i
                break 
            }
        }
        if(index===-1){
            return
        }
        else{
            copy.splice(index,1)
            this.setState({items: copy})
            console.log("the copy", copy)
        }
    }
    emptyCart(){
        const url = "https://api.farmersofamerica.net/api/cart/0/"
        axios.delete(url,this.config).then(res=>{
            this.setState({items: []}, ()=>{
                message.success('Cart has been emptied');
            })
        })
        this.props.updateCart(1.5)
    }
    render(){
        const token = localStorage.getItem('token');
        if(token===null){
            this.props.history.push("/")
        }
        return (
            <div>
                    <nav class="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li class="is-active">
                                <a href="#">
                                    <span class="icon">
                                        <i style = {{fontSize: 25}} class="fas fa-shopping-cart" aria-hidden="true"></i>
                                    </span>
                                    <span style = {{fontSize: 25}}>Your cart</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                <CartItem data = {this.state.items} delete = {this.updateState} updateCart = {this.props.updateCart}/>
                {this.state.items.length > 0 ? 
                [<NavLink to ='review'>  
                <button class = "button" >
                    Review Purchase
                </button>
                </NavLink>,
                <Popconfirm placement="bottomRight" title={"Are you sure you want to empty your cart?"} onConfirm={()=>this.emptyCart()} okText="Yes" cancelText="No">
                <button class = "button" style={{marginLeft: 30}}>
                   Empty Cart
                </button>
              </Popconfirm>]
              :
              null
                }
            </div>
        );
    }
}
export default CartView;