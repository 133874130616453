import React from 'react';
import {
    Popconfirm, List, Form, Input, Button, InputNumber
} from 'antd';

class ItemEditForm extends React.Component {
    state = {
        items: []
    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    timeout = 0
    delayUpdate = function(e, id, func){
        let value = e.target.value
        clearTimeout(this.timeout)
        this.timeout = setTimeout(function(){
            func(value,id)
        },500)
    }
    render() {
        return (
            <div className="itemEdit">
                <b style={{
                    marginLeft: "35vw"
                }}>Edit Items</b>
                <List
                    dataSource={this.props.data}
                    renderItem={item => (
                        <List.Item key={item.id}>
                            <Form layout='inline'>
                                <Form.Item
                                    label="Unit Quantity">
                                    <Input onChange={(e)=>this.props.updateQuantity(e,item.id)} value={item.unit_quantity || ''} autoComplete="off" style={{ width: "7w" }} name="name" placeholder="Ex: Organic Apple" />
                                </Form.Item>
                                <Form.Item
                                    label="Unit of Measurement">
                                    <Input onChange={(e)=>this.props.updateMeasurement(e,item.id)} 
                                    value={item.unit_of_measurement || ''} 
                                    autoComplete="off" 
                                    style={{ width: "7vw" }} 
                                    name="description" 
                                    placeholder="Ex: Juicy organic apples!" />
                                </Form.Item>
                                <Form.Item
                                    label="Price">
                                    <InputNumber
                                        style={{ width: "100px" }}
                                        value={item.price || ''}
                                        name="price"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        onChange = {(e)=>this.props.updatePrice(e,item.id)}
                                    />
                                </Form.Item>

                                <Form.Item label="Stock">
                                    <InputNumber 
                                    key="1" 
                                    style={{ width: "60px" }} 
                                    min={0} 
                                    max={999} 
                                    value={item.amount_in_stock} 
                                    onChange={(e)=>this.props.updateStock(e,item.id)} />,
                                </Form.Item>
                                <Form.Item>
                                    <Popconfirm 
                                    placement="topRight" 
                                    title={item.id} 
                                    onConfirm={(e)=>this.props.delete(item.id)} okText="Yes" cancelText="No">
                                        <Button>Delete</Button>
                                    </Popconfirm>
                                </Form.Item>
                            </Form>

                        </List.Item>
                    )}
                >
                <div>
                    <Button 
                    onClick={this.props.addEmptyItem}
                    style = {{marginTop: "2vw"}}>
                    Add Item
                    </Button>
                    </div>
                </List>


            </div>
        );
    }
}

export default ItemEditForm;
