import React from 'react'
import Inventory from '../components/Inventory'
import axios from 'axios';
import QueryString from 'query-string'
import { v4 as uuidv4 } from 'uuid';
import { Input, message, Popconfirm, AutoComplete } from 'antd';
class InventoryContainer extends React.Component {
    state = {
        dropdown: "dropdown",
        products: [],
        inven_num: "0",
        inventory_owner: "My",
        inventory_name: "",
        inventory_names: ["Main Inventory"],
        inventory_name_id: "",
        temp_inven_name: "",
        is_owner: true,
        clicked: false,
        edit_name: false,
        modal: "modal",
        editModal: "modal",
        fileName: "No file uploaded",
        fileRef: React.createRef(),
        editfileRef: React.createRef(),
        file: null,
        name: "",
        type: "Vegetable",
        maxAllowedOrder: [""],
        optionMaxQuantity: [""],
        totalQuantity: "",
        description: "",
        totalUnit: "lbs",
        optionQuantity: [""],
        optionUnit: ["lbs"],
        optionPrice: [""],
        optionInStock: [""],
        lines: 1,
        productId: "",
        product: null,
        items: [],
        temporaryProduct: {
            items: []
        },
        tempLines: 0,
        toDelete: [],
        toEditVisibility: {},
        visibility: false,
    }
    dataSource = [
        "Artichoke",
        "Arugula",
        "Asparagus",
        "Avocado",
        "Bamboo Shoots",
        "Bean Sprouts",
        "Beans",
        "Beet",
        "Belgian Endive",
        "Bell Pepper",
        "Bitter Melon",
        "Bok Choy",
        "Broccoli",
        "Brussels Sprouts",
        "Burdock Root",
        "Cabbage",
        "Calabash",
        "Capers",
        "Carrot",
        "Cassava/Yuca",
        "Cauliflower",
        "Celery",
        "Celery Root",
        "Celtuce",
        "Chayote",
        "Chinese Broccoli",
        "Corn",
        "Baby Corn",
        "Cucumber",
        "Daikon Radish",
        "Edamame",
        "Eggplant",
        "Elephant Garlic",
        "Endive",
        "Fennel",
        "Fiddlehead",
        "Galangal",
        "Garlic",
        "Ginger",
        "Grape Leaves",
        "Green Beans",
        "Wax Beans",
        "Greens",
        "Amaranth Leaves",
        "Beet Greens",
        "Collard Greens",
        "Dandelion Greens",
        "Kale",
        "Kohlrabi Greens",
        "Mustard Greens",
        "Rapini",
        "Spinach",
        "Swiss Chard",
        "Turnip Greens",
        "Hearts of Palm",
        "Horseradish",
        "Jerusalem Artichoke",
        "Jícama",
        "Curly",
        "Lacinato",
        "Ornamental",
        "Kohlrabi",
        "Leeks",
        "Lemongrass",
        "Lettuce",
        "Lotus Root",
        "Lotus Seed",
        "Mushrooms",
        "Napa Cabbage",
        "Nopales",
        "Okra",
        "Olive",
        "Onion",
        "Green Onions",
        "Parsley",
        "Parsley Root",
        "Parsnip",
        "Peas",
        "Peppers",
        "Plantain",
        "Potato",
        "Pumpkin",
        "Purslane",
        "Radicchio",
        "Radish",
        "Rutabaga",
        "Sea Vegetables",
        "Shallots",
        "Squash",
        "Sweet Potato",
        "Taro",
        "Tomatillo",
        "Tomato",
        "Turnip",
        "Water Chestnut",
        "Water Spinach",
        "Watercress",
        "Winter Melon",
        "Yams",
        "Zucchini",
    ]
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
        }
    }
    changeInventory(e){
        const name = this.state.inventory_names.find(obj => obj.id === e).name
        if(e !== this.state.inven_num){
            this.setState({inven_num:e})
            const config = {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token')
                }
            }

            this.url = 'https://api.farmersofamerica.net/api/inventory/?inven_name=' + e
            
            axios.get(this.url, config)
                .then(res => {
                    this.setState({
                        edit_name: false,
                        inventory_name_id: e,
                        inventory_name: name,
                        products: res.data
                    })
                })
        }
    }
    param1 = this.props.match.params.param1
    url = ""
    editCard = this.editCard.bind(this);
    deleteProduct = this.deleteProduct.bind(this);
    enableImageModal = this.enableImageModal.bind(this);
    checkBox = this.checkBox.bind(this);

    toggleDropdown(){
        if(this.state.dropdown === "dropdown"){
            this.setState({dropdown: "dropdown is-active"})
        } else{
            this.setState({dropdown: "dropdown"})
        }
    }
    cancel() {
        this.setState({
            editModal: "modal",
            modal: "modal",
            fileName: "No file uploaded",
            file: null,
            clicked: false

        })
        document.getElementById('productForm').reset();
    }
    addNewLine() {
        this.setState({
            lines: this.state.lines + 1,
            optionQuantity: this.state.optionQuantity.concat(0),
            optionPrice: this.state.optionPrice.concat(0),
            optionUnit: this.state.optionUnit.concat("lbs"),
            optionInStock: this.state.optionInStock.concat(0),
            maxAllowedOrder: this.state.maxAllowedOrder.concat(0)

        })
    }
    checkBox(item){
        const id = item.id
        const new_visibility = item.visible
        const temp = this.state.toEditVisibility
        if(temp.hasOwnProperty(id)){
            delete temp[id]
        } else{
            temp[id] = new_visibility
        }
        this.setState({toEditVisibility: temp})
    }
    saveChangesVisibility(){
        if(Object.keys(this.state.toEditVisibility).length !== 0){
            axios.post('https://api.farmersofamerica.net/api/update_visible/', this.state.toEditVisibility, this.config)
            .then(res=>{
                message.success("Updated visbililty", 0.5)
                }
            )
        }
        this.cancelVisible()

    }
    cancelVisible(){
        this.setState({
            visibility: false,
            toEditVisibility: {}
        })
    }
    addItemNewLine() {
        var temp = this.state.temporaryProduct
        const item = {
            id: "addme" + this.state.tempLines + 1,
            price: 0,
            product_name: temp.name,
            unit_of_measurement: "lbs",
            max_allowed_order: 0,
            unit_quantity: 0,
            amount_in_stock: 0
        }
        temp.items.push(item)
        this.setState({ temporaryProduct: temp, tempLines: this.state.tempLines + 1 })
    }
    handleUpload() {
        const files = this.state.fileRef.current.files
        if (files.length > 0) {
            const fileName = files[0].name
            this.setState({ fileName: fileName, file: files[0] })
        }
    }
    handleEditUpload() {
        const files = this.state.editfileRef.current.files
        if (files.length > 0) {
            const fileName = files[0].name
            var temp = this.state.temporaryProduct
            temp["fileName"] = fileName
            temp["file"] = files[0]
            this.setState({ temporaryProduct: temp })
        }
    }
    componentWillReceiveProps() {
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        const params = QueryString.parse(this.props.location.search)
        const path = this.props.history.location.pathname
        var num = "0"
        if ("inven_num" in params) {
            num = params.inven_num
            this.setState({
                inven_num: params.inven_num
            })
        }
        this.url = 'https://api.farmersofamerica.net/api/inventory/?inven_num=' + num
        
        axios.get(this.url, config)
            .then(res => {
                this.setState({
                    products: res.data
                })
            })
    }
    addProduct() {
        this.setState({
            modal: "modal is-active",
            lines: 1,
            optionQuantity: [0],
            optionPrice: [0.00],
            optionUnit: ["lbs"],
            totalQuantity: 0,
            optionInStock: [0],
            optionMaxQuantity: [""],
            totalUnit: "lbs",
            name: "",
        })
    }
    removePhoto() {
        var temp = this.state.temporaryProduct
        temp["fileName"] = null
        temp["image_url"] = ""
        temp['file'] = null
        this.setState({ temporaryProduct: temp })

    }
    removeAddPhoto() {
        this.setState({
            fileName: "No file uploaded",
            file: null
        })

    }
    handleOptionChange(event, targetField) {
        var toUpdate = this.state[targetField]
        toUpdate[event.target.id] = event.target.value
        this.setState({
            [targetField]: toUpdate
        })
    }
    removeTempLine(e) {
        var temp = this.state.temporaryProduct
        var items = temp.items
        const index = items.findIndex(x => x.id === e.target.id)
        items.splice(index, 1)
        temp.items = items
        if (e.target.id.length > 10) {
            this.setState({
                toDelete: [...this.state.toDelete, e.target.id]
            })
        }
        this.setState({ temporaryProduct: temp })
    }
    removeLine(e) {
        const i = e.target.id
        var q = [...this.state.optionQuantity]
        var p = [...this.state.optionPrice]
        var u = [...this.state.optionUnit]
        q.splice(i, 1)
        p.splice(i, 1)
        u.splice(i, 1)
        this.setState({
            optionQuantity: q,
            optionPrice: p,
            optionUnit: u,
            lines: this.state.lines - 1
        })
    }
    handleTempOptionChange(event, targetField) {
        var value = event.target.value
        if (targetField === "unit_of_measurement") {
            value = value.toLowerCase()
        }
        else {
            value = +value
        }
        const itemId = event.target.id
        var temp = this.state.temporaryProduct
        var items = temp.items
        var item = items.find(x => x.id === itemId);
        item[targetField] = value
        temp.items = items
        this.setState({
            temporaryProduct: temp
        })

    }
    enableImageModal() {
        this.setState({
            imageModal: true
        })
    }
    disableImageModal() {
        this.setState({
            imageModal: false
        })
    }
    getImageModal(product) {
        if (!this.state.imageModal) {
            return null
        }
        return (
            <div class="modal">
                <div class="modal-background"></div>
                <div class="modal-content">
                    <p class="image is-4by3">
                        <img src={product.image_url} alt="" />
                    </p>
                </div>
                <button class="modal-close is-large" aria-label="close"></button>
            </div>
        )
    }
    getItemLines() {
        const items = this.state.temporaryProduct.items
        console.log("items yee", items)
        const item_lines = items.map(
            item =>
                (<div style={{ marginTop: -20, marginBottom: 30 }} class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <p class="control is-expanded">
                                <input value={item.amount_in_stock.toString().replace(/^(?!0$)0+/, '')} id={item.id} onChange={(e) => { this.handleTempOptionChange(e, "amount_in_stock") }} class="input" type="number" min="0.00" placeholder="In stock" />
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input value={item.max_allowed_order.toString().replace(/^(?!0$)0+/, '')} id={item.id} onChange={(e) => { this.handleTempOptionChange(e, "max_allowed_order") }} class="input" type="number" min="0.00" placeholder="Max Order" />
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input value={item.unit_quantity.toString().replace(/^(?!0$)0+/, '')} id={item.id} onChange={(e) => { this.handleTempOptionChange(e, "unit_quantity") }} class="input" type="number" min="0.00" placeholder="Quantity" />
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input value={item.unit_of_measurement} id={item.id} onChange={(e) => { this.handleTempOptionChange(e, "unit_of_measurement") }} class="input" type="text" placeholder="Unit" />
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input value={item.price.toString().replace(/^(?!0$)0+/, '')} id={item.id} onChange={(e) => { this.handleTempOptionChange(e, "price") }} class="input" type="number" step="0.01" min="0.00" placeholder="Asking price" />
                            </p>
                        </div>
                        <button id={item.id} style={{ marginTop: "1vh" }} type="button" onClick={(e) => this.removeTempLine(e)} class="delete" aria-label="close"></button>
                    </div>
                </div>)
        )
        return item_lines

    }
    addLine() {
        var toReturn = []
        for (var i = 0; i < this.state.lines; i++) {
            toReturn.push(
                <div style = {{marginTop: -20, marginBottom: 30}} key = {i} class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <p class="control is-expanded">
                                <input autoComplete="off" id={i} onChange={(e) => { this.handleOptionChange(e, "optionInStock") }} class="input" type="number" min="0.00" placeholder="In stock" />

                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input autoComplete="off" id={i} onChange={(e) => { this.handleOptionChange(e, "optionMaxQuantity") }} class="input" type="number" min="0.00" placeholder="Max Order" />

                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input autoComplete="off" id={i} onChange={(e) => { this.handleOptionChange(e, "optionQuantity") }} class="input" type="number" min="0.00" placeholder="Quantity" />
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input autoComplete="off" id={i} onChange={(e) => { this.handleOptionChange(e, "optionUnit") }} class="input" type="text" placeholder="Unit" />
                            </p>
                        </div>
                        <div class="field">
                            <p class="control is-expanded">
                                <input autoComplete="off" id={i} onChange={(e) => { this.handleOptionChange(e, "optionPrice") }} class="input" type="number" step="0.01" min="0.00" placeholder="Asking price" />
                            </p>
                        </div>
                        <button id={i} style={{ marginTop: "1vh" }} type="button" onClick={(e) => this.removeLine(e)} class="delete" aria-label="close"></button>
                    </div>
                </div>)
        }
        return toReturn
    }
    editCard(product) {
        this.setState(
            {
                editModal: 'modal is-active',
                product: product,
                items: product.items,
                temporaryProduct:
                    JSON.parse(JSON.stringify(product)),
                tempLines: product.items.length,
                toDelete: []
            }
        )
    }
    handleEdit(value, field) {
        var temp = this.state.temporaryProduct
        temp[field] = value
        this.setState({ temporaryProduct: temp })
    }
    getEditForm() {
        var product = this.state.product
        if (product === null) {
            return null
        }
        var fileName = "No file uploaded"
        if (this.state.temporaryProduct.fileName != null) {
            fileName = this.state.temporaryProduct.fileName
            if (fileName.length > 13) {
                fileName = fileName.substring(0, 13) + "..."
            }
        } else if (this.state.temporaryProduct.image_url.length > 0) {
            const imageUrl = this.state.temporaryProduct.image_url.split("/")
            fileName = imageUrl[imageUrl.length - 1]
            if (fileName.length > 13) {
                fileName = fileName.substring(0, 13) + "..."
            }
        }
        return (
            <div class={this.state.editModal}>
                <form>
                    <div class="modal-background"></div>
                    <div style = {{width: 800}}  class="modal-card">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Edit Product</p>
                            <button type="button" onClick={() => this.cancel()} class="delete" aria-label="close"></button>
                        </header>
                        <section class="modal-card-body">
                            <div class="field is-horizontal">
                                <div class="field-body">
                                    <div class="field">
                                        <div class="control">
                                            <input style={{width: 400 }} value={this.state.temporaryProduct.name} onChange={(e) => this.handleEdit(e.target.value, "name")} class="input is-medium" type="text" placeholder="Product" />

                                        </div>
                                    </div>
                                    <fieldset>
                                        <div class="field">
                                            <div class="control">
                                                <div class="select is-medium">
                                                    <select style = {{width: 347}} onChange={(e) => { this.handleEdit(e.target.value, "category") }}>
                                                        <option selected={this.state.temporaryProduct.category === "Vegetable"}>Vegetable</option>
                                                        <option selected={this.state.temporaryProduct.category === "Fruit"}>Fruit</option>
                                                        <option selected={this.state.temporaryProduct.category === "Dairy & Grain"}>Dairy &amp; Grain</option>
                                                        <option selected={this.state.temporaryProduct.category === "Poultry & Livestock"}>Poultry &amp; Livestock</option>
                                                        <option selected={this.state.temporaryProduct.category === "Beverage & Canned"}>Beverage &amp; Canned</option>
                                                        <option selected={this.state.temporaryProduct.category === "Other"}>Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="field is-horizontal">
                                <div class="field-body">
                                    <div class="field">
                                        <p class="control">
                                            <input style={{ width: 400 }} value={this.state.temporaryProduct.total_quantity} onChange={(e) => { this.handleEdit(e.target.value, "total_quantity") }} class="input is-medium" type="number" placeholder="Total Quantity" />
                                        </p>
                                    </div>
                                    <div class="field">
                                        <div class="control">
                                            <div class="select is-medium">
                                                <select style={{ width: 347 }} onChange={(e) => { this.handleEdit(e.target.value, "total_unit") }}>
                                                    <option selected={this.state.temporaryProduct.total_unit === "lbs"}>lbs</option>
                                                    <option selected={this.state.temporaryProduct.total_unit === "oz"}>oz</option>
                                                    <option selected={this.state.temporaryProduct.total_unit === "kg"}>kg</option>
                                                    <option selected={this.state.temporaryProduct.total_unit === "gal"}>gal</option>
                                                    <option selected={this.state.temporaryProduct.total_unit === "L"}>L</option>
                                                    <option selected={this.state.temporaryProduct.total_unit === "units"}>units</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="field is-horisontal">
                            <div class="field-body">
                                    <div class="field">
                                        <textarea onChange = {(e) => this.handleEdit(e.target.value, "description")} class="textarea" placeholder="Enter a product description" value={this.state.temporaryProduct.description}></textarea>
                                    </div>
                                </div>
                            </div>
                            <label class="label is-large">Pricing options</label>
                            <div class="columns">
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1>
                                        In stock
                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -12 }}>
                                        Max Allowed Order
                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -20 }}>
                                        Quantity
                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -22 }}>
                                        Unit
                                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -30 }}>
                                        Price
                                                    </h1>
                                </div>
                            </div>
                            {this.getItemLines()}
                            <div class="field is-horizontal">
                                <div class="column is-5">
                                    <button style={{ marginTop: -20, marginLeft: -15, width: 130 }} type="button" onClick={() => this.addItemNewLine()} class="button is-small is-primary is-light">
                                        <span class="icon is-small">
                                            <i class="fas fa-plus"></i>
                                        </span>
                                        <span>another option</span>
                                    </button>
                                </div>
                            </div>

                        </section>
                        <footer style={{ marginTop: -20, fontSize: 14 }} className="modal-card-foot">
                            {
                                !this.state.clicked ?
                            
                            <button style={{ fontFamily: 'GothamRound' }} type="button" onClick={() => this.handleSubmit("put")} className="button is-success">Save changes</button>
                            :
                            <button class="button is-primary is-loading">Save Changes</button>
                            }
                            <button style={{ fontFamily: 'GothamRound' }} type="button" onClick={() => this.cancel()} className="button">Cancel</button>
                            <div style={{ "marginLeft": "2vw" }} class="field">
                                <div class="file is-warning has-name">
                                    <label style={{ marginLeft: -5 }} class="file-label">
                                        <input onChange={(e) => { this.handleEditUpload() }} class="file-input" type="file" name="resume" ref={this.state.editfileRef} />
                                        <span class="file-cta">
                                            <span class="file-icon">
                                                <i class="fas fa-upload"></i>
                                            </span>
                                            <span class="file-label">
                                                Change photo
                                            </span>
                                        </span>
                                        <span class="file-name">
                                            {fileName}
                                        </span>
                                    </label>
                                    {fileName !== "No file uploaded" ? <button style={{ marginTop: "1vh", "marginLeft": "1vw" }} type="button" onClick={() => this.removePhoto()} class="delete" aria-label="close"></button>
                                        :
                                        null}

                                </div>

                            </div>

                        </footer>
                    </div>
                </form>
            </div>
        )
    }
    componentDidMount() {
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        const isFarmer = localStorage.getItem('is_farmer')
        if(isFarmer === "true"){
            const inven_url = 'https://api.farmersofamerica.net/api/inventorynames'
            axios.get(inven_url, config).then(
                res=>{
                    const names = res.data
                    this.setState({inventory_names: names, inventory_name: names[0].name, inventory_name_id: names[0].id})
                }
            )
        }
        const params = QueryString.parse(this.props.location.search)
        var num = "0"
        if ("inven_num" in params) {
            num = params.inven_num
            this.setState({
                inven_num: params.inven_num
            })
        }
        var userId = ""
        if (this.param1 != null) {
            userId = this.param1
        }

        if (userId === "") {
            this.url = 'https://api.farmersofamerica.net/api/inventory/'
        }
        else {
            this.url = 'https://api.farmersofamerica.net/api/inventory/' + userId
            this.setState({
                is_owner: false
            })
        }
        axios.get(this.url, config)
            .then(res => {
                this.setState({
                    products: res.data
                })
                if (res.data.length > 0) {
                    if (!res.data[0].is_owner) {
                        this.setState({
                            inventory_owner: res.data[0].user_name,
                            is_owner: res.data[0].is_owner
                        })
                    }
                }
            })
            .catch(res => {
                this.props.history.push('/')

            })
        if (this.userId != null) {
            this.url = 'https://api.farmersofamerica.net/api/profile/' + userId
            axios.get(this.url, config)
                .then(res => {
                    this.setState({
                        inventory_owner: res.data.username
                    })
                })
        }
    }
    updateState = (data) => {
        this.setState({ products: [...this.state.products, data] })
    }
    onSelect = (value) => {
        this.setState({
            name: value
        })
    }
    onSearch = (value) => {
        this.setState({
            name: value
        })
    }
    createNewInventory = () => {
        console.log("state", this.state)
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        message.loading("Cloning main inventory...")
        axios.post('https://api.farmersofamerica.net/api/inventory/create/', { inventory_name: this.state.inventory_name }, config)
            .then(res => {
                console.log("res ",res)
                axios.get('https://api.farmersofamerica.net/api/inventory/?inven_name=' + this.state.inventory_name_id, config)
                    .then(res2 => {
                        this.setState({
                            products: res2.data
                        })
                        message.destroy()
                        message.success("Cloned main inventory!", 0.5)
                    })
            })
    }
    async handleSubmit(requestType) {
        console.log("state", this.state)
        if(this.state.clicked){
            return
        }
        this.setState({clicked: true})
        switch (requestType) {
            case 'post': {
                const name = this.state.name
                const description = this.state.description
                const category = this.state.type
                const total_quantity = this.state.totalQuantity
                const total_unit = this.state.totalUnit
                const file = this.state.file
                const inventory_name = this.state.inventory_name
                if(name.length === 0 || total_quantity === 0){
                    this.cancel()
                    return
                }
                if (file !== null) {
                    const fileName = this.state.fileName
                    var path = "https://bonharvest-product-photos.s3.amazonaws.com/product-photos/" + uuidv4() + "/" + fileName
                } else {
                    var path = ""
                }
                var formData = new FormData()
                formData.append("name", name)
                formData.append("description", description)
                formData.append("category", category)
                formData.append("total_quantity", total_quantity)
                formData.append("total_unit", total_unit)
                formData.append("path", path)
                formData.append("file", file)
                formData.append("inventory_name", inventory_name )
                let res = await axios.post('https://api.farmersofamerica.net/api/products/', formData, this.config)
                const productId = res.data.id
                var product = {
                    id: productId,
                    description: description,
                    is_owner: true,
                    total_quantity: total_quantity,
                    total_unit: total_unit,
                    category: category,
                    name: name,
                    items: [],
                    visible: true,
                    image_url: res.data.image_url
                }
                if (this.state.lines > 0) {
                    for (let i = 0; i < this.state.lines; i++) {
                        let price = this.state.optionPrice[i]
                        price = Number(price).toFixed(2)
                        let unit = this.state.optionUnit[i]
                        let inStock = this.state.optionInStock[i]
                        let maxAllowedOrder = this.state.optionMaxQuantity[i]
                        if(maxAllowedOrder === "" || maxAllowedOrder === undefined || maxAllowedOrder === "0"){
                            maxAllowedOrder = inStock
                        }
                        let quantity = this.state.optionQuantity[i]
                        let res = await axios.post('https://api.farmersofamerica.net/api/items/', {
                            product: productId,
                            unit_quantity: quantity,
                            amount_in_stock: inStock,
                            unit_of_measurement: unit,
                            price: price,
                            max_allowed_order: maxAllowedOrder

                        }, this.config
                        )
                        let itemId = res.data.id
                        let item = {
                            id: itemId,
                            old_price: 0,
                            price: price,
                            amount_in_stock: inStock,
                            unit_of_measurement: unit,
                            unit_quantity: quantity,
                            product_name: name,
                            max_allowed_order: maxAllowedOrder
                        }
                        product.items.push(item)
                    }
                }
                this.setState({
                    products: [...this.state.products, product]
                })
                this.cancel()
            }
                break

            case 'put': {
                const name = this.state.temporaryProduct.name
                const productId = this.state.temporaryProduct.id
                const description = this.state.temporaryProduct.description
                const category = this.state.temporaryProduct.category
                const total_quantity = this.state.temporaryProduct.total_quantity
                const total_unit = this.state.temporaryProduct.total_unit
                const file = this.state.temporaryProduct.file
                if (file != null) {
                    const fileName = this.state.temporaryProduct.fileName
                    var path = "https://bonharvest-product-photos.s3.amazonaws.com/product-photos/" + uuidv4() + "/" + fileName
                } else {
                    var path = ""
                }
                var formData = new FormData()
                formData.append("name", name)
                formData.append("description", description)
                formData.append("category", category)
                formData.append("total_quantity", total_quantity)
                formData.append("total_unit", total_unit)
                formData.append("path", path)
                formData.append("file", file)
                axios.put(`https://api.farmersofamerica.net/api/products/${this.state.temporaryProduct.id}/`, formData, this.config)
                    .then(res => {
                        for (let item of this.state.temporaryProduct.items) {
                            if (item.id.length < 10) {
                                axios.post('https://api.farmersofamerica.net/api/items/', {
                                    product: productId,
                                    unit_quantity: item.unit_quantity,
                                    amount_in_stock: item.amount_in_stock,
                                    unit_of_measurement: item.unit_of_measurement,
                                    price: item.price,
                                    max_allowed_order: item.max_allowed_order

                                }, this.config)
                            } else {
                                const itemId = item.id
                                axios.put(`https://api.farmersofamerica.net/api/items/${itemId}/`, {
                                    unit_quantity: item.unit_quantity,
                                    amount_in_stock: item.amount_in_stock,
                                    unit_of_measurement: item.unit_of_measurement,
                                    max_allowed_order: item.max_allowed_order,
                                    price: item.price
                                }, this.config)

                            }

                        }
                        for (let item of this.state.toDelete) {
                            let deleteUrl = `https://api.farmersofamerica.net/api/items/${item}/`
                            axios.delete(deleteUrl, this.config)
                        }
                        var toChange = this.state.products
                        const index = toChange.findIndex(x => x.id === productId)
                        const temp = this.state.temporaryProduct
                        this.state.temporaryProduct.image_url = path
                        this.setState({
                            temporaryProduct: temp
                        })
                        toChange[index] = temp
                        this.setState({
                            products: toChange
                        })
                    })
                    .catch(err => console.log(err));
                break
            }
            default:
                console.log("this shouldn't happen not put or post in ProductForm.js")
        }
        this.cancel()
    }
    deleteInventory = () => {
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        if (this.param1 == null) {
            message.error('Error: Cannot delete Main Inventory.')
        }
        else {
            axios.delete('https://api.farmersofamerica.net/api/inventory/delete/' + this.param1 + "/", config)
                .then(res => {
                    message.success("Success: Deleted Inventory " + this.param1, 0.75)
                    this.setState({
                        products: []
                    })
                })
                .catch(res => {
                    message.error("Error: Unable to delete Inventory " + this.param1)
                })
        }
    }
    deleteProduct(product) {
        const index = this.state.products.findIndex(x => x.id === product.id)
        var productArray = [...this.state.products]
        productArray.splice(index, 1)
        this.setState({ products: productArray })
        const promises = []
        const url = "https://api.farmersofamerica.net/api/products/" + product.id + "/"
        axios.delete(url, this.config).then(res => promises.push(res))
        message.info(product.name + ' succesfully removed', 0.75);
    }
    getSelectInventorys(){
        const names = this.state.inventory_names
        if(names.length === 0){
            return []
        } 
        const toReturn = []
        for(const name of names){
            toReturn.push(
                <option value={name.id}>{name.name}</option>
            )
        }
        return toReturn
    }
    getTopInventoryName(){
        const isFarmer = localStorage.getItem('is_farmer')
        if(isFarmer === "true"){
            return this.state.inventory_name
        }
        return `${this.state.inventory_owner}'s Inventory`
    }

    toggleEditName(){
        this.setState({temp_inven_name: "", edit_name: !this.state.edit_name})
    }
    changeInventoryName(){
        console.log("are rewrwerwer")
        const config = {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
        const temp_inven_name = this.state.temp_inven_name
        if(temp_inven_name === "" || temp_inven_name === this.state.inventory_name){
            this.setState({edit_name: false})
            return
        }
        const old_name = this.state.inventory_name
        const old_id = this.state.inventory_names.find(name => name.name===this.state.inventory_name).id
        const url = `https://api.farmersofamerica.net/api/inventorynames/${old_id}/`
        axios.put(url, {new: temp_inven_name, id: old_id}, config).then(
            res=>{
                const index = this.state.inventory_names.findIndex(obj=> obj.id===this.state.inventory_name_id)
                const temp = this.state.inventory_names
                temp[index].name = temp_inven_name
                console.log("windex", index)
                this.setState({inventory_name: temp_inven_name, inventory_names: temp})
                console.log(this.state.inventory_names)
            }
        )
        console.log("in change", temp_inven_name, old_id)
        this.setState({
            edit_name: false,
            temp_inven_name: ""
        })
        
    }
    getEditInventoryNameButton(isFarmerBool){
        if(isFarmerBool==="true"){
            return  (<button style = {{marginLeft: 10, width: 30, height: 30 }}onClick = {()=>{this.toggleEditName()}} class="button is-white"><i class="fas fa-edit"></i></button> )
        } else{
            return null
        }
    }
    render() {
        const isFarmer = localStorage.getItem('is_farmer')
        return (
            <div style={{ fontFamily: 'GothamRound' }}>
                <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                        <li className="is-active">
                        <span className="icon">
                                    <i style = {{fontSize: 25}}className="fas fa-store" aria-hidden="true"></i>
                                </span>
                                {this.state.edit_name? [
                                <input onChange={(e) => this.setState({ temp_inven_name: e.target.value })} style = {{height: 30, width: 200}} defaultValue = {this.state.inventory_name} class="input"></input>,
                                <button style = {{marginLeft: 10, width: 30, height: 30 }}onClick = {()=>{this.changeInventoryName()}} class="button is-white"><i class="fas fa-check"></i></button>,
                                <button style = {{marginLeft: 5, width: 30, height: 30 }}onClick = {()=>{this.toggleEditName()}} class="button is-white"><i class="fas fa-times"></i></button> ]
                                
                            :
                            [<span style = {{fontSize: 25}}>{
                                this.getTopInventoryName()}</span>,
                                this.getEditInventoryNameButton(isFarmer)
                            ]
                            }
                            
                        </li>
                    </ul>
                </nav>
                <div className="container">
                    {isFarmer === "true" ? 
                <div style = {{marginBottom: 10}} class="select is-primary">
                    <select onChange = {(e)=>{this.changeInventory(e.target.value)}} >
                        {this.getSelectInventorys()}
                    </select>
                </div>
                :
                null
        }
                    <div className="columns is-variable is-4">
                        <div className="column">
                            <div className="columns is-marginless">
                                {this.state.is_owner ?
                                    <div style={{ marginTop: -60 }} className="column has-text-right is-paddingless">
                                        <a onClick={() => { this.addProduct() }}>
                                            <span className="icon is-large">
                                                <i className="fas fa-3x fa-plus-circle has-text-primary"></i>
                                            </span>
                                        </a>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="content">
                                <Inventory updateCart = {this.props.updateCart} checkBox = {this.checkBox} visibility = {this.state.visibility} data={this.state.products} editCard={this.editCard} delete={this.deleteProduct} history={this.props.history} enableImageModal={this.enableImageModal} />
                            </div>
                            <div className={this.state.modal}>
                                <form id='productForm'>
                                    <div className="modal-background"></div>
                                    <div style = {{width: 800}} className="modal-card">
                                        <header className="modal-card-head">
                                            <p className="modal-card-title">Add Product</p>
                                            <button type="button" onClick={() => this.cancel()} class="delete" aria-label="close"></button>
                                        </header>
                                        <section style={{ overflow: "hidden" }} class="modal-card-body">
                                            <div className="field is-horizontal">
                                                <div className="field-body">
                                                    <div className="field">
                                                        <div className="control">
                                                            <AutoComplete
                                                                style={{ width: 400 }}
                                                                dataSource={this.dataSource}
                                                                onSelect={this.onSelect}
                                                                onSearch={this.onSearch}
                                                                value={this.state.name}
                                                                filterOption={(inputValue, option) =>
                                                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                                }
                                                            >
                                                                <Input value={this.state.name} style={{ height: 50, fontSize: 20, width: 400 }} placeholder="Product" />
                                                            </AutoComplete>
                                                        </div>
                                                    </div>
                                                    <fieldset>
                                                        <div style={{ marginRight: 20 }} class="field">
                                                            <div class="control">
                                                                <div class="select is-medium">
                                                                    <select style={{ width: 347 }} onChange={(e) => { this.setState({ type: e.target.value }) }}>
                                                                        <option>Vegetable</option>
                                                                        <option>Fruit</option>
                                                                        <option>Dairy &amp; Grain</option>
                                                                        <option>Poultry &amp; Livestock</option>
                                                                        <option>Beverage &amp; Canned</option>
                                                                        <option>Other</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <div class="field is-horisontal">
                                                <div class="field-body">
                                                    <div class="field">
                                                        <p class="control">
                                                            <input style={{ width: 400 }} onChange={(e) => this.setState({ totalQuantity: e.target.value })} class="input is-medium" type="number" placeholder="Total Quantity" />
                                                        </p>
                                                    </div>
                                                    <div class="field">
                                                        <div class="control">
                                                            <div class="select is-medium">
                                                                <select style={{ width: 347 }} onChange={(e) => { this.setState({ totalUnit: e.target.value }) }}>
                                                                    <option>lbs</option>
                                                                    <option>kg</option>
                                                                    <option>gal</option>
                                                                    <option>L</option>
                                                                    <option>units</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="field is-horisontal">
                                                <div class="field-body">
                                                    <div class="field">
                                                        <textarea onChange = {(e) => this.setState({description: e.target.value})} class="textarea" placeholder="Add a product description"></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <label class="label is-large">Pricing options</label>
                                            <div class="columns">
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1>
                                        In stock
                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -12 }}>
                                        Max Allowed Order
                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -20 }}>
                                        Quantity
                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -22 }}>
                                        Unit
                                                    </h1>
                                </div>
                                <div style={{ fontSize: 12 }} class="column">
                                    <h1 style={{ marginLeft: -30 }}>
                                        Price
                                                    </h1>
                                </div>
                            </div>
                                            {this.addLine()}
                                            <button type="button" onClick={() => this.addNewLine()} class="button is-small is-primary is-light">
                                                <span class="icon is-small">
                                                    <i class="fas fa-plus"></i>
                                                </span>
                                                <span>another option</span>
                                            </button>
                                        </section>
                                        <footer style={{ fontSize: 14 }} className="modal-card-foot">
                                            <button style={{ fontFamily: 'GothamRound', fontSize: 14 }} type="button" onClick={() => this.handleSubmit("post")} className="button is-success">Add to inventory</button>
                                            <button style={{ fontFamily: 'GothamRound', fontSize: 14 }} type="button" onClick={() => this.cancel()} className="button">Cancel</button>
                                            <div style={{ "marginLeft": 15 }} class="field">
                                                <div class="file is-warning has-name">
                                                    <label class="file-label">
                                                        <input onChange={(e) => { this.handleUpload() }} class="file-input" type="file" name="resume" ref={this.state.fileRef} />
                                                        <span class="file-cta">
                                                            <span class="file-icon">
                                                                <i class="fas fa-upload"></i>
                                                            </span>
                                                            <span class="file-label">
                                                                Upload a photo...
                                                        </span>
                                                        </span>
                                                        <span class="file-name">
                                                            {this.state.fileName}
                                                        </span>
                                                    </label>
                                                    {this.state.fileName !== "No file uploaded" ? <button style={{ marginTop: "1vh", "marginLeft": "1vw" }} type="button" onClick={() => this.removeAddPhoto()} class="delete" aria-label="close"></button>
                                                            :
                                                            null}
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {this.getEditForm(this.product)}
                    </div>
                </div>
                {(isFarmer === "true" && this.state.products.length > 0) ?
                
                
                this.state.visibility? 
                
                [
                    <button style={{ fontFamily: 'GothamRound', fontSize: 14 }} onClick = {()=>this.saveChangesVisibility()} type="button"  className="button is-success" >Save Changes</button>,
                    <button style={{ marginLeft: 20, fontFamily: 'GothamRound', fontSize: 14 }} onClick = {()=>this.setState({visibility: false})} type="button"  className="button">Cancel</button>
                ]    
                    :
                    <button style={{ fontFamily: 'GothamRound', fontSize: 14 }} onClick = {()=>{this.setState({visibility: true})}} type="button"  className="button">Edit Visibility</button>
                
                :
                null}
                {(isFarmer === "true" && this.state.products.length === 0 && this.state.inven_num !== "0") ?
                <button  onClick = {()=>this.createNewInventory()} type="button"  className="button" style={{ fontFamily: 'GothamRound', fontSize: 14 }}> 
                    Clone Main Inventory
                </button>
                :
                null
                }
            </div>
        )
    }
}
export default InventoryContainer;