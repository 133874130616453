import React from 'react'
class FarmersDashboard extends React.Component {
    state = {
        products: [],
        inventory_owner: "My",
        is_owner: true
    }
    url = ""
//     componentWillReceiveProps() {
//         const config = {
//             headers:{
//                 'Authorization' : 'Token ' + localStorage.getItem('token')
//         }
//     }
// }
//     componentDidMount() {
//         const config = {
//             headers:{
//                 'Authorization' : 'Token ' + localStorage.getItem('token')
//             }
//         }
//     }
    render() {
        const isFarmer = localStorage.getItem('is_farmer')
        return (    
            <div style = {{fontFamily: 'GothamRound'}}>
                <nav className="breadcrumb" aria-label="breadcrumbs">
                <ul>
                    <li className="is-active">
                            <span className="icon">
                                <i style = {{fontSize: 25}}className="fas fa-house-user" aria-hidden="true"></i>
                            </span>
                            <span style = {{fontSize: 25}}>Dashboard</span>
                    </li>
                </ul>
            </nav>
        <div className="column">
            <div className="content">
                <div className="tile is-ancestor">
                    <div className="tile is-vertical is-8">
                        <div className="tile">
                            <div className="tile is-parent is-vertical">
                                {isFarmer === "true" ?
                                <article className="tile is-child notification is-grey">
                                    <h3 className="is-size-3">Sales</h3>
                                    <p>No sales yet here.</p>
                                </article>:
                                null
                                }
                                {isFarmer === "true" ?
                                <article className="tile is-child notification">
                                    <h3 className="is-size-3">Inventory</h3>
                                    <p>More information.</p>
                                </article>
                                :
                                null
                            }
                            </div>
                            <div className="tile is-parent">
                                <article className="tile is-child notification">
                                    <h3 className="is-size-3">Past orders</h3>
                                
                                </article>
                            </div>
                        </div>
                        <div className="tile is-parent">
                            <article className="tile is-child notification">
                                <h3 className="is-size-3">Relevant news</h3>
                                <p>More to come</p>
                                <div className="content">
                                    More content here.
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="tile is-parent">
                        <article className="tile is-child notification">
                            <div className="content">
                                <h3 className="is-size-3">Clients</h3>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
        </div> 
        )
    }
}
export default FarmersDashboard;