import React from 'react';
import {
  List, Avatar, InputNumber, Button, Popconfirm, message
} from 'antd';

import icon from '../images/icon.jpg';
import fruit from '../images/fruit.png'
import meat from '../images/meat.png'
import veggie from '../images/veggie.png'
import axios from 'axios'

class CartItem extends React.Component {
  imageMap = {
    "Other": icon,
    "Livestock": meat,
    "Vegetable": veggie,
    "Fruit": fruit,
  };
  state = {
    itemPrevs: {}
  }
  config = {
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token')
    }
  }
  addAmountDropdown(curr, maxAmount){
    var toReturn = []
    for(var i = 1; i<=maxAmount; i++){
        toReturn.push(<option value={i}>
            {i}
        </option>)
    }
    return toReturn
}
  updateValue = (id, e, original) => {
    var prev = this.state.itemPrevs[id]
    console.log("prev", prev)
    var temp = this.state.itemPrevs
    if(prev === undefined){
      prev = original
    }
    temp[id] = e
    this.setState({itemPrevs: temp})
    const diff = e-prev
    this.props.updateCart(diff)
    const url = "https://api.farmersofamerica.net/api/itemorder/" + id + "/"
    axios.patch(url, {
      quantity: e
    }, this.config)
  }
  confirm = (id) => {
    const url = "https://api.farmersofamerica.net/api/itemorder/" + id + "/"
    axios.delete(url,this.config)
    message.info('Item succesfully removed');
    this.props.delete(id)
    const quantity = document.getElementById(id).value
    this.props.updateCart(-1*quantity)
  }
  getImageUrl(url, name){
    if(url == null  || url == ""){
      return this.getImage(name)
    } else{
        return url
    }
  }
  getImage = (name) => {
    if(name!=null){
      name = name.toLowerCase()
      name = name.split(" ")[0]
    }
    console.log("names",name)
    const available_veggies = [
      "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
      "corn", "arugula", "beansprouts", "cucumber", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
    ]
    if(available_veggies.includes(name)){
      const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
      return url
    }
    else{
      return icon
    }
  }

  render() {
    return (
      <div className="demo-infinite-container">

        <List
          dataSource={this.props.data}
          locale = {{emptyText: "No items in cart"}}
          renderItem={item => (
            <List.Item key={item.id} actions={
              [
                <Popconfirm placement="topRight" title={"Are you sure you want to delete this item?"} onConfirm={(e)=>this.confirm(item.id)} okText="Yes" cancelText="No">
                <Button>Delete</Button>
              </Popconfirm>

              ]
            }>
              <List.Item.Meta
                avatar={<Avatar src={this.getImageUrl(item.image_url, item.item_name)} size={60} />}
                title={item.item_name}
                description={item.seller_name}
              />
              <div  class="select is-primary">
              <select key = {item.id} id = {item.id} defaultValue = {item.quantity} style={{fontSize: "0.9em", marginLeft:"1vw", width: 60}} onChange  = {(e)=>{this.updateValue(item.id, e.target.value, item.quantity)}}>
                    {this.addAmountDropdown(item.quantity, item.max_allowed_order)}
                </select>
              </div>

            </List.Item>
          )}
        >
        </List>
      </div>
    );
  }
}

export default CartItem;
