import React from 'react'
import Product from '../components/Product'
import axios from 'axios';
import FormContainer from './FormContainer'
import {Button, message, Popconfirm } from 'antd';
class ProductList extends React.Component {
    state = {
        products: [],
        inventory_owner: "My",
        is_owner: true
    }
    param1 = this.props.match.params.param1
    url = ""
    componentWillReceiveProps() {
        const config = {
            headers:{
                'Authorization' : 'Token ' + localStorage.getItem('token')
        }
       }
        const path = this.props.history.location.pathname
        if (path === '/inventory') {
            this.url = 'https://api.farmersofamerica.net/api/inventory/'
        }
        else {

            if(this.param1.length<12){
                this.param1 = path[path.length-1]
            }
 
            this.url = 'https://api.farmersofamerica.net/api/inventory/' + this.param1
        }
        axios.get(this.url, config)
            .then(res => {
                this.setState({
                    products: res.data
                })
            })
    }
    componentDidMount() {
    const config = {
        headers:{
            'Authorization' : 'Token ' + localStorage.getItem('token')
        }
    }
    var userId = ""
    if(this.param1 != null){
        userId = this.param1
    }

        if (this.userId === "") {
            this.url = 'https://api.farmersofamerica.net/api/inventory/'
        }
        else {
            this.url = 'https://api.farmersofamerica.net/api/inventory/' + userId
        }
        console.log("config",config)
        axios.get(this.url, config)
            .then(res => {
                this.setState({
                    products: res.data
                })
                if (res.data.length > 0) {
                    if (!res.data[0].is_owner) {
                        this.setState({
                            inventory_owner: res.data[0].user_name,
                            is_owner: res.data[0].is_owner
                        })
                    }
                }
            })
            .catch(res => {
                this.props.history.push('/')

            })
        if (this.userId != null) {
            this.url = 'https://api.farmersofamerica.net/api/profile/' + userId
            axios.get(this.url, config)
                .then(res => {
                    this.setState({
                        inventory_owner: res.data.username
                    })
                })
        }
    }
    updateState = (data) => {
        this.setState({ products: [...this.state.products, data] })
    }
    createNewInventory = () => {

     const config = {
        headers:{
            'Authorization' : 'Token ' + localStorage.getItem('token')
        }
    }
        message.loading("Cloning main inventory...")
        axios.post('https://api.farmersofamerica.net/api/inventory/create/', { inven_num: this.param1 }, config)
            .then(res => {
                axios.get('https://api.farmersofamerica.net/api/inventory/' + this.param1, config)
                    .then(res2 => {
                        this.setState({
                            products: res2.data
                        })
                        message.destroy()
                        message.success("Cloned main inventory!", 0.5)
                    })
            })
    }
    deleteInventory = () => {
    const config = {
        headers:{
            'Authorization' : 'Token ' + localStorage.getItem('token')
        }
    }
        if (this.param1 == null) {
            message.error('Error: Cannot delete Main Inventory.')
        }
        else {
            axios.delete('https://api.farmersofamerica.net/api/inventory/delete/' + this.param1 + "/", config)
                .then(res => {
                    message.success("Success: Deleted Inventory " + this.param1, 0.75)
                    this.setState({
                        products: []
                    })
                })
                .catch(res => {
                    message.error("Error: Unable to delete Inventory " + this.param1)
                })
        }
    }
    render() {
        return (
            <div className="inventory">
            <div className="inventoryContent">
                {
                    this.param1 == null || this.param1.length<5 ?

                        <FormContainer update={this.updateState} /> :
                        null}
                <br />
                <div style={{ display: "inline-block" }} >
                    <h2>
                        {this.state.inventory_owner} Inventory
                    </h2>
                </div>
                <Product data={this.state.products} />
                {
                    this.param1 != null && this.param1.length>5 ? 
                        null :
                        this.state.products[0] == null && this.userId == null ?
                            <Button style={{
                                marginTop: "2vw"
                            }}
                            onClick={this.createNewInventory}>
                                Clone Main Inventory
                            </Button>
                            :
                            <Popconfirm
                                placement="topRight"
                                title={"Delete this inventory?"}
                                onConfirm={this.deleteInventory}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button className="deleteInventory"
                                    type="danger"
                                    style={{fontSize:"16px",
                                }}
                                >

                                    Delete Inventory</Button>
                            </Popconfirm>
                }

            </div>
            </div>
        );
    }
}
export default ProductList;