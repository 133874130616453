import * as actionTypes from './actionTypes'
import axios from 'axios';
export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess= (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error,
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('is_farmer')
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}


export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000 * 24)
    }
}

export const authLogin = (email, password) => {
    return dispatch =>{
        dispatch(authStart());
        axios.post('https://api.farmersofamerica.net/rest-auth/login/',{
            email: email,
            password: password,

        })
        .then(res => {
            const token = res.data.key;
            const is_farmer = res.data.user.is_farmer
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000 * 24);
            localStorage.setItem('token',token);
            localStorage.setItem('expirationDate',expirationDate)
            localStorage.setItem('is_farmer',is_farmer)
            dispatch(authSuccess(token));
            dispatch(checkAuthTimeout(3600));
        })
        .catch(err => {
            console.log("ERROR?",err)
            dispatch(authFail(err))
        })
    }
}


export const authSignup = (username, email, password, password2, is_farmer, first_name, last_name, phone_number,
    address_line_1, address_line_2, city, state, zipcode, farm_size, farm_type, produce_type) => {
    return dispatch =>{
        dispatch(authStart());
        axios.post('https://api.farmersofamerica.net/rest-auth/registration/',{
            username: username,
            password1: password,
            password2: password2,
            email: email,
            is_farmer:is_farmer,
            phone_number: phone_number,
            address_line_1: address_line_1,
            address_line_2: address_line_2,
            city: city, 
            state: state,
            zipcode: zipcode,
            first_name: first_name,
            last_name: last_name,
            farm_size: farm_size,
            farm_type: farm_type,
            produce_type: produce_type
        })
        .then(res => {
            const token = res.data.key;
            const expirationDate = new Date(new Date().getTime() + 3600 * 1000 * 24);
            localStorage.setItem('token',token);
            localStorage.setItem('expirationDate',expirationDate)
            localStorage.setItem("is_farmer", is_farmer)
            
            dispatch(authSuccess(token));
            dispatch(checkAuthTimeout(3600));
        })
        .catch(err => {
            dispatch(authFail(err))
        })
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (token === undefined) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if ( expirationDate <= new Date() ) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) / 1000) );
            }
        }
    }
}