import React from 'react'
import axios from 'axios';
import {List, Avatar, Button, message} from 'antd'
import icon from '../images/icon.jpg';
import NewItem from './NewItem'
class Inventory extends React.Component {
    state = {
        products: [],
        inventory_owner: false,
        is_owner: true,
        current_item_price: 0,
        to_add : {},
        to_change_visibility: {}

    }
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    componentWillReceiveProps(props){
    }
    eventhandler = (id, amount, price) => {
        var temp_hash = this.state.to_add
        temp_hash[id] = {amount:amount, price:price}
        this.setState({to_add: temp_hash})

    }
    updateState = (data) => {
        this.setState({ products: [...this.state.products, data] })
    }
    getImage = (name, url) => {
        if(url!=null && url.length>0){
            return url
        }
        if(name!=null){
          name = name.toLowerCase()
        }
        const available_veggies = [
          "artichoke","avocado","broccoli","cabbage","carrot","eggplant","lettuce","onion","potato","tomato",
          "corn", "arugula", "beansprouts", "cucumbers", "garlic", "spinach", "greenonion", "zucchini", "greenbeans", "olives"
        ]
        if(available_veggies.includes(name)){
          const url = `https://s3.amazonaws.com/farmersofamerica.net/images/${name}.png`
          return url
        }
        if(url.length === 0){
            return icon
        }
        else{
          return icon
        }
      }
    changePrice(e){
        const o = e.target.value
        console.log(o)
    }
    getOptions = (item) => {
        var toReturn = []
        for(var i = 0; i<item.length; i++){
            toReturn.push(<option value={[item[i].price, item[i].amount_in_stock]}>
                {item[i].unit_quantity+" "+item[i].unit_of_measurement}
            </option>)
        }
        return toReturn

    }

    addAllToCart() {
        const toAdd = this.state.to_add
        const keys = Object.keys(toAdd)
        var total = 0
        for( const id of keys){
            const amount = toAdd[id].amount
            total+=amount
            const price = toAdd[id].price
            console.log('jeeby',price)
            axios.post('https://api.farmersofamerica.net/api/itemorder/',{
            quantity: amount,
            price: price,
            item_id: id,

        },this.config)
        }
        this.props.updateCart(parseInt(total))
        message.success(`Added all to cart!`,0.5)
    }
    getItems = () => {
        const items = this.props.data
        const to_return = items.map(item => 
        <article class="item">
        <div class="columns is-variable is-vcentered">
            <div class="column is-1">
                <a onClick = {()=>{this.props.enableImageModal(item)}}><img style = {{borderRadius: "50%"}} src={this.getImage(item.name,item.image_url)}/></a>
            </div>
            <div class="column is-2">
                <h4 style = {{fontSize: "1em"}}class="is-marginless">{item.name}
                    <br></br>
                    <h6 style = {{color: "DimGrey", fontSize: 10, fontFamily: "GothamRounded-Light"}}>{item.description}</h6>

                </h4>
            </div>
            <NewItem updateCart = {this.props.updateCart} data={item.items} product = {item} itemId = {item.id }isOwner = {item.is_owner} passProps = {this.eventhandler}/>
            {item.is_owner? 
            <div class="column is-1 has-text-centered">
                {!this.props.visibility ? 
                <div class="dropdown is-hoverable">
                    <div class="dropdown-trigger" aria-haspopup="true" aria-controls="dropdown-menu">
                        <button style = {{marginTop: -7, height: 32, width: 32}}class="button">
                            <span class="icon">
                                <i class="fas fa-ellipsis-h has-text-dark"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">
                            <a onClick = {()=>{this.props.editCard(item)}} class="dropdown-item">
                                <span class="icon">
                                    <i class="fas fa-plus-circle" aria-hidden="true"></i>
                                </span>
                                <span>Edit item</span>
                            </a>
                            <a onClick ={()=>{this.props.delete(item)}} class="dropdown-item">                  
                                <span class="icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span>Delete</span>
                            </a>
                        </div>
                    </div>
                </div>
                :
                <label class="checkbox">
                    <input onChange = {()=>{item.visible = !item.visible; this.props.checkBox(item)}} checked={item.visible} type="checkbox"/>
                    Visible
                </label>
            }
                </div>
                :
                null
            }
        </div>
    </article>)
    return to_return
    }
    render() {
        const isFarmer = localStorage.getItem('is_farmer')
            return(
                <div>
                {this.getItems()}
                {(this.props.data.length > 0 && isFarmer === "false") ? 
                <button onClick = {()=>this.addAllToCart()} class = "button is-primary is-rounded" style = {{"marginTop": "3vh"}}>
                    Add all to cart
                </button> :
                null
    }
                </div>
            )

    }
}
export default Inventory;