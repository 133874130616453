import React from 'react';
import {
    Form, Input, InputNumber, Button, message
} from 'antd';
import axios from 'axios';
class ItemForm extends React.Component {
    didSubmit = false
    category = "Other"
    config = {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    }
    success = () => {
        message.success("Sucessfully added item")
    }
    handleFormSubmit = (event, requestType, itemID) => {
        event.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const unit_quantity = event.target.elements.amount.value
                event.target.elements.amount.value = 0
                const amount_in_stock = event.target.elements.quantity.value
                const unit_of_measurement = event.target.elements.measurement.value
                const price = event.target.elements.price.value
                this.props.form.resetFields()
                this.success()
                switch (requestType) {
                    case 'post':
                        return axios.post('https://api.farmersofamerica.net/api/items/', {
                            product: this.props.product,
                            unit_quantity: unit_quantity,
                            amount_in_stock: amount_in_stock,
                            unit_of_measurement: unit_of_measurement,
                            price: price

                        },
                            this.config
                        )
                            .then(function (response) {
                            })
                            .catch(err => console.error(err));

                    case 'put':
                        console.log("its a put")
                        return axios.put(`https://api.farmersofamerica.net/api/items/${itemID}/`, {
                            unit_quantity: unit_quantity,
                            amount_in_stock: amount_in_stock,
                            unit_of_measurement: unit_of_measurement,
                            price: price
                        })
                            .then(res => console.log(res))
                            .catch(err => console.err(err));
                    default:
                        console.log("Not a put or post. Shouldn't be happening.")
                }
            };
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <h2>2. Press Add Item to save a quantity/size. Press Finish to go back and create a new product.</h2>
                <Form
                    id="item-form"
                    style={{ width: "90vw" }}
                    layout='inline'
                    onSubmit={(event) => this.handleFormSubmit(event, this.props.requestType, this.props.itemID)}>
                    <Form.Item

                        label="Amount/Size">{
                            getFieldDecorator('note1', {
                                rules: [{
                                    required: true,
                                    message: 'Required'
                                }],
                            }
                            )(
                                    <Input
                                        autoComplete="off"
                                        style={{ width: "125px" }}
                                        name="amount"
                                        placeholder="Ex: 25, Large, etc."
                                    />
                                )}
                    </Form.Item>
                    <Form.Item
                        label="Unit of Measurement"
                    >   {
                            getFieldDecorator('note2', {
                                rules: [{
                                    required: true,
                                    message: 'Required'
                                }],
                            })(
                                <Input
                                    autoComplete="off"
                                    style={{ width: "175px" }}
                                    name="measurement"
                                    placeholder="Ex: liters, lbs, units, etc." />
                            )}
                    </Form.Item>
                    <Form.Item
                        label="Price">
                        {
                            getFieldDecorator('note3', {
                                rules: [{
                                    required: true,
                                    message: 'Required'
                                }],
                            })(
                                    <InputNumber
                                        style={{ width: "100px" }}
                                        defaultValue={100}
                                        name="price"
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    />
                                )
                        }
                    </Form.Item>
                    <Form.Item
                        label="Quantity in stock"
                    >   {
                            getFieldDecorator('note4', {
                                rules: [{ required: true, message: 'Required' }],
                            })(
                                <InputNumber
                                    style={{ width: "60px" }}
                                    min={0}
                                    max={999}
                                    defaultValue={0}
                                    name="quantity"
                                />
                            )}
                    </Form.Item>
                    <Form.Item >
                        <Button onClick={event => this.handleFormSubmit} style={{ background: "#008CBA", border: "2px solid #008CBA" }} type="primary" htmlType="submit" >Add Item</Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={this.props.resetId} style={{ background: "#4CAF50", border: "2px solid #4CAF50" }} type="primary" htmlType="submit" >Finish</Button>
                    </Form.Item>
                </Form>
            </div>

        );
    }
}

const WrappedItemForm = Form.create()(ItemForm);
export default WrappedItemForm;